import { createReducer, on } from "@ngrx/store";
import { setLanguage, loadLanguages } from "./language.actions";

export interface Language {
  code: string;
  name: string;
}

export interface LanguageState {
  locale: string;
  languageList: Language[];
}

export const initialLanguageState: LanguageState = {
  locale: "en-US",
  languageList: [
    { code: "en-US", name: "English" },
    { code: "sr-RS", name: "Serbian" },
  ],
};

export const languageReducer = createReducer(
  initialLanguageState,
  on(setLanguage, (state, { locale }) => {
    if (locale) {
      localStorage.setItem("i18n_locale", locale);
    }
    return {
      ...state,
      locale,
    };
  }),
  on(loadLanguages, (state) => {
    const storedLocale = localStorage.getItem("i18n_locale");
    return {
      ...state,
      locale: storedLocale || state.locale,
    };
  })
);
