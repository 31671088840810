import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { Serbian } from "flatpickr/dist/l10n/sr";
import { FlatpickrOptions } from "ng2-flatpickr";
import { toggleAnimation } from "../../animations";
import { AppState } from "src/app/store/index.selector";
import { DateService } from "../../services/date.service";
import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";

@Component({
  selector: "app-datepicker",
  templateUrl: "./datepicker.component.html",
  animations: [toggleAnimation],
})
export class DatepickerComponent implements OnInit {
  @Input() pickerType!: boolean;
	
  search!: boolean;
  datetimeForm!: FormGroup;
  basic!: FlatpickrOptions;
  range!: FlatpickrOptions;
  basicPickerDate!: Date | string;
  rangePickerDates!: (Date | string)[];
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;

  constructor(private fb: FormBuilder, private dateService: DateService, private storeData: Store<AppState>) {
    this.datetimeForm = fb.group({
      basicPicker: new FormControl(),
      rangePicker: new FormControl(),
    });
  }

  ngOnInit() {
    this.initDatePickers();
    this.datepick$ = this.storeData.select(selectFromTo);

    this.datepick$.subscribe((datepick) => {
      if (datepick) {
        const { from, to } = datepick;

        if (this.pickerType && to) {
          this.rangePickerDates = [from, to];
        } else {
          this.pickerType = false;
          this.basicPickerDate = from;
        }
      }
    });
  }

  initDatePickers() {
    const { range, lastDayOfLastYearFormatted } = this.generateDateRange();

    this.basic = {
      defaultDate: lastDayOfLastYearFormatted,
      dateFormat: "d. M Y",
      position: "auto right",
      locale: Serbian,
      onChange: this.handleDateChange.bind(this),
    };

    this.range = {
      defaultDate: range,
      dateFormat: "d. M Y",
      mode: "range",
      position: "auto right",
      locale: Serbian,
      onChange: this.handleDateChange.bind(this),
    };
  }

  handleDateChange(selectedDates: Date[]) {
    if (selectedDates.length === 2) {
      const from = selectedDates[0];
      const to = selectedDates[1];
      this.dateService.setFromToDate(from, to);
    } else if (selectedDates.length === 1) {
      const from = selectedDates[0];
      this.dateService.setFromDate(from);
    }
  }

  generateDateRange(): { range: string; lastDayOfLastYearFormatted: string } {
    const lastDayOfLastYear = new Date(new Date().getFullYear() - 1, 11, 31);
    const firstDayOfNewYear = new Date(new Date().getFullYear(), 0, 1);

    const lastDayOfLastYearFormatted = this.formatDate(lastDayOfLastYear);
    const firstDayOfNewYearFormatted = this.formatDate(firstDayOfNewYear);
    const range = `${lastDayOfLastYearFormatted} to ${firstDayOfNewYearFormatted}`;

    return { range, lastDayOfLastYearFormatted };
  }

  formatDate(date: Date): string {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"];
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    return `${day}. ${monthNames[monthIndex]} ${year}`;
  }
}
