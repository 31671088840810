<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"> </app-file-history>
  <app-file-upload [id]="id"></app-file-upload>
  <app-group-travel-add-passanger [groupTravelId]="id"></app-group-travel-add-passanger>
</div>
<div *ngIf="group_travel">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4 print:hidden">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "group_travels_details_label" | translate }}
            </div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl italic text-white-dark">
            {{ "group_travel_details.name" | translate }}
          </div>
          <div class="text-xl">{{ group_travel.name }}</div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "group_travel_details.status" | translate }}:</div>
            <div *ngIf="group_travel.active; else notActive" class="badge mt-1 inline-flex w-auto self-start bg-success">
              {{ "group_travel_details.active" | translate }}
            </div>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "group_travel_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.departure_from" | translate }} :</div>
          <div>{{ group_travel.departure.description }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.hotel" | translate }}:</div>
          <div>{{ mainDestination.hotel?.name }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.transportation" | translate }} :</div>
          <div>{{ group_travel.type.title }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1 sm:row-span-3">
          <div>{{ "travel_details.additional_destinations" | translate }} :</div>
          <div *ngFor="let dest of additionalDestinations">
            <app-travel-accordion [content]="dest"></app-travel-accordion>
          </div>
        </div>

        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.departure_date" | translate }}:</div>
          <div>{{ group_travel.start | date : "dd.MM.yyyy" }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.return_date" | translate }}:</div>
          <div>{{ group_travel.end | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "group_travel_details.days_nights" | translate }}:</div>
          <div>{{ mainDestination.days }}/{{ mainDestination.nights }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.room_description" | translate }}:</div>
          <div>{{ mainDestination.roomDescription }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.service_type" | translate }}:</div>
          <div>{{ mainDestination.service?.title }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.notes" | translate }}:</div>
          <div>{{ mainDestination.notes }}</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <div class="col-span-1 sm:col-span-2">
          <div class="flex-col">
            <div>{{ "group_travel_details.bruto" | translate }}:</div>
            <div class="text-dark">
              {{ group_travel.bruto | currency : "EUR" }}
            </div>
          </div>
        </div>

        <div class="col-span-1 sm:col-span-2">
          <div class="flex-col">
            <div>{{ "group_travel_details.neto" | translate }}:</div>
            <div class="text-dark">
              {{ group_travel.neto | currency : "EUR" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-group-travel-passangers-table [groupTravelId]="id"></app-group-travel-passangers-table>
</div>
