import { createReducer, on } from "@ngrx/store";
import { toggleDirection, toggleMainLoader, setMainLayout, toggleTheme, toggleMenu, toggleLayout, toggleRTL, toggleAnimation, toggleNavbar, toggleSemidark, toggleSidebar } from "./layout.actions";

export interface LayoutState {
  isDarkMode: boolean;
  mainLayout: string;
  theme: string;
  menu: string;
  layout: string;
  rtlClass: string;
  animation: string;
  navbar: string;
  sidebar: boolean;
  isShowMainLoader: boolean;
  semidark: boolean;
}

export const initialState: LayoutState = {
  isDarkMode: false,
  mainLayout: "app",
  theme: "light",
  menu: "vertical",
  layout: "full",
  rtlClass: "rtl",
  animation: "",
  navbar: "navbar-sticky",
  sidebar: false,
  isShowMainLoader: false,
  semidark: false,
};

export const layoutReducer = createReducer(
  initialState,

  on(toggleDirection, (state, { direction }) => {
    localStorage.setItem("direction", direction);
    document.querySelector("html")?.setAttribute("dir", direction || "ltr");
    return { ...state, rtlClass: direction };
  }),
  on(toggleMainLoader, (state, { isShow }) => ({
    ...state,
    isShowMainLoader: isShow,
  })),
  on(setMainLayout, (state, { layout }) => ({
    ...state,
    mainLayout: layout,
  })),

  on(toggleTheme, (state, { theme }) => {
    localStorage.setItem("theme", theme);
    const isDarkMode = theme === "dark" || (theme === "system" && window.matchMedia("(prefers-color-scheme: dark)").matches);
    document.querySelector("body")?.classList.toggle("dark", isDarkMode);
    return { ...state, theme, isDarkMode };
  }),

  on(toggleMenu, (state, { menu }) => {
    localStorage.setItem("menu", menu);
    return { ...state, menu, sidebar: false };
  }),

  on(toggleLayout, (state, { layout }) => {
    localStorage.setItem("layout", layout);
    return { ...state, layout };
  }),

  on(toggleRTL, (state, { rtlClass }) => {
    localStorage.setItem("rtlClass", rtlClass);
    document.querySelector("html")?.setAttribute("dir", rtlClass || "rtl");
    return { ...state, rtlClass };
  }),

  on(toggleAnimation, (state, { animation }) => {
    localStorage.setItem("animation", animation);
    const animationElement = document.querySelector(".animation");
    if (animation === "") {
      animationElement?.classList.remove("animate__animated", state.animation);
    } else {
      animationElement?.classList.add("animate__animated", animation);
    }
    return { ...state, animation };
  }),

  on(toggleNavbar, (state, { navbar }) => {
    localStorage.setItem("navbar", navbar);
    return { ...state, navbar };
  }),

  on(toggleSemidark, (state, { semidark }) => {
    localStorage.setItem("semidark", JSON.stringify(semidark));
    return { ...state, semidark };
  }),

  on(toggleSidebar, (state) => {
    return { ...state, sidebar: !state.sidebar };
  })
);
