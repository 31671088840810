import { Component, OnInit } from "@angular/core";
import { TransferPlan } from "../models/transfer";
import { TransfersService } from "../services/transfers.service.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-transfers-plan-details",
  templateUrl: "./transfers-plan-details.component.html",
})
export class TransfersPlanDetailsComponent implements OnInit {
  transfersPlanData!: TransferPlan;
  id!: number;

  constructor(private transfersPassengersService: TransfersService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.id = +this.route.snapshot.params["id"];
    this.loadTransfersDetails();
  }
  loadTransfersDetails() {
    this.transfersPassengersService.getTransferPlan(this.id).subscribe((response) => {
      this.transfersPlanData = response;
    });
  }
}
