import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { User } from "../auth/model/user";
import { FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { AppState } from "../store/index.selector";
import { AppService } from "../service/app.service";
import { TranslateService } from "@ngx-translate/core";
import { slideInFromRight } from "../shared/animations";
import { Router, NavigationEnd } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { Language } from "../store/language/language.reducer";
import { LocalStorageService } from "../service/local-storage.service";
import { toggleSidebar, toggleTheme } from "../store/layout/layout.actions";
import { selectLanguageList, selectLocale } from "../store/language/language.selector";
import { selectMenu, selectSemidark, selectTheme } from "../store/layout/layout.selector";

@Component({
  moduleId: module.id,
  selector: "app-header",
  templateUrl: "./header.html",
  animations: [slideInFromRight],
})
export class HeaderComponent implements OnInit {
  pickerType = false;
  search = false;
  user!: User | undefined;
  locale$!: Observable<string>;
  languageList$!: Observable<{ code: string; name: string }[]>;
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;
  semidark$!: Observable<boolean>;
  menu$!: Observable<string>;
  theme$!: Observable<string>;
  cars!: any[];
  user$ = this.storeData.select((d) => d.auth);
  hasOngoingItems = false;
  tooltipMessage!: string;

  constructor(
    public translate: TranslateService,
    public storeData: Store<AppState>,
    public router: Router,
    private appSetting: AppService,
    private sanitizer: DomSanitizer,
    public fb: FormBuilder,
    private localStorageService: LocalStorageService
  ) {
    this.initStore();
    this.user$.subscribe((auth) => {
      this.user = auth.user;
    });
  }

  navigateToCalendar() {
    const currentDate = new Date();
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();

    this.router.navigate(["/dashboard/calendar"], {
      queryParams: { month: month, year: year },
    });
  }

  async initStore() {
    this.locale$ = this.storeData.select(selectLocale);
    this.languageList$ = this.storeData.select(selectLanguageList);
    this.semidark$ = this.storeData.select(selectSemidark);
    this.menu$ = this.storeData.select(selectMenu);
    this.theme$ = this.storeData.select(selectTheme);
  }

  ngOnInit() {
    this.setActiveDropdown();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setActiveDropdown();
      }
    });
    this.translate.get("filter_by_date").subscribe((translatedText) => {
      this.cars = [
        { id: false, name: translatedText },
        { id: true, name: this.translate.instant("filter_from_date_to_date") },
      ];
    });
    const ongoingKeys = ["ticketData", "groupTravelPlan", "otherService", "transferPlan", "travelData"];
    ongoingKeys.forEach((key) => {
      this.localStorageService.getKeyStatus(key).subscribe((status) => {
        this.updateOngoingItemsStatus(ongoingKeys);
      });
    });
    this.tooltipMessage = "tooltip.logoutMessage";
  }
  private updateOngoingItemsStatus(ongoingKeys: string[]): void {
    this.hasOngoingItems = ongoingKeys.some((key) => this.localStorageService.loadDataFromLocalStorage(key));
  }
  setActiveDropdown() {
    const selector = document.querySelector('ul.horizontal-menu a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const all: any = document.querySelectorAll("ul.horizontal-menu .nav-link.active");
      for (let i = 0; all.length && i < all.length; i++) {
        all[i]?.classList.remove("active");
      }
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link");
        if (ele) {
          ele = ele[0];
          setTimeout(() => {
            ele?.classList.add("active");
          });
        }
      }
    }
  }

  changeLanguage(item: Language) {
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }

  navigateToProfile() {
    this.router.navigate(["/dashobard/user-profile"]);
  }
  toggleSidebar() {
    this.storeData.dispatch(toggleSidebar());
  }
  toggleTheme(theme: string) {
    this.storeData.dispatch(toggleTheme({ theme }));
  }
  logout() {
    this.localStorageService.clearSpecificData();
    this.router.navigate(["/auth/login"]);
  }
}
