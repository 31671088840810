import { filter, map } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, NavigationEnd, Router, ActivatedRouteSnapshot } from "@angular/router";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.html",
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: { label: string; url: string }[] = [];

  constructor(private route: ActivatedRoute, private router: Router, public translate: TranslateService) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot)
      )
      .subscribe((routeSnapshot) => {
        this.breadcrumbs = this.getBreadcrumbs(routeSnapshot as ActivatedRouteSnapshot);
      });

    if (this.router.url) {
      this.breadcrumbs = this.getBreadcrumbs(this.route.snapshot as ActivatedRouteSnapshot);
    }
  }

  private getBreadcrumbs(routeSnapshot: ActivatedRouteSnapshot): { label: string; url: string }[] {
    const breadcrumbs: { label: string; url: string }[] = [];
    let currentRoute = routeSnapshot;

    while (currentRoute) {
      const childrenRoutes = currentRoute.children;
      if (childrenRoutes.length === 0) {
        break;
      }

      const primaryChildRoute = childrenRoutes.find((route) => route.outlet === "primary");
      if (!primaryChildRoute) {
        break;
      }

      let routeUrl = primaryChildRoute.url.map((segment) => segment.path).join("/");
      const routeLabel = primaryChildRoute.data["breadcrumb"];
      if (routeLabel === "tickets" && routeUrl) routeUrl = routeLabel + "/" + routeUrl;
      let previousPaths = breadcrumbs[breadcrumbs.length - 1];

      const prefix = previousPaths ? `${previousPaths.url}/` : "/dashboard/";

      if (routeUrl && routeLabel) {
        breadcrumbs.push({ label: routeLabel, url: prefix + routeUrl });
      }

      currentRoute = primaryChildRoute;
    }

    return breadcrumbs;
  }
}
