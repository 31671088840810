import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelDestination } from "../../travels/models/travel-destination";

@Component({
  selector: "app-destinations-list",
  templateUrl: "./destinations.list.component.html",
  animations: [toggleAnimation],
})
export class DestinationsListComponent {
  public travelDestinations: TravelDestination[] = [];
  public editDestination!: TravelDestination | undefined;

  constructor(public translate: TranslateService) { }

  getDestinations(): TravelDestination[] | undefined {
    if (this.editDestination != null) {
      showMessage(PopupType.Danger, this.translate.instant("popup.please_save_destination_before_proceeding"));
      return;
    }
    return this.travelDestinations;
  }

  addDestination() {
    this.travelDestinations.push({
      guid: crypto.randomUUID(),
      id: undefined,
      start: undefined,
      end: undefined,
      days: undefined,
      nights: undefined,
      notes: undefined,
      service: undefined,
      destination: undefined,
      hotel: undefined,
      travelId: undefined,
      serviceId: undefined,
      destinationId: undefined,
      hotelId: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      roomDescription: undefined,
      voucher: undefined,
      isMainDestination: false,
    });
  }

  checkValidity() {
    if (this.editDestination || !this.isDestinationSaved()) {
      showMessage(PopupType.Danger, this.translate.instant("passanger_needs_to_be_saved"));
      return false;
    }
    return true;
  }

  isDestinationSaved() {
    const notSaveDestinations = this.travelDestinations.find((p) => p.destination);
    if (notSaveDestinations) {
      return false;
    }
    return true;
  }

  removeDestination(id: string | undefined) {
    this.editDestination = undefined;
    this.travelDestinations = this.travelDestinations.filter((e) => e.guid != id);
    showMessage(PopupType.Success, this.translate.instant("popup.destination_removed_successfully"));
  }

  handleDestination(destination: TravelDestination) {
    const updatedDestinations = this.travelDestinations.map((e) => {
      if (e.guid === destination.guid) {
        return { ...e, ...destination };
      }
      return e;
    });

    this.travelDestinations = updatedDestinations;

    showMessage(PopupType.Success, this.translate.instant("popup.destination_saved_successfully"));
    this.editDestination = undefined;
  }

  toEditDestination(destination: TravelDestination) {
    this.editDestination = undefined;
    setTimeout(() => {
      this.editDestination = destination;
    });
  }

  push(destinations: TravelDestination[]) {
    this.travelDestinations = destinations.map((p) => {
      return { ...p, guid: crypto.randomUUID() };
    });
  }
}
