<div *ngIf="sale">
  <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-5 print:hidden">
    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.bruto" | translate }} :</div>
        <div>{{ sale.bruto | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.neto" | translate }} :</div>
        <div>{{ sale.neto | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col">
        <div>{{ "sale_details.avans" | translate }}:</div>
        <div>{{ sale.avans | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex-col" *ngIf="totalDepth$ | async as totalDepth">
        <div>{{ "sale_details.dept" | translate }}:</div>
        <div>{{ totalDepth | currency : "EUR" }}</div>
      </div>
    </div>

    <div class="col-span-1 pt-3">
      <div class="flex items-center justify-between">
        <div class="flex-col">
          <div>{{ "sale_details.paymentType" | translate }}:</div>
          <div *ngIf="sale.paymentType">{{ sale.paymentType.title }}</div>
        </div>
        <a type="button" [ngxTippy]="translate.instant('sale_details.reciept')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="sendPlanInvoice()">
          <icon-txt-file></icon-txt-file>
        </a>
      </div>
    </div>
    <div class="col-span-2 flex sm:col-span-1" *ngIf="!hideStatus">
      <div class="flex place-content-center items-center self-center">
        <div>{{ "ticket_details.status" | translate }}:</div>
        <ng-container *ngIf="sale.status; else notPaid">
          <div class="badge bg-success">
            {{ "ticket_details.paid" | translate }}
          </div>
        </ng-container>
        <ng-template #notPaid>
          <div class="badge bg-slate-200 text-white">
            {{ "ticket_details.not_paid" | translate }}
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-span-2 flex sm:col-span-2" *ngIf="!hideStatus">
      <div class="flex place-content-center items-center self-center">
        <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
        <div class="badge bg-success" *ngIf="sale.active; else notActive">
          {{ "ticket_details.active" | translate }}
        </div>
        <ng-template #notActive>
          <div class="badge bg-slate-200 text-white">
            {{ "ticket_details.deleted" | translate }}
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<div class="my-5 print:hidden" id="payments">
  <div class="block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden">
    <div class="text-[20px] text-dark">{{ "sale_details.all_payments" | translate }}:</div>
    <div class="grid grid-cols-1 justify-center sm:grid-cols-1" *ngIf="payments && payments.length > 0">
      <ng-container *ngFor="let item of payments; index as i">
        <div
          [ngClass]="{ 'bg-green-100': editPayment && editPayment.guid === item.guid }"
          class="grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-3"
        >
          <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ "sale_details.payed_at_date" | translate }}: {{ item.paymentDate | date : "dd.MM.yyyy" }}</div>
          <div class="grid-cols-1 px-2 sm:grid-cols-1">
            {{ "sale_details.amound_payed" | translate }}:
            {{ item.payment | currency : "EUR" }}
          </div>
          <div class="flex justify-around">
            <a type="button" class="btn btn-dark h-10 w-10 rounded-full p-0" [ngxTippy]="translate.instant('sale_details.edit')" (click)="toEditPayment(item)">
              <icon-pencil />
            </a>
            <a type="button" [ngxTippy]="translate.instant('sale_details.delete')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="removePayment(item)">
              <icon-trash-lines />
            </a>
            <a type="button" [ngxTippy]="translate.instant('sale_details.reciept')" class="btn btn-dark h-10 w-10 rounded-full p-0" (click)="item.id && fetchPaymentId(item.id)">
              <icon-txt-file />
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="editPayment">
    <app-payment-form [payment]="editPayment" [max]="totalDepth$ | async" #appPaymentForm></app-payment-form>

    <div class="grid grid-cols-2 gap-5" *ngIf="editPayment.id">
      <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
      <button (click)="submitPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
    </div>
    <div class="grid grid-cols-2 gap-5" *ngIf="!editPayment.id">
      <button (click)="discard()" class="btn btn-outline-warning w-full">{{ "sale_details.payment_form_clear" | translate }}</button>
      <button (click)="submitNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.payment_form_submit" | translate }}</button>
    </div>
  </div>
  <div class="mt-2" *ngIf="sale && !editPayment && !sale.status">
    <button (click)="setNewPayment()" class="btn btn-outline-primary w-full">{{ "sale_details.add_new_payment" | translate }}</button>
  </div>
</div>
