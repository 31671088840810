import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { TaskService } from "src/app/domain/sales/services/task.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-global-table-filters",
  templateUrl: "./global-table-filters.component.html",
})
export class GlobalTableFiltersComponent implements OnInit, OnDestroy {
  totalTasks = 0;
  private subscription!: Subscription;

  @Input() filterType = "None";
  @Input() total!: number;
  @Input() withDept!: number;
  @Input() withoutDept!: number;
  @Input() draftFilterVisible = false;
  @Output() selectFilter: EventEmitter<string> = new EventEmitter<string>();
  constructor(private translate: TranslateService, private taskService: TaskService) {}

  ngOnInit(): void {
    if (this.draftFilterVisible) {
      this.subscription = this.taskService.allTasksLength$.subscribe((length) => {
        this.totalTasks = length;
      });
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onSelectionChange(val: string): void {
    this.selectFilter.emit(val);
  }

  isValueNaN(value: number | null): boolean {
    return value === null || isNaN(value);
  }

  get totalRows() {
    if (!this.isValueNaN(this.withoutDept) && !this.isValueNaN(this.withDept)) {
      return this.withoutDept + this.withDept;
    }
    return 0;
  }
}
