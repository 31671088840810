<div class="space-y-8">
  <div class="panel mt-5 pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table [exportApi]="exportApiUrl" [filterType]="'none'" [columns]="exportCols" [filename]="'Transfer Table Export'" [sortColumn]="'none'" [sortDirection]="'none'" [search]="'none'">
        </app-export-table>
      </div>
    </div>
    <div class="datatable">
      <ng-datatable
        [rows]="groupTravelPassangersPlan"
        [columns]="cols"
        [sortable]="true"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
      >
        <ng-template slot="id" let-value="data">
          <span>{{ value.transferPlanId }}</span>
        </ng-template>
        <ng-template slot="numberPassangers" let-value="data">
          <button type="button" (click)="toggleTreeview('passengers-' + value.transferPlanId)" class="active btn btn-outline-primary">
            <icon-caret-down [class]="'w-5 h-5 text-primary inline relative -top-1 ltr:mr-2 rtl:ml-2 ' + [treeview1.includes('passengers-' + value.transferPlanId) ? 'rotate-180' : '']" />
            <span>+{{ value.passengers.length }}</span>
          </button>
        </ng-template>
        <ng-template slot="name" let-value="data">
          {{ value?.mainCustomer.name }}
          <div [@slideDownUp]="!treeview1.includes('passengers-' + value.transferPlanId)" class="accordion-content">
            <ul>
              <ng-container *ngFor="let passenger of value.passengers">
                <li class="py-[5px]">
                  {{ passenger.customer?.name }}
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <ng-template slot="surname" let-value="data">
          {{ value?.mainCustomer.surname }}
          <div [@slideDownUp]="!treeview1.includes('passengers-' + value.transferPlanId)" class="accordion-content">
            <ul>
              <ng-container *ngFor="let passenger of value.passengers">
                <li class="py-[5px]">
                  {{ passenger.customer?.surname }}
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <ng-template slot="dateOfBirth" let-value="data">
          {{ value?.mainCustomer.dateOfBirth }}
          <div [@slideDownUp]="!treeview1.includes('passengers-' + value.transferPlanId)" class="accordion-content">
            <ul>
              <ng-container *ngFor="let passenger of value.passengers">
                <li class="py-[5px]">
                  {{ passenger.customer?.dateOfBirth }}
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <ng-template slot="address" let-value="data">
          {{ value?.mainCustomer.address }}
          <div [@slideDownUp]="!treeview1.includes('passengers-' + value.transferPlanId)" class="accordion-content">
            <ul>
              <ng-container *ngFor="let passenger of value.passengers">
                <li class="py-[5px]">
                  {{ passenger.customer?.address }}
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <ng-template slot="phoneNumber" let-value="data">
          {{ value?.mainCustomer.phoneNumber }}
          <div [@slideDownUp]="!treeview1.includes('passengers-' + value.transferPlanId)" class="accordion-content">
            <ul>
              <ng-container *ngFor="let passenger of value.passengers">
                <li class="py-[5px]">
                  {{ passenger.customer?.phoneNumber }}
                </li>
              </ng-container>
            </ul>
          </div>
        </ng-template>
        <ng-template slot="passangerState" let-value="data">
          <span class="badge bg-success" [ngClass]="{ 'bg-danger': !value.planStatus, 'bg-success': value.planStatus }">
            <span *ngIf="value.planStatus; else unpaidTemplate">{{ "paid" | translate }}</span>
            <ng-template #unpaidTemplate>
              <span>{{ "not_paid" | translate }}</span>
            </ng-template>
          </span>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" [routerLink]="'/dashboard/transfers/' + value.transferPlanId + '/plan/details'" *hlMenuItem="let menuItem">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "group-travels_info.details" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" [routerLink]="'/dashboard/transfers/' + value.transferPlanId + '/plan/edit'" *hlMenuItem="let menuItem">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "group-travels_info.edit_passanger" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
</div>
