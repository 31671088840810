import { Store } from "@ngrx/store";
import { User } from "./model/user";
import { Router } from "@angular/router";
import { AppState } from "../store/index.selector";
import { AuthService } from "./service/auth.service";
import { Observable, Subject, takeUntil } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { toggleAnimation } from "../shared/animations";
import { AppService } from "src/app/service/app.service";
import { Language } from "../store/language/language.reducer";
import { resetUser, setUser } from "../store/auth/auth.actions";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { selectLanguageList, selectLocale } from "../store/language/language.selector";

@Component({
  moduleId: module.id,
  templateUrl: "./login.html",
  animations: [toggleAnimation],
  providers: [AuthService],
})
export class LoginComponent implements OnDestroy {
  loading = false;
  protected loginForm: FormGroup;
  passwordVisible = false;
  private destroy$: Subject<void> = new Subject<void>();
  locale$!: Observable<string>;
  languageList$!: Observable<{ code: string; name: string }[]>;
  constructor(public router: Router, private fb: FormBuilder, public storeData: Store<AppState>, private appSetting: AppService, private authService: AuthService, public translate: TranslateService) {
    this.initStore();
    this.loginForm = fb.group({
      email: new FormControl(""),
      password: new FormControl(""),
    });
  }
  @ViewChild("usernameInput") usernameInput!: ElementRef;

  ngOnInit(): void {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
      this.usernameInput.nativeElement.select();
    }, 0);
  }

  async initStore() {
    this.storeData.dispatch(resetUser());

    this.locale$ = this.storeData.select(selectLocale);
    this.languageList$ = this.storeData.select(selectLanguageList);
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  login() {
    if (this.loginForm.valid) {
      const trimmedEmail = this.loginForm.get("email")?.value.trim();
      this.loginForm.get("email")?.setValue(trimmedEmail);

      this.loading = true;
      this.authService
        .authenticate(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (user: User) => {
            this.storeData.dispatch(setUser({ user }));
            this.router.navigate(["/dashboard/sales"]);
          },
          error: () => (this.loading = false),
        });
    }
  }

  changeLanguage(item: Language) {
    this.appSetting.toggleLanguage(item);
    window.location.reload();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
