import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Ticket } from "../models/ticket";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "src/app/store/index.selector";
import { TranslateService } from "@ngx-translate/core";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TicketsService } from "../services/tickets.service";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { TransfersService } from "../../transfers/services/transfers.service.service";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";

interface Passenger {
  reservationNumber: string | null;
  ticketId: number;
  id: number;
  name: string;
  surname: string;
}

@Component({
  moduleId: module.id,
  templateUrl: "./tickets.table.html",
  animations: [toggleAnimation],
})
export class TicketsTableComponent extends BaseTableComponent<Ticket> implements OnInit, OnDestroy {
  public type = "flight";
  public transfers!: any[];
  public exportApiUrl!: string;

  private routesSubscription!: Subscription;

  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  constructor(
    private modalService: ModalService,
    public  override translate: TranslateService,
    public override store: Store<AppState>,
    private ticketsService: TicketsService,
    private route: ActivatedRoute,
    private transferService: TransfersService,
    public override tableService: TableService,
    private positioningService: PositioningService,
    public override userService: UserService
  ) {
    super(store, tableService, `tickets/paginated?type=flight`, userService, translate);
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit() {
    this.routesSubscription = this.route.params.subscribe((params) => {
      this.apiUrl = `tickets/paginated?type=${params["type"]}`;
      this.exportApiUrl = `api/Tickets/export?type=${params["type"]}`;
      super.ngOnInit();
      this.getTransfers();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routesSubscription.unsubscribe();
  }

  public checkUncheck(isChecked: boolean) {
    console.log(isChecked, typeof isChecked);
  }

  override getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("tickets_info.actions"), hide: false } as DatatableColumn,
      { field: "getStatus", title: this.translate.instant("status"), hide: true, sort: false } as DatatableColumn,
      { field: "id", title: "ID", isUnique: true, hide: true } as DatatableColumn,
      { field: "fullName", title: this.translate.instant("tickets_info.full_name"), hide: false, sort: false } as DatatableColumn,
      { field: "reservationNumber", title: this.translate.instant("tickets_info.num_of_reservations"), hide: false } as DatatableColumn,
      { field: "departureId", title: this.translate.instant("tickets_info.departure"), hide: true } as DatatableColumn,
      { field: "numberOfPassangers", title: this.translate.instant("tickets_info.numberOfPassangers"), hide: true } as DatatableColumn,
      { field: "destinationId", title: this.translate.instant("tickets_info.destination"), hide: false } as DatatableColumn,
      { field: "departureDate", title: this.translate.instant("tickets_info.departure_date"), hide: false } as DatatableColumn,
      { field: "arrivalDate", title: this.translate.instant("tickets_info.arrival_date"), hide: true } as DatatableColumn,
      { field: "reservationDate", title: this.translate.instant("tickets_info.reservation_date"), hide: true } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("tickets_info.created_date"), hide: true } as DatatableColumn,
      { field: "checked", title: this.translate.instant("tickets_info.checked"), hide: true, sort: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("tickets_info.details"), hide: false } as DatatableColumn,
    ];
  }

  public exportCols = [
    { field: "id", title: this.translate.instant("tickets-export.id"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("tickets-export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("tickets-export.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "numberOfPassengers", title: this.translate.instant("tickets-export.numberOfPassengers"), hide: false } as DatatableColumn,
    { field: "departure", title: this.translate.instant("tickets-export.departure"), hide: false } as DatatableColumn,
    { field: "destination", title: this.translate.instant("tickets-export.destination"), hide: false } as DatatableColumn,
    { field: "startDate", title: this.translate.instant("tickets-export.startDate"), hide: false } as DatatableColumn,
    { field: "returnDate", title: this.translate.instant("tickets-export.returnDate"), hide: true, sort: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("tickets-export.bruto"), hide: true, sort: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("tickets-export.neto"), hide: true, sort: false } as DatatableColumn,
    { field: "saleDate", title: this.translate.instant("tickets-export.saleDate"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("tickets-export.user"), hide: true, sort: false } as DatatableColumn,
  ];
  getReservationNumber(pass: Passenger[]) {
    if (pass) {
      const arr = pass.find((passenger) => passenger.reservationNumber);
      return arr ? arr.reservationNumber : "";
    }
    return "";
  }

  getTransfers() {
    const transfersSubscription = this.transferService.getTransfers().subscribe({
      next: (res) => {
        this.transfers = res;
        transfersSubscription.unsubscribe();
      },
      error: (err) => {
        transfersSubscription.unsubscribe();
        showMessage(PopupType.Danger, err.toString());
      },
    });
  }

  async deleteTicket(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.ticketsService.deleteTicket(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
}
