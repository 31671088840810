<div [formGroup]="selectControl">
  <ng-select
    [items]="options$ | async"
    placeholder="{{ 'please_select' | translate }}"
    [searchable]="searchable"
    [clearable]="false"
    class="custom-multiselect"
    bindLabel="title"
    [labelForId]="labelForId"
    [formControlName]="formControlName"
    typeToSearchText="{{ 'autocomplete_type_to_search' | translate : { minNum: minTermLength } }}"
    addTagText="{{ 'add_tag_text' | translate }}"
    #remoteSelect
  >
  </ng-select>
</div>
