import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { User } from 'src/app/auth/model/user';
import { map, withLatestFrom } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/store/index.selector';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { selectFeatureUser } from 'src/app/store/auth/auth.selector';
import { updateSettings } from 'src/app/store/auth/auth.actions'; 
import { UserService } from 'src/app/domain/user-profile/service/user.service';
import { setMainLayout, toggleDirection, toggleLayout, toggleMainLoader, toggleMenu, toggleTheme, toggleRTL, toggleAnimation, toggleNavbar, toggleSemidark, toggleSidebar } from 'src/app/store/layout/layout.actions';

@Injectable()
export class LayoutEffects {
  public user$!: Observable<User | undefined>;
  public user!: User;
  public settings: any = {};
  public key = "layout";

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    public translate: TranslateService,
    public userService: UserService
  ) {
    this.loadUserSettings();
  }

  loadUserSettings() {
    this.user$ = this.store.select(selectFeatureUser).pipe(
      map((user) => {
        if (user) {
          this.user = user;
          this.settings = user.settings ? JSON.parse(user.settings) : {}; 
        }
        return user;
      })
    );
    this.user$.subscribe(); 
  }

  saveLayoutSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        toggleDirection,
        toggleMainLoader,
        setMainLayout,
        toggleTheme,
        toggleMenu,
        toggleLayout,
        toggleRTL,
        toggleAnimation,
        toggleNavbar,
        toggleSemidark,
        toggleSidebar
      ),
      withLatestFrom(this.store.select('layout')), 
      map(([action, layoutState]) => {
        const updatedSettings = { ...this.settings };
        updatedSettings[this.key] = { 
          ...updatedSettings[this.key], 
          ...layoutState 
        };

        if (this.user) {
          this.store.dispatch(updateSettings({ settings: JSON.stringify(updatedSettings) }));
          this.userService.updateUser(this.user.id, { ...this.user, settings: JSON.stringify(updatedSettings) });
        }
        return action; 
      })
    ),
    { dispatch: false } 
  );
}
