<div class="fixed inset-0 z-50 flex items-center justify-center">
  <div class="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
    <div class="relative w-full max-w-md rounded-lg bg-white">
      <button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
        <icon-x class="h-5 w-5"></icon-x>
      </button>
      <h2 class="mb-4 text-lg font-semibold text-center">
        {{ editMode ? ("hotel.update" | translate) : ("hotel.add" | translate) }}
      </h2>
      <form [formGroup]="form" (ngSubmit)="submitForm()" class="w-full">
        <div class="grid grid-cols-1 gap-5 sm:grid-cols-1">
          <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="name">{{ "entity_form.name" | translate }}</label>
            <input id="name" type="text" placeholder="{{ 'entity_form.enter_name' | translate }}" class="form-input" formControlName="name" [appLimitCharacterInput]="60" #nameInput />
            <small class="text-danger" *ngIf="form.controls['name'].touched && form.controls['name'].errors"></small>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
          <div [ngClass]="form.controls['address'].touched ? (form.controls['address'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="address">{{ "entity_form.address" | translate }}</label>
            <input id="address" type="text" placeholder="{{ 'entity_form.enter_address' | translate }}" class="form-input" formControlName="address" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['address'].touched && form.controls['address'].errors"></small>
          </div>
          <div [ngClass]="form.controls['phoneNumber'].touched ? (form.controls['phoneNumber'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="phoneNumber">{{ "entity_form.phoneNumber" | translate }}</label>
            <input id="phoneNumber" type="text" placeholder="{{ 'entity_form.enter_phone_number' | translate }}" class="form-input" formControlName="phoneNumber" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['phoneNumber'].touched && form.controls['phoneNumber'].errors"></small>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5 sm:grid-cols-2">
          <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="email">{{ "entity_form.email" | translate }}</label>
            <input id="email" type="email" placeholder="{{ 'entity_form.enter_email' | translate }}" class="form-input" formControlName="email" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['email'].touched && form.controls['email'].errors"></small>
          </div>
          <div [ngClass]="form.controls['representative'].touched ? (form.controls['representative'].errors ? 'has-error' : 'has-success') : ''" class="mb-4">
            <label for="representative">{{ "entity_form.representative" | translate }}</label>
            <input id="representative" type="text" placeholder="{{ 'entity_form.enter_representative' | translate }}" class="form-input" formControlName="representative" [appLimitCharacterInput]="60" />
            <small class="text-danger" *ngIf="form.controls['representative'].touched && form.controls['representative'].errors"></small>
          </div>
        </div>
        <button type="submit" class="btn btn-primary w-full" [disabled]="isLoading || form.invalid">
          <ng-container *ngIf="!isLoading; else loadingTemplate">{{ editMode ? ("settings.update" | translate) : ("settings.save" | translate) }}</ng-container>
          <ng-template #loadingTemplate> <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle"></span> {{ "loading" | translate }} </ng-template>
        </button>
      </form>
    </div>
  </div>
</div>
