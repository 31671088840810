import { Sale } from "../models/sale";
import { Payment } from "../models/payment";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { SalesService } from "../services/sales.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PopupType } from "src/app/shared/enums/popup-types";
import { PaymentService } from "../services/payment.service";
import { toggleAnimation } from "src/app/shared/animations";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { ModalService } from "src/app/shared/services/modal.service";
import { PaymentFormComponent } from "../payment.form/payment.form.component";
import { BehaviorSubject, combineLatest, map, Observable, Subscription, tap } from "rxjs";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import * as moment from "moment";

@Component({
  selector: "app-sale-details",
  templateUrl: "./sale.details.component.html",
  animations: [toggleAnimation],
})
export class SaleDetailsComponent implements OnInit {
  @Input() hideStatus = false;
  public sale!: Sale;
  private _id!: number;
  form!: FormGroup;
  public payments!: Payment[];
  public currentPaymentId!: number;
  public totalDepth$!: Observable<number>;
  public editPayment!: Payment | undefined;
  public travelId: number | undefined;
  private routeSubscribtion!: Subscription;
  @ViewChild("modal") modal!: ModalComponent;
  @ViewChild("appPaymentForm") appPaymentForm!: PaymentFormComponent;
  @Output() notifyWhenEditAndSend: EventEmitter<boolean> = new EventEmitter<boolean>();
  messageRS = `Molim Vas za potrvdu. Racun je u dodatku emaila. `;
  messageEN = `Please confirm. Payment is in the email attachment. `;
  @Input() set id(value: number) {
    this._id = value;
    this.fetchSaleData();
  }
  get id(): number {
    return this._id;
  }
  private saleSubject = new BehaviorSubject<Sale | undefined>(undefined);
  sale$: Observable<Sale | undefined> = this.saleSubject.asObservable();
  public paymentDriveId: string | undefined;
  public planDriveId: string | undefined;

  constructor(
    public saleService: SalesService,
    public translate: TranslateService,
    private paymentService: PaymentService,
    private fb: FormBuilder,
    protected modalService: ModalService,
    private route: ActivatedRoute
  ) {
    this.totalDepth$ = combineLatest([this.sale$]).pipe(
      map(([sale]) => {
        if (!sale) return 0;
        const avans = sale.avans || 0;
        const totalPayments = sale.payments.reduce((acc: number, payment: Payment) => acc + (payment.payment || 0), 0);
        return sale.bruto - avans - totalPayments;
      })
    );
    this.form = this.fb.group({
      emails: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.routeSubscribtion = this.route.params.subscribe((params) => {
      this.travelId = params["id"];
    });
  }

  fetchSaleData() {
    const fetchSubscription = this.saleService.getSaleDetails(this.id).subscribe((response) => {
      fetchSubscription.unsubscribe();
      this.sale = response;
      this.payments = this.sale.payments.map((p) => {
        return { ...p, guid: crypto.randomUUID() };
      });
      this.saleSubject.next(this.sale);
    });
  }

  toEditPayment(item: Payment) {
    this.editPayment = undefined;
    setTimeout(() => {
      this.editPayment = item;
    });
  }

  submitPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    newPayment.paymentDate = moment(newPayment.paymentDate, "DD.MM.YYYY").format("YYYY-MM-DD");
    const updateSubscription = this.paymentService.updatePayment(newPayment).subscribe(() => {
      updateSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  discard() {
    this.editPayment = undefined;
  }

  submitNewPayment() {
    const newPayment = this.appPaymentForm.getPayment();
    newPayment.paymentDate = moment(newPayment.paymentDate, "DD.MM.YYYY").format("YYYY-MM-DD");
    const createSubscription = this.paymentService.savePayment({ ...newPayment, planId: this.id }).subscribe(() => {
      createSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  setNewPayment() {
    this.editPayment = {} as Payment;
  }

  removePayment(item: any) {
    const deleteSubscription = this.paymentService.deletePayment(item.id).subscribe(() => {
      deleteSubscription.unsubscribe();
      this.editPayment = undefined;
      this.fetchSaleData();
    });
  }

  onEditPaymentChange(editPayment: Payment | undefined) {
    this.editPayment = editPayment;
  }

  async sendInvoice() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send_avans"),
      message: this.translate.instant("common.confirm"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.sale,
      isOnlyAvans: true,
      navigateToRoute: `dashboard/travels/${this.travelId}`,
      activeTab: undefined,
      paymentId: undefined,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
  sendPlanInvoice(): void {
    this.saleService
      .getPlanDriveId(this.sale.id)
      .pipe(
        tap((response) => {
          if (response?.driveId) {
            this.planDriveId = response.driveId;
            window.open(`https://docs.google.com/document/d/${this.planDriveId}`, "_blank");
          } else {
            this.sendInvoice();
          }
        })
      )
      .subscribe();
  }

  fetchPaymentId(paymentId: number): void {
    this.currentPaymentId = paymentId;
    this.paymentService
      .getPaymentDriveId(this.currentPaymentId)
      .pipe(
        tap((response) => {
          if (response?.driveId) {
            this.paymentDriveId = response.driveId;
            window.open(`https://docs.google.com/document/d/${this.paymentDriveId}`, "_blank");
          } else {
            this.sendInvoicePayment();
          }
        })
      )
      .subscribe();
  }

  async sendInvoicePayment() {
    const confirm = await this.modalService.openModalForSendingInovice({
      title: this.translate.instant("send_avans"),
      message: this.translate.instant("common.confirm"),
      confirmButtonText: this.translate.instant("yes"),
      cancelButtonText: this.translate.instant("no"),
      plan: this.sale,
      isOnlyAvans: true,
      navigateToRoute: `dashboard/travels/${this.travelId}`,
      activeTab: undefined,
      paymentId: this.currentPaymentId,
    });
    if (confirm) {
      showMessage(PopupType.Success, this.translate.instant("popup.avans_sent_successfully"));
    }
  }
}
