import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LoginComponent } from "./login";
import { MenuModule } from "headlessui-angular";
import { HttpClient } from "@angular/common/http";
import { ReactiveFormsModule } from "@angular/forms";
import { AuthService } from "./service/auth.service";
import { Routes, RouterModule } from "@angular/router";
import { IconModule } from "src/app/shared/icon/icon.module";
import { LockscreenComponent } from "./lockscreen/lockscreen";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { DirectivesModule } from "../directives/directives.module";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent, title: "Travel Agent - Application for all Turist Agencies" },
  { path: "lock", component: LockscreenComponent, title: "Travel Agent - Application for all Turist Agencies" },
];
@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MenuModule,
    IconModule,
    ReactiveFormsModule,
    DirectivesModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [LoginComponent, LockscreenComponent],
  providers: [AuthService],
})
export class AuthModule {}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
