import * as moment from "moment";
import { Store } from "@ngrx/store";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import interactionPlugin from "@fullcalendar/interaction";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FullCalendarComponent } from "@fullcalendar/angular";
import { CalendarService } from "./services/calendar.service";
import { toggleAnimation } from "src/app/shared/animations";
import { AppState } from "src/app/store/index.selector";
import { LayoutState } from "src/app/store/layout/layout.reducer";

@Component({
  moduleId: module.id,
  templateUrl: "./calendar.html",
  animations: [toggleAnimation],
})
export class CalendarComponent implements OnInit {
  @ViewChild("isAddEventModal") isAddEventModal!: ModalComponent;
  @ViewChild("calendar") calendar!: FullCalendarComponent;
	layoutData!: LayoutState;
  params!: FormGroup;
  minStartDate: any = "";
  minEndDate: any = "";
  selectedEvent: any;
  events: any = [];
  calendarOptions: any;
  isLoading = true;
  defaultParams = {
    id: null,
    title: "",
    start: "",
    end: "",
    description: "",
    type: "primary",
  };

  constructor(public fb: FormBuilder, public state: Store<AppState>, public calendarService: CalendarService, public translate: TranslateService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.initStore();
    this.isLoading = false;
    this.params = this.fb.group({
      id: [null],
      title: [""],
      start: [""],
      end: [""],
      type: ["primary"],
      description: [""],
    });
  }
  ngOnInit() {
    const currentDate = new Date();
    this.getEvents(currentDate.getFullYear(), currentDate.getMonth());
  }
  async initStore() {
    this.state
      .select((d: AppState) => d.layout)
      .subscribe((d: LayoutState) => {
        const hasChangeLayout = this.layoutData?.layout !== d?.layout;
        const hasChangeMenu = this.layoutData?.menu !== d?.menu;
        const hasChangeSidebar = this.layoutData?.sidebar !== d?.sidebar;

        this.layoutData = d;

        if (hasChangeLayout || hasChangeMenu || hasChangeSidebar) {
          this.activatedRoute.queryParams.subscribe((params) => {
            const currentMonth = parseInt(params["month"]);
            const currentYear = parseInt(params["year"]);
            if (this.calendar) {
              const calendarApi = this.calendar.getApi();
              calendarApi.gotoDate(new Date(currentYear, currentMonth, 1));
            }
            if (this.isLoading) {
              this.initCalendar(currentMonth, currentYear);
              this.calendarOptions.events = [];
            } else {
              setTimeout(() => {
                this.initCalendar(currentMonth, currentYear);
              }, 300);
            }
          });
        }
      });

    this.calendar;
  }

  initCalendar(currentMonth: number, currentYear: number) {
    const initialDate = new Date(currentYear, currentMonth, 1);
    this.calendarOptions = {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: "dayGridMonth",
      headerToolbar: {
        left: "prev,today",
        center: "title",
        right: "today,next",
      },
      initialDate: initialDate,
      editable: false,
      dayMaxEvents: true,
      defaultAllDay: true,
      selectable: false,
      droppable: false,
      eventClick: (event: any) => {
        this.editEvent(event);
      },
      select: (event: any) => {
        this.editDate(event);
      },
      dateClick: (info: any) => {
        const clickedDate = info.date;
        this.getEvents(clickedDate.getFullYear(), clickedDate.getMonth());
      },
      datesSet: (info: any) => {
        const newDate = info.view.currentStart;
        this.updateEvents(newDate.getFullYear(), newDate.getMonth());
        this.setCurrentDateAsQueryParam(newDate.getMonth(), newDate.getFullYear());
      },
    };
    this.getEvents(initialDate.getFullYear(), initialDate.getMonth());
  }
  updateEvents(year: number, month: number) {
    this.getEvents(year, month);
  }

  setCurrentDateAsQueryParam(currentMonth: number, currentYear: number) {
    const queryParams = {
      month: currentMonth,
      year: currentYear,
    };

    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge", // to merge with existing query params
      skipLocationChange: false, // if true, does not push a new state into history
    });
  }

  getEvents(year: number, month: number) {
    const now = new Date();
    const currentYear = year || now.getFullYear();
    const currentMonth = month || now.getMonth();

    this.calendarService.getEvents(currentYear, currentMonth).subscribe((events) => {
      this.events = events.reduce((acc: any[], e) => {
        acc.push({
          ...e,
          id: e.id,
          title: `${this.getLabel(e.entity_name)}  ${e.destination.description}`,
          start: e.event_starts,
          end: e.event_starts,
          className: this.getClass(e.entity_name),
          description: "Start of event",
          eventType: "start",
        });

        if (new Date(e.event_ends).getTime() !== new Date(e.event_starts).getTime()) {
          acc.push({
            ...e,
            id: e.id,
            title: `${this.getLabel(e.entity_name)}  ${e.destination.description}`,
            start: e.event_ends,
            end: e.event_ends,
            className: this.getClass(e.entity_name),
            description: "End of event",
            eventType: "end",
          });
        }

        return acc;
      }, []);

      this.calendarOptions.events = this.events;
    });
  }

  stringify(obj: any) {
    return JSON.stringify(obj);
  }

  getLabel(entity: string) {
    return this.translate.instant(`${entity.split(" ").join("-").toLowerCase()}-label`);
  }

  getClass(entity: string) {
    switch (entity) {
      case "Travel":
        return "bg-primary";
      case "Ticket":
        return "bg-info";
      case "GroupTravel":
        return "bg-success";
      case "Transfer":
        return "bg-danger";
      default:
        return "";
    }
  }

  getMonth(dt: Date, add = 0) {
    const month = dt.getMonth() + 1 + add;
    const str = (month < 10 ? "0" + month : month).toString();
    return str;
  }

  editEvent(data: any = null) {
    this.params.reset(this.defaultParams);
    this.isAddEventModal.open();
    if (data) {
      const obj = JSON.parse(JSON.stringify(data.event));
      this.selectedEvent = obj;
      this.params.setValue({
        id: obj.id ? obj.id : null,
        title: obj.title ? obj.title : null,
        start: this.dateFormat(obj.start),
        end: this.dateFormat(obj.end),
        type: obj.classNames ? obj.classNames[0] : "primary",
        description: obj.extendedProps ? obj.extendedProps.description : "",
      });
      this.minStartDate = new Date();
      this.minEndDate = this.dateFormat(obj.start);
    } else {
      this.minStartDate = new Date();
      this.minEndDate = new Date();
    }
  }

  editDate(data: any) {
    const obj = {
      event: {
        start: data.start,
        end: data.end,
      },
    };
    this.editEvent(obj);
  }

  dateFormat(dt: any) {
    return moment(dt).format("YYYY-MM-DDTHH:mm");
  }

  saveEvent(data: any = null) {
    this.params.reset(this.defaultParams);
    this.isAddEventModal.open();

    if (data) {
      this.selectedEvent = data.event;
      const obj = JSON.parse(JSON.stringify(data.event));
      this.params.setValue({
        id: obj.id ? obj.id : null,
        title: obj.title ? obj.title : null,
        start: this.dateFormat(obj.start),
        end: this.dateFormat(obj.end),
        type: obj.classNames ? obj.classNames[0] : "primary",
        description: obj.extendedProps ? obj.extendedProps.description : "",
      });
      this.minStartDate = new Date();
      this.minEndDate = this.dateFormat(obj.start);
    } else {
      this.minStartDate = new Date();
      this.minEndDate = new Date();
    }
  }
  showEventDetails() {
    this.isAddEventModal.open();
  }

  startDateChange(event: any) {
    const dateStr = event.target.value;
    if (dateStr) {
      this.minEndDate = this.dateFormat(dateStr);
      this.params.patchValue({ end: "" });
    }
  }
  navigateToDetails(entityId: number, entityName: string) {
    let baseUrl: string;

    switch (entityName) {
      case "Travel":
        baseUrl = "/dashboard/travels";
        break;
      case "Ticket":
        baseUrl = "/dashboard/tickets/flight";
        break;
      case "Organized Travel":
        baseUrl = "/dashboard/group-travels";
        break;
      case "Transfer":
        baseUrl = "/dashboard/transfers";
        break;
      default:
        baseUrl = "/dashboard";
    }

    const url = `${baseUrl}/${entityId}`;
    this.router.navigateByUrl(url);
  }
}
