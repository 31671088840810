<!-- <div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end">
  <div hlMenu class="dropdown relative right-0 pt-1">
    <a hlMenuButton href="javascript:;" class="inline-block transform rounded-xl border-2 border-white-dark/100 bg-slate-100 p-2 transition-transform hover:scale-110 hover:shadow-lg">
      <icon-horizontal-dots class="rotate-90 opacity-70" />
    </a>
    <div #dropdownPlaceholder *hlMenuItems>
      <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
        <li>
          <a href="javascript:;" *hlMenuItem="let menuItem">
            <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
            {{ "edit_quick_label" | translate }}
          </a>
        </li>
        <li>
          <a href="javascript:;" *hlMenuItem="let menuItem">
            <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
            {{ "delete_quick_label" | translate }}
          </a>
        </li>
      </app-message-portal>
    </div>
  </div>
</div> -->
<div class="space-y-8">
  <div *ngIf="hotelData" class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-1 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-1 flex grid grid-cols-1 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.basic_information" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex items-center pl-2 sm:col-span-2">
          <div class="flex flex-col">
            <div class="text-white-dark">{{ "hotel_data.name" | translate }}</div>
            <div class="text-xl font-bold">{{ hotelData.name }}</div>
            <div class="text-[15px]" style="font-style: italic"></div>
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div class="flex flex-col p-2">
            <div class="text-[15px]">{{ "hotel_data.phone_number" | translate }}:</div>
            <div>{{ hotelData.phoneNumber }}</div>
          </div>
          <div class="flex flex-col p-2">
            <div class="text-[15px]>">{{ "hotel_data.email" | translate }}:</div>
            <div>{{ hotelData.email }}</div>
          </div>
          <div class="flex flex-col p-2 text-[15px]">
            <div lass="text-[15px]>">{{ "hotel_data.address" | translate }}:</div>
            <div>{{ hotelData.address }}</div>
          </div>
          <div class="flex flex-col p-2 text-[15px]">
            <div lass="text-[15px]>">{{ "hotel_data.representative" | translate }}:</div>
            <div>{{ hotelData.representative }}</div>
          </div>
        </div>
      </div>

      <div class="gric-cols-2 col-span-2 grid pl-2 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col pl-2 sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.createdAt" | translate }} : {{ hotelData.createdAt | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div style="font-size: 0.875rem; color: darkslategray">{{ "customer_details.updatedAt" | translate }} : {{ hotelData.updatedAt | date : "dd.MM.yyyy" }}</div>
        </div>
      </div>

			<div class="col-span-2 flex flex-col rounded-lg p-4 sm:grid sm:grid-cols-4 sm:col-span-4">
        <div class="col-span-1 flex flex-col pb-4 sm:col-span-2">
          <div class="mb-4 text-xl font-bold ">
            {{ "customer_stats.payments" | translate }}
          </div>
          <div class="p-1">{{ "hotel_statistics.bruto" | translate }}:</div>
          <div class="p-1">{{ hotelData.bruto | currency : "EUR" }}</div>

          <div class="p-1">{{ "hotel_statistics.dept" | translate }}:</div>
          <div class="p-1">{{ hotelData.dept | currency : "EUR" }}</div>

          <div class="p-1">{{ "hotel_statistics.brutoNetoDiff" | translate }}:</div>
          <div class="p-1">{{ hotelData.brutoNetoDiff | currency : "EUR" }}</div>
        </div>

        <div class="col-span-1 pb-4" >
          <div class="mb-4 text-xl font-bold">
            {{ "statistics" | translate }}
          </div>
          <h3 class="mb-2">{{ "webchart.annualattendance" | translate }}:</h3>
          <apx-chart
            *ngIf="chartData.length > 0"
            [series]="chartOptions?.series"
            [chart]="chartOptions?.chart"
            [xaxis]="chartOptions?.xaxis"
            [yaxis]="chartOptions?.yaxis"
            [legend]="chartOptions?.legend"
            [dataLabels]="chartOptions?.dataLabels"
          ></apx-chart>
          <div class="mt-4" *ngIf="monthlyChartOptions?.series && monthlyChartOptions?.series.length > 0">
            <h3 class="mb-2">{{ "webchart.monthlyattendance" | translate }}:</h3>
            <apx-chart
              [series]="monthlyChartOptions?.series"
              [chart]="monthlyChartOptions?.chart"
              [xaxis]="monthlyChartOptions?.xaxis"
              [yaxis]="monthlyChartOptions?.yaxis"
              [legend]="monthlyChartOptions?.legend"
              [dataLabels]="monthlyChartOptions?.dataLabels"
              class="rounded-lg bg-white dark:bg-black"
            ></apx-chart>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "customer_stats.services" | translate }}
          </div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "hotel_data.numberOfArrangements" | translate }}:</div>
          <div>{{ hotelData.numberOfArrangements }}</div>
        </div>
        <div class="col-span-1 flex flex-col sm:col-span-2">
          <div>{{ "hotel_data.numberOfGroupDestinations" | translate }}:</div>
          <div>{{ hotelData.numberOfGroupDestinations }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <h5 class="text-lg font-semibold dark:text-white-light">{{ "show_hide_columns" | translate }}</h5>
      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="relative">
          <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input pr-10" placeholder="{{ 'search_placeholder' | translate }}" />
          <span class="absolute inset-y-0 right-0 flex items-center pr-3">
            <icon-search class="text-gray-500"></icon-search>
          </span>
        </div>
        <app-export-table
          [exportApi]="exportApiUrl"
          [filterType]="filterType"
          [sortColumn]="'none'"
          [sortDirection]="'asc'"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Hotel Table Export'"
          [additionalInfo]="additionalInfo"
        ></app-export-table>
      </div>
    </div>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [page]="pageNumber"
        [search]="search"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >
        <ng-template slot="createdAtFormated" let-value="data"> {{ value.createdAt | date : "dd.MM.yyyy" }} </ng-template>
        <ng-template slot="destinations" let-value="data">
          <span>{{ value.departure?.description }}, {{ value.destination?.description }}</span>
        </ng-template>
        <ng-template slot="getStatus" let-value="data">
          <span *ngIf="value.status; else elseBlock" class="badge bg-success">{{ "paid" | translate }}</span>
          <ng-template #elseBlock>
            <span class="badge bg-danger">{{ "not-paid" | translate }}</span>
          </ng-template>
        </ng-template>
        <ng-template slot="bruto" let-value="data">{{ value.bruto | currency : "EUR" }}</ng-template>
        <ng-template slot="depth" let-value="data"
          ><span class="text-danger">{{ value.depth | currency : "EUR" }}</span>
        </ng-template>
        <ng-template slot="actions" let-value="data">
          <div class="flex items-center">
            <div>
              <a [routerLink]="'/dashboard/hotels/' + value.id" type="button" ngxTippy="Delete" class="btn btn-dark h-10 w-10 rounded-full p-0">
                <icon-info-circle />
              </a>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
</div>
