import { Routes } from "@angular/router";
import { HasIdGuard } from "./guards/has-id.guard";
import { HasRoleGuard } from "./guards/has-role.guard";
import { AppLayoutComponent } from "./layouts/app-layout";
import { AuthLayoutComponent } from "./layouts/auth-layout";
import { CalendarComponent } from "./domain/calendar/calendar";
import { HotelComponent } from "./domain/hotels/hotel/hotel.component";
import { PackageRightsGuard } from "./guards/has-package-rights.guard";
import { Error404Component } from "./shared/components/error404/error404";
import { WorkersComponent } from "./domain/workers/worker/worker.component";
import { BookComponent } from "./domain/evidence-book/book/book.component";
import { CompanyComponent } from "./domain/companies/company/company.component";
import { DailySaleComponent } from "./domain/sales/daily-sale/daily-sale.component";
import { TicketsTableComponent } from "./domain/tickets/tickets.table/tickets.table";
import { SalesTableComponent } from "./domain/sales/sales.table/sales.table.component";
import { OrganizerComponent } from "./domain/organizers/organizer/organizer.component";
import { TicketCheckoutComponent } from "./checkouts/ticket/ticket.checkout.component";
import { DailyActionsComponent } from "./domain/daily-actions/daily-actions.component";
import { VoucherComponent } from "./domain/documents/voucher/voucher/voucher.component";
import { HotelsTableComponent } from "./domain/hotels/hotels.table/hotels.table.component";
import { StatisticsMoneyComponent } from "./domain/statisticsmoney/statisticsmoney.component";
import { TravelsTableComponent } from "./domain/travels/travels.table/travels.table.component";
import { DestinationComponent } from "./domain/destinations/destination/destination.component";
import { SettingsListComponent } from "./domain/settings/settings-list/settings-list.component";
import { TransferEditComponent } from "./domain/transfers/transfer.edit/transfer.edit.component";
import { UserProfileComponent } from "./domain/user-profile/user-profile/user-profile.component";
import { GroupTravelComponent } from "./domain/group-travels/group-travel/group-travel.component";
import { TravelDetailsComponent } from "./domain/travels/travel.details/travel.details.component";
import { TicketDetailsComponent } from "./domain/tickets/ticket.details/ticket.details.component";
import { CustomerStatsComponent } from "./domain/customers/customer.stats/customer.stats.component";
import { TravelContractComponent } from "./domain/travels/travel-contract/travel-contract.component";
import { TravelVouchersComponent } from "./domain/travels/travel-vouchers/travel-vouchers.component";
import { CustomersTableComponent } from "./domain/customers/customer.table/customers.table.component";
import { TransferCreateComponent } from "./domain/transfers/transfer.create/transfer.create.component";
import { ETouristTableComponent } from "./domain/e-tourist/e-tourist.table/e-tourist.table.component";
import { TransfersTableComponent } from "./domain/transfers/transfers.table/transfers.table.component";
import { CompaniesTableComponent } from "./domain/companies/companies.table/companies.table.component";
import { OrganizersTableComponent } from "./domain/organizers/organizers.table/organizers.table.component";
import { TransfersDetailsComponent } from "./domain/transfers/transfers.details/transfers.details.component";
import { DailySaleEditComponent } from "./domain/sales/daily-sale/daily-sale.edit/daily-sale.edit.component";
import { TransferPlanComponent } from "./domain/transfers/transfer-plan/transfer-plan.component";
import { TravelVoucherEditComponent } from "./domain/travels/travel-voucher.edit/travel-voucher.edit.component";
import { TravelContractEditComponent } from "./domain/travels/travel-contract.edit/travel-contract.edit.component";
import { DestinationsTableComponent } from "./domain/destinations/destinations.table/destinations.table.component";
import { TravelCheckoutEditComponent } from "./checkouts/travel/travel-checkout.edit/travel-checkout.edit.component";
import { GroupTravelsTableComponent } from "./domain/group-travels/group-travels.table/group-travels.table.component";
import { WorkersOfficesTableComponent } from "./domain/workers/workers-offices.table/workers-offices.table.component";
import { OfficeBranchTableComponent } from "./domain/office-branch/office-branch.table/office-branch.table.component";
import { GroupTravelDetailsComponent } from "./domain/group-travels/group-travel.details/group-travel.details.component";
import { DailyWorkerSummaryComponent } from "./domain/daily-actions/daily-worker-summary/daily-worker-summary.component";
import { OtherServicesTableComponent } from "./domain/other-services/other-services.table/other-services-table.component";
import { DailySaleDocumentComponent } from "./domain/sales/daily-sale/daily-sale.document/daily-sale.document.component";
import { GroupTravelContractComponent } from "./domain/group-travels/group-travel-contract/group-travel-contract.component";
import { TravelCheckoutCreateComponent } from "./checkouts/travel/travel-checkout.create/travel-checkout.create.component";
import { GroupTravelPlanComponent } from "./domain/group-travels/group-travel-plan/group-travel-plan.component";
import { TransferPassangersTableComponent } from "./domain/transfers/transfer-passanger-table/transfer-passanger-table.component";
import { GroupTravelVoucherListComponent } from "./domain/group-travels/group-travel-voucher-list/group-travel-voucher-list.component";
import { GroupTravelVoucherEditComponent } from "./domain/group-travels/group-travel-voucher-edit/group-travel-voucher-edit.component";
import { GroupTravelContractEditComponent } from "./domain/group-travels/group-travel-contract-edit/group-travel-contract-edit.component";
import { GroupTravelPlanEditComponent } from "./domain/group-travels/group-travel-plan-edit/group-travel-plan-edit.component";
import { TransferPlanEditComponent } from "./domain/transfers/transfer-plan-edit/transfer-plan-edit.component";
import { OtherServicesCreateComponent } from "./domain/other-services/other-services.create/other-services-create.component";
import { OtherServicesDetailsComponent } from "./domain/other-services/other-services.details/other-services.details.component";
import { OtherServicesEditComponent } from "./domain/other-services/other-services.edit/other-services.edit.component";
import { TravelCheckoutEditTravelComponent } from "./checkouts/travel/travel-checkout.edit.travel/travel-checkout-edit-travel.component";
import { AnnouncementEditComponent } from "./domain/travels/announcement/announcement.edit/announcement.edit.component";
import { OfficeBranchListComponent } from "./domain/office-branch/office-branch.list/office-branch.list.component";
import { LayoutComponent } from "./domain/settings/layout/layout.component";
import { SettingsCreateComponent } from "./domain/settings/settings.create/settings.create.component";
import { DangerZoneComponent } from "./domain/settings/danger-zone/danger-zone.component";

export const routes: Routes = [
  { path: "", redirectTo: "/auth/login", pathMatch: "full" },
  {
    path: "dashboard",
    component: AppLayoutComponent,
    data: { breadcrumb: "dashboard" },
    children: [
      {
        path: "customers",
        data: { breadcrumb: "customers" },
        children: [
          { path: "", component: CustomersTableComponent, data: { breadcrumb: "customers" } },
          { path: ":id", component: CustomerStatsComponent, canActivate: [PackageRightsGuard], data: { breadcrumb: "customer_details_label", packageRequirment: "passengersDatabase" } },
        ],
      },
      {
        path: "tickets",
        children: [
          { path: "", component: TicketsTableComponent, data: { breadcrumb: "tickets" } },
          {
            path: ":type",
            data: { breadcrumb: "tickets" },
            children: [
              { path: "", component: TicketsTableComponent },
              { path: ":id", component: TicketDetailsComponent, data: { breadcrumb: "ticket_details_label" } },
              { path: "create/new", component: TicketCheckoutComponent, data: { breadcrumb: "create_ticket" } },
              { path: "edit/:id", component: TicketCheckoutComponent, data: { breadcrumb: "edit_ticket" } },
            ],
          },
        ],
      },
      {
        path: "travels",
        data: { breadcrumb: "travels_label" },
        children: [
          { path: "", component: TravelsTableComponent },
          { path: "draft/create", component: TravelCheckoutEditComponent, data: { breadcrumb: "create_travel" } },
          { path: "draft/accept/:id", component: TravelCheckoutEditComponent, data: { breadcrumb: "create_travel" } },
          { path: "edit/:id", component: TravelCheckoutEditComponent, data: { breadcrumb: "edit_travel" } },
          { path: "draft/edit/:id", component: TravelCheckoutEditComponent, data: { breadcrumb: "edit_draft_travel" } },
          { path: "create", component: TravelCheckoutCreateComponent, data: { breadcrumb: "create_travel_draft_passenger" } },
          { path: "draft/edit-travel/:id", component: TravelCheckoutEditTravelComponent, data: { breadcrumb: "travel_edit" } },
          {
            path: ":id",
            data: { breadcrumb: "travel_details_label" },
            children: [
              { path: "", component: TravelDetailsComponent },
              { path: "voucher", component: VoucherComponent, data: { breadcrumb: "voucher" } },
              { path: "voucher/:voucherId/edit", component: TravelVoucherEditComponent, data: { breadcrumb: "edit_voucher" } },
              { path: "voucher-list", component: TravelVouchersComponent, data: { breadcrumb: "voucher_list" } },
              { path: "contract", component: TravelContractComponent, data: { breadcrumb: "contract_label" } },
              { path: "contract/:contractId", component: TravelContractEditComponent, data: { breadcrumb: "edit_contract" } },
              { path: "announcement/:announcementid/edit", component: AnnouncementEditComponent, data: { breadcrumb: "announcement_edit" } },
            ],
          },
        ],
      },
      {
        path: "transfers",
        data: { breadcrumb: "transfers" },
        children: [
          { path: "", component: TransfersTableComponent },
          { path: "create", component: TransferCreateComponent, data: { breadcrumb: "create_transfer" } },
          { path: ":id", component: TransfersDetailsComponent, data: { breadcrumb: "transfer_details_label" } },
          { path: "edit/:id", component: TransferEditComponent, data: { breadcrumb: "edit_transfer" } },
          { path: ":type/passangers/:transferId/:id", component: TransferPassangersTableComponent, data: { breadcrumb: "transfer_passengers" } },
          { path: ":id/plan", component: TransferPlanComponent, data: { breadcrumb: "transfer_plan" } },
          { path: ":id/plan/edit", component: TransferPlanEditComponent, data: { breadcrumb: "transfer_plan_edit" } },
        ],
      },
      {
        path: "hotels",
        data: { breadcrumb: "hotels" },
        children: [
          { path: "", component: HotelsTableComponent },
          { path: ":id", component: HotelComponent, data: { breadcrumb: "hotel_details" } },
        ],
      },
      {
        path: "organizers",
        data: { breadcrumb: "organizers" },
        children: [
          { path: "", component: OrganizersTableComponent, data: { breadcrumb: "organizers" } },
          { path: ":id", component: OrganizerComponent, data: { breadcrumb: "organizer_details" } },
        ],
      },
      {
        path: "destinations",
        data: { breadcrumb: "destinations" },
        children: [
          { path: "", component: DestinationsTableComponent, data: { breadcrumb: "destinations" } },
          { path: ":id", component: DestinationComponent, data: { breadcrumb: "destination_details" } },
        ],
      },
      {
        path: "companies",
        data: { breadcrumb: "companies" },
        children: [
          { path: "", component: CompaniesTableComponent, data: { breadcrumb: "companies" } },
          { path: ":id", component: CompanyComponent, data: { breadcrumb: "company_details" } },
        ],
      },
      {
        path: "sales",
        data: { breadcrumb: "sales" },
        children: [{ path: "", component: SalesTableComponent, data: { breadcrumb: "sales" } }],
      },
      {
        path: "",
        children: [
          {
            path: "daily_actions",
            component: DailyActionsComponent,
            canActivate: [PackageRightsGuard, HasRoleGuard],
            data: { breadcrumb: "daily_actions", role: ["Agent", "OfficeBranchManager"], packageRequirment: "creationOfDailySummaries" },
          },
        ],
      },
      {
        path: "daily_actions_worker",
        data: { breadcrumb: "daily_action_worker" },
        children: [{ path: "", component: DailyWorkerSummaryComponent, canActivate: [HasRoleGuard], data: { breadcrumb: "daily_action_worker", role: ["Admin", "OfficeBranchManager"] } }],
      },
      {
        path: "daily-sale",
        canActivate: [PackageRightsGuard],
        data: { breadcrumb: "daily_sale", packageRequirment: "creationOfDailySalesOrders" },
        children: [
          { path: "", component: DailySaleComponent },
          { path: "document/:id", component: DailySaleDocumentComponent, data: { breadcrumb: "daily_sale_document" } },
          { path: "document/edit/:id", component: DailySaleEditComponent, data: { breadcrumb: "edit_daily_sale_document" } },
        ],
      },
      {
        path: "group-travels",
        data: { breadcrumb: "group_travels" },
        children: [
          { path: "", component: GroupTravelsTableComponent },
          { path: "create", component: GroupTravelComponent, data: { breadcrumb: "create_group_travel" } },
          { path: "edit/:id", component: GroupTravelComponent, data: { breadcrumb: "edit_group_travel" } },
          {
            path: ":id",
            children: [
              { path: "", component: GroupTravelDetailsComponent, data: { breadcrumb: "group_travel_details_label" } },
              { path: "voucher-list", component: GroupTravelVoucherListComponent, data: { breadcrumb: "group_travel_voucher_list" } },
              { path: "voucher/:voucherId/edit", component: GroupTravelVoucherEditComponent, data: { breadcrumb: "group_travel_voucher_edit" } },
              { path: "contract", component: GroupTravelContractComponent, data: { breadcrumb: "group_travel_contract" } },
              { path: "contract/:contractId/edit", component: GroupTravelContractEditComponent, data: { breadcrumb: "group_travel_contract_edit" } },
            ],
          },
          { path: ":id/plan", component: GroupTravelPlanComponent, data: { breadcrumb: "group_travel_plan" } },
          { path: ":id/plan/edit", component: GroupTravelPlanEditComponent, data: { breadcrumb: "group_travel_plan_edit" } },
        ],
      },
      {
        path: "workers",
        data: { breadcrumb: "workers" },
        children: [
          {
            path: "",
            component: WorkersOfficesTableComponent,
            canActivate: [HasRoleGuard],
            data: { breadcrumb: "workers", role: ["Admin", "OfficeBranchManager"] },
          },
          { path: ":id", component: WorkersComponent, data: { breadcrumb: "worker_details", role: ["Admin", "OfficeBranchManager"] }, canActivate: [HasIdGuard] },
        ],
      },
      { path: "calendar", component: CalendarComponent, canActivate: [PackageRightsGuard], data: { breadcrumb: "calendar", packageRequirment: "calendarAccounting" } },
      {
        path: "settings",
        component: SettingsListComponent,
        data: { breadcrumb: "settings_label" },
        children: [
          { path: "profile", component: UserProfileComponent },
          { path: "picklists", component: SettingsCreateComponent },
          { path: "office-branches", component: OfficeBranchListComponent },
          { path: "danger-zone", component: DangerZoneComponent },
          { path: "layout", component: LayoutComponent },
        ],
      },
      { path: "evidence-book", component: BookComponent, data: { breadcrumb: "evidence_book_label" } },
      { path: "office-branch", component: OfficeBranchTableComponent, data: { breadcrumb: "office_branch" } },
      {
        path: "statistics",
        component: StatisticsMoneyComponent,
        canActivate: [HasRoleGuard, PackageRightsGuard],
        data: { breadcrumb: "statistics", role: ["Admin", "OfficeBranchManager"], redirectTo: "/dashboard/workers", packageRequirment: "passengersDatabase" },
      },
      { path: "user-profile", component: UserProfileComponent, data: { breadcrumb: "user-profile" } },
      { path: "e-tourist", component: ETouristTableComponent, data: { breadcrumb: "e-tourist" } },
      {
        path: "other-services",
        data: { breadcrumb: "other_services" },
        children: [
          { path: "create", component: OtherServicesCreateComponent, data: { breadcrumb: "other_services_create" } },
          { path: "", component: OtherServicesTableComponent, data: { breadcrumb: "other_services" } },
          { path: ":id", component: OtherServicesDetailsComponent, data: { breadcrumb: "other_services_details" } },
          { path: "edit/:id", component: OtherServicesEditComponent, data: { breadcrumb: "edit_other_service" } },
        ],
      },
    ],
  },
  {
    path: "auth",
    component: AuthLayoutComponent,
    children: [
      {
        path: "",
        loadChildren: () => import("./auth/auth.module").then((d) => d.AuthModule),
      },
    ],
  },
  { path: "**", component: Error404Component },
];
