import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit, OnDestroy } from "@angular/core";
import { TicketsService } from "../../tickets/services/tickets.service";
import { TicketQuickView } from "../../tickets/models/ticket-quick-view";

@Component({
  selector: "app-ticket-quick-view",
  templateUrl: "./ticket-quick-view.component.html",
})
export class TicketQuickViewComponent implements OnInit, OnDestroy {
  public data!: TicketQuickView;
  @Input() id!: number | undefined;
  private getSubscription!: Subscription;

  constructor(public translate: TranslateService, private ticketService: TicketsService) {}

  ngOnInit(): void {
    if (this.id) {
      this.fetchTicketQuickView(this.id);
    }
  }
  fetchTicketQuickView(id: number) {
    this.getSubscription = this.ticketService.getTicketQuickView(id).subscribe((ticket) => {
      this.data = ticket;
    });
  }
  ngOnDestroy(): void {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
  }
}
