import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LimitCharacterInputDirective } from "./limit-character-input.directive";
import { HasRoleDirective } from "./has-role.directive";
import { HasPackageRightsDirective } from "./has-package-rights.directive";

@NgModule({
  declarations: [LimitCharacterInputDirective, HasRoleDirective, HasPackageRightsDirective],
  imports: [CommonModule],
  exports: [LimitCharacterInputDirective, HasRoleDirective, HasPackageRightsDirective],
})
export class DirectivesModule {}
