import { createAction, props } from "@ngrx/store";

export const setLanguage = createAction(
  "[Settings] Set Language",
  props<{ locale: string }>()
);

export const loadLanguages = createAction(
  "[Settings] Load Language List"
);
