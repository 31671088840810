import { Store } from "@ngrx/store";
import { User } from "src/app/auth/model/user";
import { UserService } from "../service/user.service";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Component, OnInit, ViewChild } from "@angular/core";
import { updateUser } from "src/app/store/auth/auth.actions";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-user-profile",
  templateUrl: "./user-profile.component.html",
})
export class UserProfileComponent implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;
  editProfile = false;
  userForm!: FormGroup;
  user!: User;
  isDisabled = false;
  updatedUser!: User;
  constructor(private fb: FormBuilder, private userService: UserService, public storeData: Store<AppState>, public translate: TranslateService) {}

  ngOnInit(): void {
    this.userService.getUserData().subscribe((response) => {
      this.user = response;
      this.initForm();
    });
  }

  initForm() {
    this.userForm = this.fb.group({
      first_name: [{ value: this.user.name, disabled: true }, Validators.required],
      last_name: [{ value: this.user.surname, disabled: true }, Validators.required],
      email: [{ value: this.user.email, disabled: true }, Validators.required],
      imageUrl: [this.user.imageUrl, Validators.required],
      role: [{ value: this.user.role, disabled: true }, Validators.required],
      uniqueMasterCitizenNumber: [{ value: this.user.uniqueMasterCitizenNumber, disabled: true }, Validators.required],
    });
  }

  editUser() {
    this.editProfile = true;
    this.setFormState();
  }

  passwordUpdated(successful: boolean) {
    if (successful) {
      this.modal.close();
    }
  }

  submitUserForm() {
    this.editProfile = false;
    this.setFormState();
    const userUpdateSub = this.userService.updateUser(this.user.id, this.fetchUpdatedUserData()).subscribe({
      next: (res) => {
        this.updatedUser = res;
        const data = {
          name: this.updatedUser.name,
          surname: this.updatedUser.surname,
          settings: this.updatedUser.settings,
          uniqueMasterCitizenNumber: this.updatedUser.uniqueMasterCitizenNumber,
        };
        this.storeData.dispatch(updateUser({ user: data }));

        showMessage(PopupType.Success, this.translate.instant("popup.user_updated_successfully"));
        userUpdateSub.unsubscribe();
      },
      error: (error) => {
        showMessage(PopupType.Danger, error.toString());
        userUpdateSub.unsubscribe();
      },
    });
  }

  private setFormState() {
    if (this.editProfile) {
      this.userForm.get("first_name")?.enable();
      this.userForm.get("last_name")?.enable();
      this.userForm.get("uniqueMasterCitizenNumber")?.enable();
    } else {
      this.userForm.get("first_name")?.disable();
      this.userForm.get("last_name")?.disable();
      this.userForm.get("uniqueMasterCitizenNumber")?.disable();
    }
  }

  fetchUpdatedUserData() {
    const { first_name, last_name, email, imageUrl, role, uniqueMasterCitizenNumber } = this.userForm.getRawValue();
    const data = {
      username: this.user.username,
      email: email,
      role: role,
      imageUrl: imageUrl,
      name: first_name,
      surname: last_name,
      uniqueMasterCitizenNumber,
    };

    return data;
  }
}
