<div class="mb-1 rounded border border-[#d3d3d3] dark:border-[#1b2e4b]">
  <button type="button" class="flex h-5 w-full items-center p-4 text-white-dark dark:bg-[#1b2e4b]" [ngClass]="{ '!text-primary': isOpen }" (click)="toggleAccordion()">
    {{ content.destination?.description }}
    <div class="ltr:ml-auto rtl:mr-auto" [ngClass]="{ 'rotate-180': isOpen }">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4">
        <path d="M19 9L12 15L5 9" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  </button>
  <div [@slideDownUp]="!isOpen" class="accordion-content">
    <div class="space-y-2 border-t border-[#d3d3d3] p-4 text-[13px] text-white-dark dark:border-[#1b2e4b]">
      <p>{{ "travel_details.hotel" | translate }}:<br />{{ content.hotel?.name }}</p>
      <p>{{ "travel_details.service_type" | translate }}:<br />{{ content.service?.title }}</p>
      <p>{{ "travel_details.days_nights" | translate }}:<br />{{ content.days }} / {{ content.nights }}</p>
      <p>{{ "travel_details.departure_date" | translate }}:<br />{{ content.start | date : "dd.MM.yyyy" }}</p>
      <p>{{ "travel_details.return_date" | translate }}:<br />{{ content.end | date : "dd.MM.yyyy" }}</p>
    </div>
  </div>
</div>
