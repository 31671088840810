import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnInit } from "@angular/core";
import { Customer } from "../../customers/models/customer";
import { TravelWithPlan } from "../../travels/models/travel";
import { TravelsService } from "../../travels/services/travels.service";

@Component({
  selector: "app-travel-quick-view",
  templateUrl: "./travel-quick-view.component.html",
})
export class TravelQuickViewComponent implements OnInit {
  @Input() data!: TravelWithPlan;
  @Input() id!: number;

  constructor(public translate: TranslateService, private travelService: TravelsService) {}

  ngOnInit(): void {
    if (this.id) {
      const getSubscription = this.travelService.getTravelData(this.id).subscribe((travel) => {
        getSubscription.unsubscribe();
        this.data = travel;
      });
    }
  }

  getReservationNumber(pass: Customer[]) {
    if (pass) {
      const arr = pass.map((passenger) => passenger.reservationNumber);
      return arr.join(", ");
    }
    return "";
  }
}
