import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Sale } from "../../sales/models/sale";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Organizer, OrganizerStatistics } from "../models/organizers";

@Injectable({
  providedIn: "root",
})
export class OrganizersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  storeData: any;

  getOrganizersStatistics(id: number) {
    return this.http.get<OrganizerStatistics>(`${this.apiUrl}/api/organizers/statistics/${id}`);
  }

  addOrganizer(data: any) {
    return this.http.post<Organizer>(`${this.apiUrl}/api/organizers`, data);
  }

  updateOrganizer(id: number,data: Organizer) {
    return this.http.put<Organizer>(`${this.apiUrl}/api/organizers/${id}`, data);
  }

  getOrganizer(id: number) {
    return this.http.get<Organizer>(`${this.apiUrl}/api/organizers/entities/${id}`);
  }
  searchOrganizerByIdWithTerm(organizerid: number, term: string) {
    return this.http.get<Organizer[]>(`${this.apiUrl}/api/Organizers/search/${organizerid}?term=${term}`);
  }
  mergeOrganizers(rootId: number, listOfIds: number[]): Observable<any> {
    const url = `${this.apiUrl}/api/Organizers/merge`;
    const body = { rootId, listOfIds };
    return this.http.post(url, body, { responseType: "text" });
  }
  getOrganizernew() {
    return this.http.get<Organizer[]>(`${this.apiUrl}/api/organizers`);
  }
  deleteOrganizer(id: number) {
    return this.http.delete(`${this.apiUrl}/api/organizers/${id}`, { responseType: "text" });
  }
}
