import { Transfer } from "../models/transfer";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../customers/models/customer";
import { TransfersService } from "../services/transfers.service.service";

@Component({
  selector: "app-transfers.details",
  templateUrl: "./transfers.details.component.html",
})
export class TransfersDetailsComponent implements OnInit {
  isShowingSidebar = false;
  protected id!: number;
  protected transfer!: Transfer;
  protected passangers: Customer[] = [];
  protected transferPlanIds: number[] = [];
  public entityname = "transfers";
  public returnId!: number;
  tabs: Array<{ id: string; label: string }> = [];
  activeTab?: string = "departure";
  constructor(private transferService: TransfersService, private route: ActivatedRoute, public translate: TranslateService) {}
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchTransferData();
      }
    });
  }
  onTabChange(tab: string) {
    this.activeTab = tab;
  }
  fetchTransferData() {
    this.transferService.getTransferData(this.id).subscribe((response) => {
      this.transfer = response;
      if (this.transfer?.returnTransfer?.id) {
        this.returnId = this.transfer.returnTransfer.id;
      }
      this.transferPlanIds = this.transfer.transferPlans.map((plan) => plan.id);
      this.initializeTabs();
    });
  }
  initializeTabs() {
    const departureDescription = this.transfer.departure.description + " - " + this.transfer.destination.description;
    const destinationDescription = this.transfer.destination.description + " - " + this.transfer.departure.description;
    this.tabs = [
      { id: "departure", label: departureDescription },
      { id: "destination", label: destinationDescription },
    ];
  }
}
