import { IconMenuBusComponent } from "../icon/menu/icon-menu-bus";
import { IconMenuGroupTravelComponent } from "../icon/menu/icon-menu-group-travel";
import { IconMenuOtherServicesComponent } from "../icon/menu/icon-menu-other-services";
import { IconMenuPlaneComponent } from "../icon/menu/icon-menu-plane";
import { IconMenuTransferComponent } from "../icon/menu/icon-menu-transfer";
import { IconMenuTravelComponent } from "../icon/menu/icon-menu-travel";
export class RouterLinkHelper {
  static getRouterLink(value: any): string | undefined {
    switch (value.entity_name) {
      case "Ticket":
        return `/dashboard/tickets/${value.type}/${value.entityId}`;
      case "Travel":
        return `/dashboard/travels/${value.entityId}`;
      case "GroupTravel":
        return `/dashboard/group-travels/${value.entityId}`;
      case "Transfer":
        return `/dashboard/transfers/${value.entityId}`;
      case "PlanService":
        return `/dashboard/other-services/${value.entityId}`;
      default:
        return `/dashboard/sales`;
    }
  }
  static getIconAndTooltip(entityName: string, entityType?: string): any | null {
    switch (entityName) {
      case "Ticket":
        if (entityType === "flight") {
          return IconMenuPlaneComponent;
        } else if (entityType === "bus") {
          return IconMenuBusComponent;
        }
        return null;
      case "Travel":
        return IconMenuTravelComponent;
      case "GroupTravel":
        return IconMenuGroupTravelComponent;
      case "Transfer":
        return IconMenuTransferComponent;
      case "PlanService":
        return IconMenuOtherServicesComponent;
      default:
        return null;
    }
  }
  
}