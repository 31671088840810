<div class="fixed left-0 top-0 z-30 w-full shadow-sm print:hidden" [ngClass]="{ 'dark text-white-dark': (semidark$ | async)  && (menu$ | async) === 'horizontal'}">
  <div class="relative flex w-full items-center bg-white px-5 py-2.5 dark:bg-[#0e1726]">
    <div class="horizontal-logo flex items-center justify-between lg:hidden ltr:mr-2 rtl:ml-2">
      <a routerLink="/dashboard/sales" class="main-logo flex shrink-0 items-center">
        <img class="ml-[5px] h-auto w-12 flex-none" src="/assets/images/logo.svg" alt="" />
        <img class="ml-[5px] h-10" src="/assets/images/logo-text.svg" alt="" />
      </a>

      <a
        href="javascript:;"
        class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary lg:hidden ltr:ml-2 rtl:mr-2"
        (click)="toggleSidebar()"
      >
        <icon-menu class="h-5 w-5" />
      </a>
    </div>
    <div class="flex items-center space-x-1.5 dark:text-[#d0d2d6] sm:flex-1 lg:space-x-2 ltr:ml-auto ltr:sm:ml-0 rtl:mr-auto rtl:space-x-reverse sm:rtl:mr-0">
      <div class="sm:ltr:mr-auto sm:rtl:ml-auto"></div>

      <div class="shrink-0">
        <div class="hidden md:block ltr:mr-2 rtl:ml-2">
          <ul class="flex items-center space-x-2 dark:text-[#d0d2d6] rtl:space-x-reverse">
            <li *appHasPackageRight="['calendarAccounting']">
              <button (click)="navigateToCalendar()" class="block rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60">
                <icon-calendar />
              </button>
            </li>
            <li [ngxTippy]="('higher_tier' | translate)" *appHasPackageRight="['calendarAccounting',true]">
              <button class="block cursor-not-allowed rounded-full bg-white-light/40 p-2 dark:bg-dark/40">
                <icon-calendar class="text-gray-500" />
              </button>
            </li>

            <li *appHasPackageRight="['calendarAccounting']">
              <ng-select [items]="cars" bindLabel="name" bindValue="id" [clearable]="false" [searchable]="false" [(ngModel)]="pickerType"></ng-select>
            </li>

            <li *appHasPackageRight="['calendarAccounting']">
              <div class="hidden md:block">
                <app-datepicker [pickerType]="pickerType"></app-datepicker>
              </div>
            </li>
            <li>
              <app-etourist-checker></app-etourist-checker>
            </li>
          </ul>
        </div>
      </div>

			<div class="dropdown shrink-0" *ngIf="user">
        <div hlMenu class="!block">
          <button type="button" hlMenuButton class="group relative block">
            <img class="h-9 w-9 rounded-full object-cover saturate-50 group-hover:saturate-100" [src]="user.imageUrl ?? '/assets/images/auth/user-icon.png'" alt="" />
          </button>

          <ul *hlMenuItems @slideInFromRight class="top-11 flex  w-[35vh] h-[93vh] flex-col !py-0 font-semibold text-dark dark:text-white-dark dark:text-white-light/90 ltr:right-0 rtl:left-0">
            <li>
              <div class="flex items-center px-4 py-4">
                <div class="flex-none">
                  <img class="h-10 w-10 rounded-md object-cover" [src]="user.imageUrl" alt="" />
								</div>

                <div class="truncate">
                  <h4 class="text-base">
                    <a class="text-black hover:text-primary dark:text-dark-light/60 dark:hover:text-white" routerLink="/dashboard/user-profile"> {{user.name}} {{user.surname}} </a>
                  </h4>
							
                </div>
								<button type="button" hlMenuButton class="group relative block ml-14 focus:outline-none active:outline-none border-none">
									<icon-x class="text-gray-500" />
								</button>
              </div>  
            </li>
					
						<li class="border-t border-white-light dark:border-white-light/10">
              <a routerLink="/dashboard/settings/profile" *hlMenuItem="let menuItem" class="dark:hover:text-white">
                <icon-settings class="h-7 w-7 shrink-0 ltr:mr-2 rtl:ml-2" />
                {{ 'settings_label' | translate}}
              </a>
            </li>
            <li class="border-t border-white-light dark:border-white-light/10">
              <div *hlMenuItem="let menuItem" class="flex items-center space-x-4 px-4 h-10  dark:text-[#d0d2d6] dark:hover:text-white ltr:mr-2 rtl:ml-2">{{"choose_a_theme"|translate}}:</div>
              <div class="flex items-center space-x-6 truncate dark:text-[#d0d2d6] ltr:mr-2 ltr:pl-4 rtl:ml-2 rtl:pr-4 custom-padding-bottom">
                <button
                  type="button"
									class="w-full hover:text-primary p-2 focus:outline-none active:outline-none border-none flex justify-center items-center"
									[ngClass]="{'bg-primary/10 text-primary': (theme$ | async) === 'light', 'dark:bg-dark/40': (theme$ | async) !== 'light'}"
                  (click)="toggleTheme('light')"
                >
                  <icon-sun class="w-6 h-6 " />
                </button>
                <button
                  type="button"
									class="w-full hover:text-primary p-2 focus:outline-none active:outline-none border-none flex justify-center items-center"
									[ngClass]="{'bg-primary/10 text-primary': (theme$ | async) === 'dark', 'dark:bg-dark/40': (theme$ | async) !== 'dark'}"
                  (click)="toggleTheme('dark')"
                >
                  <icon-moon class="w-6 h-6"/>
                </button>
                <button
                  type="button"
									class="w-full hover:text-primary p-2 focus:outline-none active:outline-none border-none flex justify-center items-center"
									[ngClass]="{'bg-primary/10 text-primary': (theme$ | async) === 'system', 'dark:bg-dark/40': (theme$ | async) !== 'system'}"
                  (click)="toggleTheme('system')"
                >
                  <icon-laptop class="w-6 h-6" />
                </button>
              </div>
            </li>

            <li>
              <div *hlMenuItem="let menuItem" class="flex items-center space-x-4 px-4 h-10 dark:text-[#d0d2d6] dark:hover:text-white ltr:mr-2 rtl:ml-2">{{"choose_a_language"|translate}}:</div>
              <div class="flex items-center space-x-6 truncate pb-2 dark:text-[#d0d2d6] ltr:mr-2 ltr:pl-4 rtl:ml-2 rtl:pr-4">
                <ng-container *ngFor="let item of (languageList$ | async)">
                  <button
									*hlMenuItem="let menuItem"
                    type="button"
										class="w-full hover:text-primary flex items-center p-2 focus:outline-none active:outline-none border-none"
								     [ngClass]="{
											'bg-primary/10 text-primary': translate.currentLang === item.code,
										}"
                    (click)="changeLanguage(item)"
                  >
                    <img [src]="'/assets/images/flags/' + item.code.toUpperCase() + '.svg'" alt="flag" class="h-6 w-6  rounded-full object-cover" />
										<span class="ltr:ml-3 rtl:mr-3">{{ item.name }}</span>
									</button>
                </ng-container>
              </div>
           
            </li>

						<li class="mt-auto">
							<a routerLink="/auth/lock" *hlMenuItem="let menuItem" class="dark:hover:text-white">
								<icon-lock-dots class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
								{{"screen_lock" | translate}}
							</a>
							<a routerLink="/auth/login" *hlMenuItem="let menuItem" class="!py-3 text-danger  border-t border-white-light dark:border-white-light/10">
								<icon-logout class="h-4.5 w-4.5 shrink-0 rotate-90 ltr:mr-2 rtl:ml-2" />
								
								{{'sign_out' | translate}}
							</a>
						</li>
          </ul>
        </div>
      </div> 
    </div>
  </div>
</div>
