import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, forwardRef, Input, ViewChild } from "@angular/core";
import { UtilService } from "src/app/shared/services/util.service";
import { EntityPicklistType } from "src/app/shared/enums/picklist-types";
import { AutocompleteRemoteComponent } from "src/app/shared/components/autocomplete-remote/autocomplete-remote.component";

@Component({
  selector: "app-destination-autocomplete",
  templateUrl: "./destination-autocomplete.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestinationAutocompleteComponent),
      multi: true,
    },
  ],
})
export class DestinationAutocompleteComponent implements ControlValueAccessor {
  @Input() form!: FormGroup;
  EntityPicklistType = EntityPicklistType;
  @Input() formControlName!: string;
  isLoading = false;
  mockFormName: FormGroup;
  countrySelectorForm!: FormGroup;
  value: any;
  isDisabled: boolean = false;

  @ViewChild("initialSearch") initialSearch!: AutocompleteRemoteComponent;

  constructor(public utilService: UtilService) {
    this.mockFormName = new FormGroup({
      [this.formControlName]: new FormControl(""),
    });

    this.countrySelectorForm = new FormGroup({
      ["country"]: new FormControl(""),
    });
  }

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
    if (this.form && this.formControlName) {
      this.form.controls[this.formControlName].setValue(value, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  // Update form control value and propagate changes
  updateValue(newValue: any) {
    this.value = newValue;
    this.onChange(newValue);
    this.onTouched();
  }

  focusField() {
    this.initialSearch.focusField();
  }

  save() {
    this.isLoading = true;
    const country = this.countrySelectorForm.controls["country"].value;
    const countryId = country?.id;
    if (countryId !== undefined) {
      this.utilService.selectCountry(countryId);
      this.isLoading = false;
    }
  }
}
