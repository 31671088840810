import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { Ticket } from "../models/ticket";
import { FormBuilder } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Customer } from "../../customers/models/customer";
import { TicketsService } from "../services/tickets.service";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { toggleAnimation } from "src/app/shared/animations";

@Component({
  selector: "app-ticket-details",
  templateUrl: "./ticket.details.component.html",
  animations: [toggleAnimation],
})
export class TicketDetailsComponent implements OnInit, OnDestroy {
  isShowingSidebar = false;
  protected id!: number;
  public planId!: number;
  protected ticket!: Ticket;
  public entityname = "Tickets";
  protected passangers: Customer[] = [];
  private routerSubscriber!: Subscription;
  public reservationNumber!: string;
  @Input() defaultTicket: Ticket = {} as Ticket;
  @Output() submitTicket: EventEmitter<Ticket> = new EventEmitter<Ticket>();

  ngOnInit(): void {
    this.routerSubscriber = this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchTicketData();
      this.fetchPassangers();
    });
  }
  ngOnDestroy() {
    this.routerSubscriber.unsubscribe();
  }
  constructor(private ticketService: TicketsService, public store: Store<AppState>, public translate: TranslateService, public fb: FormBuilder, private route: ActivatedRoute) {}
  fetchTicketData() {
    const getTicketsSub = this.ticketService.getTicketData(this.id).subscribe((response) => {
      getTicketsSub.unsubscribe();
      this.ticket = response;
      this.planId = response.planId;
    });
  }
  fetchPassangers() {
    const fetchPassanger = this.ticketService.getPassangersData(this.id).subscribe((response: any) => {
      fetchPassanger.unsubscribe();
      this.passangers = response;  
      this.passangers.forEach(passanger => {
        this.reservationNumber = passanger.reservationNumber || "";
      });
    });
  }
}
