import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { OtherServicesService } from "../services/other-services.service";
import { OtherServiceDetail } from "../models/other-services-detail";

@Component({
  selector: "app-other-services.details",
  templateUrl: "./other-services.details.component.html",
})
export class OtherServicesDetailsComponent implements OnInit {
  isShowingSidebar = false;
  protected id!: number;
  protected otherService!: OtherServiceDetail;
  public planid!: number;
  public entityname = "planservice";

  constructor(private otherserviceService: OtherServicesService, private route: ActivatedRoute, public translate: TranslateService) {}
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchOtherServiceData();
      }
    });
  }

  fetchOtherServiceData() {
    this.otherserviceService.getOtherServiceDetails(this.id).subscribe((response) => {
      this.otherService = response;
      this.planid = this.otherService.planId;
    });
  }
}
