import { from, map, Observable, of, Subscription, tap } from "rxjs";
import { Store } from "@ngrx/store";
import { UserWorker } from "../models/worker";
import { User } from "src/app/auth/model/user";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { WorkersService } from "./../services/workers.service";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { WorkerFormComponent } from "../worker.form.component/worker.form.component";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";
@Component({
  selector: "app-workers-table",
  templateUrl: "./workers.table.component.html",
  animations: [toggleAnimation],
})
export class WorkersTableComponent extends BaseTableComponent<User> implements OnInit, OnDestroy {
  @ViewChild("deleteConfirmationModal")
  deleteConfirmationModal!: ModalComponent;
  @ViewChild("workerForm") workerForm!: WorkerFormComponent;
  @ViewChild("createWorkerModal") createWorkerModal!: ModalComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;

  public isEdit = false;
  public userIdForDelete!: number;
  public userId!: number | undefined;
  public worker!: UserWorker[];
  public workers: Observable<UserWorker[]> = of([]);
  private routeSubscription!: Subscription;

  constructor(
    private route: ActivatedRoute,
    private modalService: ModalService,
    private workersService: WorkersService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    public override translate: TranslateService,
    private positioningService: PositioningService,
    public override userService: UserService
  ) {
    super(store, tableService, `users/paginated`,userService, translate);
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.apiUrl = `users/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscription.unsubscribe();
  }

  closeModal() {
    this.createWorkerModal.close();
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("worker_info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("worker_info.name"), hide: false } as DatatableColumn,
      { field: "email", title: this.translate.instant("worker_info.email"), hide: false } as DatatableColumn,
      { field: "role", title: this.translate.instant("worker_info.role"), hide: false } as DatatableColumn,
      { field: "officeBranch.name", title: this.translate.instant("worker_info.office_branch"), hide: false } as DatatableColumn,
      { field: "createdAt", title: this.translate.instant("worker_info.created_at"), hide: false } as DatatableColumn,
      { field: "actions", title: this.translate.instant("worker_info.actions"), hide: false } as DatatableColumn,
    ];
  }

  openModalForCreating(): void {
    from(this.modalService.openWorkerModal())
      .pipe(
        tap((result) => {
          if (result) {
            this.updatePaginationProps.next(this.getPaginationParams());
            this.getColumns();
            showMessage(PopupType.Success, this.translate.instant("worker_data.worker_added_successfully"));
          }
        })
      )
      .subscribe();
  }

  openModalForEditing(id: number): void {
    from(this.modalService.openWorkerModal(id))
      .pipe(
        tap((result) => {
          if (result) {
            this.updatePaginationProps.next(this.getPaginationParams());
            this.getColumns();
            showMessage(PopupType.Success, this.translate.instant("worker_data.worker_changed_successfully"));
          }
        })
      )
      .subscribe();
  }

  loadWorker() {
    this.workers = this.workersService.getWorkers().pipe(map((workers: UserWorker[]) => workers.filter((worker) => worker.id !== this.userId)));
  }
  async deleteWorker(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.workersService.deleteWorker(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
          this.loadWorker();
          showMessage(PopupType.Success, this.translate.instant("popup.worker_deleted_successfully"));
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
}
