import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TaskService {
  private allTasksLengthSubject = new BehaviorSubject<number>(0);
  allTasksLength$ = this.allTasksLengthSubject.asObservable();

  updateTasksLength(length: number): void {
    this.allTasksLengthSubject.next(length);
  }
}
