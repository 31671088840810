<div class="space-y-8">
  <div class="panel pb-0">
    <div class="mb-5 flex flex-col gap-5 md:flex-row md:items-center">
      <div class="relative">
        <input [(ngModel)]="search" (keydown.enter)="onSearchEnter()" type="text" class="form-input pr-10" placeholder="{{ 'search_placeholder' | translate }}" [appLimitCharacterInput]="60" />
        <span class="absolute inset-y-0 right-0 flex items-start pr-3 pt-2">
          <icon-search class="text-gray-500"></icon-search>
        </span>
      </div>

      <div class="flex items-center gap-5 ltr:ml-auto rtl:mr-auto">
        <div hlMenu class="dropdown">
          <button hlMenuButton type="button" class="flex items-center rounded-md border border-[#e0e6ed] px-4 py-2 text-sm font-semibold dark:border-[#253b5c] dark:bg-[#1b2e4b] dark:text-white-dark">
            <span class="ltr:mr-1 rtl:ml-1">{{ "table_columns" | translate }}</span>
            <icon-caret-down class="h-5 w-5" />
          </button>
          <ul *hlMenuItems @toggleAnimation class="z-20 w-max ltr:left-0 rtl:right-0">
            <ng-container *ngFor="let col of cols; index as i">
              <li>
                <div class="flex items-center px-4 py-1">
                  <label [for]="'chk-' + i" class="mb-0 cursor-pointer">
                    <input type="checkbox" [id]="'chk-' + i" class="form-checkbox" [(ngModel)]="!col.hide" (change)="updateColumn(col)" />
                    <span class="ltr:ml-2 rtl:mr-2">{{ col.title }}</span>
                  </label>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
        <app-export-table
          [exportApi]="exportApiUrl"
          [filterType]="filterType"
          [sortColumn]="sortColumn"
          [sortDirection]="sortDirection"
          [search]="search"
          [columns]="exportCols"
          [filename]="'Customers Table Export'"
        ></app-export-table>
      </div>
    </div>
    <app-global-table-filters [filterType]="filterType" [total]="totalRecords" [withDept]="withDeptPlans" [withoutDept]="withoutDeptPlans" (selectFilter)="onSelectionChange($event)"></app-global-table-filters>

    <div class="datatable">
      <ng-datatable
        [rows]="items"
        [stickyHeader]="true"
        [height]="'670px'"
        [columns]="cols"
        [sortable]="true"
        [loading]="loading"
        [isServerMode]="true"
        [page]="pageNumber"
        [pageSize]="pageSize"
        [totalRows]="totalRows"
        (changeServer)="changeServer($event)"
        skin="whitespace-nowrap table-hover"
        [paginationInfo]="'paginationInfoLabel' | translate"
        [noDataContent]="'empty_tables' | translate"
        [showNumbersCount]="3"
      >
        <ng-template slot="actions" let-value="data">
          <div class="dropdown inline-flex">
            <button
              type="button"
              [ngxTippy]="tooltipTemplate"
              [tippyContext]="{ data: value }"
              [tippyProps]="{ placement: 'right', interactive: true }"
              [routerLink]="'/dashboard/tickets/'+value.type+'/' + value.id"
              class="btn btn-outline-secondary rounded-circle"
              (mouseenter)="onMouseEnter(value.id)"
            >
              <div class="flex h-6 w-6 items-center gap-2">
                <ng-container *ngIf="value.type === 'flight'; else busIcon">
                  <icon-menu-plane></icon-menu-plane>
                </ng-container>
                <ng-template #busIcon>
                  <icon-menu-bus></icon-menu-bus>
                </ng-template>
              </div>
            </button>
          </div>
        </ng-template>

        <ng-template slot="getStatus" let-value="data">
          <span class="badge bg-success" [ngClass]="{ 'bg-danger': !value.status, 'bg-success': value.status }">
            <span *ngIf="value.status; else unpaidTemplate">{{'paid' | translate }}</span>
            <ng-template #unpaidTemplate>
              <span>{{ 'not_paid'| translate}}</span>
            </ng-template>
          </span>
        </ng-template>
        <ng-template slot="departureDate" let-value="data"> {{ value.departureDate | date: 'dd.MM.yyyy' }} </ng-template>
        <ng-template slot="arrivalDate" let-value="data"> {{ value.arrivalDate | date: 'dd.MM.yyyy' }} </ng-template>
        <ng-template slot="reservationDate" let-value="data"> {{ value.reservationDate | date: 'dd.MM.yyyy' }} </ng-template>
        <ng-template slot="createdAt" let-value="data"> {{ value.createdAt | date: 'dd.MM.yyyy'}} </ng-template>
        <ng-template slot="numberOfPassangers" let-value="data"> {{ value.numberOfPassangers}} </ng-template>
        <ng-template slot="destinationId" let-value="data"> {{ value.destination}} </ng-template>
        <ng-template slot="departureId" let-value="data"> {{ value.departure}} </ng-template>
        <ng-template slot="fullName" let-value="data">
          <span>{{ value.custemerFullName }}</span>
        </ng-template>
        <ng-template slot="reservationNumber" let-value="data">
          <span>{{ value.reservationNumber }}</span>
        </ng-template>
        <ng-template slot="checked" let-value="data">
          <span class="badge my-1 flex items-center justify-between rounded-full p-0 text-base ltr:pr-4 rtl:pl-4" [ngClass]="{ 'bg-danger': !value.checked, 'bg-success': value.checked }">
            <span *ngIf="value.checked; else uncheckedTemplate" class="ml-4">{{ 'ticket_checked' | translate }}</span>
            <ng-template #uncheckedTemplate>
              <span class="ml-4">{{ 'ticket_unchecked' | translate }}</span>
            </ng-template>
            <div class="h-10 rounded-full"></div>
            <span class="cursor-pointer p-2 hover:opacity-90" (click)="checkUncheck(value.checked)">x</span>
          </span>
        </ng-template>
        <ng-template slot="details" let-value="data">
          <div class="flex w-max items-center justify-between">
            <div hlMenu class="dropdown">
              <a hlMenuButton href="javascript:;">
                <icon-horizontal-dots class="rotate-90 opacity-70" />
              </a>
              <div #dropdownPlaceholder *hlMenuItems>
                <app-message-portal #messagePortalComponent @toggleAnimation (portalAttached)="onPortalAttached()">
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/tickets/'+value.type+'/' + value.id">
                      <icon-info-circle class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{'details_quick_label' | translate}}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" [routerLink]="'/dashboard/tickets/'+value.type+'/edit/' + value.id">
                      <icon-pencil-paper class="h-4.5 w-4.5 shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "edit_quick_label" | translate }}
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" *hlMenuItem="let menuItem" (click)="deleteTicket(value.id)">
                      <icon-trash-lines class="shrink-0 ltr:mr-2 rtl:ml-2" />
                      {{ "delete_quick_label" | translate }}
                    </a>
                  </li>
                </app-message-portal>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-datatable>
    </div>
  </div>
  <ng-template #tooltipTemplate let-name let-data="data">
    <ng-container *ngIf="currentId === data.id">
      <app-ticket-quick-view [id]="data.id"></app-ticket-quick-view>
    </ng-container>
  </ng-template>
</div>