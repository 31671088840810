import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "../store/index.selector";
import { AppService } from "../service/app.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { toggleMainLoader } from "../store/layout/layout.actions";
import { selectIsShowMainLoader, selectLayout, selectMenu, selectRtlClass, selectSidebar } from "../store/layout/layout.selector";

@Component({
  selector: "app-root",
  templateUrl: "./auth-layout.html",
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  headerClass = "";
  showTopButton = false;
  menu$!: Observable<string>;
  layout$!: Observable<string>;
  rtlClass$!: Observable<string>;
  sidebar$!: Observable<boolean>;
  isShowMainLoader$!: Observable<boolean>;
  constructor(public storeData: Store<AppState>, private service: AppService) {}
  ngOnInit() {
    this.toggleLoader();
    window.addEventListener("scroll", () => {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.showTopButton = true;
      } else {
        this.showTopButton = false;
      }
    });
    this.menu$ = this.storeData.select(selectMenu);
    this.layout$  = this.storeData.select(selectLayout);
    this.rtlClass$ = this.storeData.select(selectRtlClass);
    this.sidebar$= this.storeData.select(selectSidebar);
    this.isShowMainLoader$ = this.storeData.select(selectIsShowMainLoader);
  }

  toggleLoader() {
    this.storeData.dispatch(toggleMainLoader({ isShow: true }));
    setTimeout(() => {
      this.storeData.dispatch(toggleMainLoader({ isShow: false }));
    }, 500);
  }
  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    window.removeEventListener("scroll", () => {});
  }

  public goToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
}
