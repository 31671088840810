import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { Office } from './../models/office-branch';
import { AppState } from "src/app/store/index.selector";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable, of, Subscription } from "rxjs";
import { toggleAnimation } from "src/app/shared/animations";
import { PopupType } from "src/app/shared/enums/popup-types";
import { MessagePortalComponent } from "src/app/portal/portal";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { OfficeBranchService } from "../services/office-branch.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { OfficeBranchFormComponent } from "../office-branch.form.component/office-branch.form.component";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { UserService } from "../../user-profile/service/user.service";

@Component({
  selector: "app-office-branch-table",
  templateUrl: "./office-branch.table.component.html",
  animations: [toggleAnimation],
})
export class OfficeBranchTableComponent extends BaseTableComponent<Office> implements OnInit, OnDestroy {
  @ViewChild("addOfficeLocationModal") addOfficeLocationModal!: ModalComponent;
  @ViewChild("createOfficeModal") createOfficeModal!: ModalComponent;
  @ViewChild("officeBranchForm") officeBranchForm!: OfficeBranchFormComponent;
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  public offices: Office[] = [];
  public isEdit = false;
  public branchId!: number | undefined;
  public userIdForDelete!: number;
  public office!: any[];
  private routeSubscription!: Subscription;
  public officeBranch: Observable<Office[]> = of([]);

  constructor(
    private route: ActivatedRoute,
    public override store: Store<AppState>,
    public override translate: TranslateService,
    public override tableService: TableService,
    private modalService: ModalService,
    private positioningService: PositioningService,
    private officeBranchService: OfficeBranchService,
    public override userService: UserService
  ) {
    super(store, tableService, `OfficeBranches/paginated`, userService, translate);
  }

  override ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.apiUrl = `OfficeBranches/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscription.unsubscribe();
  }

  override getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("office-branch-info.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "name", title: this.translate.instant("office-branch-info.name"), hide: false } as DatatableColumn,
      { field: "bankAccount", title: this.translate.instant("office-branch-info.bankAccount"), hide: true } as DatatableColumn,
      { field: "city", title: this.translate.instant("office-branch-info.city"), hide: true } as DatatableColumn,
      { field: "email", title: this.translate.instant("office-branch-info.email"), hide: false } as DatatableColumn,
      { field: "guid", title: this.translate.instant("office-branch-info.guid"), hide: false } as DatatableColumn,
      { field: "logoURL", title: this.translate.instant("office-branch-info.logoURL"), hide: true } as DatatableColumn,
      { field: "municipality", title: this.translate.instant("office-branch-info.municipality"), hide: true } as DatatableColumn,
      { field: "phoneNumber", title: this.translate.instant("office-branch-info.phoneNumber"), hide: false } as DatatableColumn,
      { field: "pib", title: this.translate.instant("office-branch-info.pib"), hide: false } as DatatableColumn,
      { field: "siteURL", title: this.translate.instant("office-branch-info.siteURL"), hide: false } as DatatableColumn,
      { field: "street", title: this.translate.instant("office-branch-info.street"), hide: true } as DatatableColumn,
      { field: "actions", title: this.translate.instant("office-branch-info.actions"), hide: false } as DatatableColumn,
    ];
  }

  openModalAddingOfficeLocation(id: number) {
    this.branchId = id;
    this.addOfficeLocationModal.open();
  }

  closeLocationsModal() {
    this.branchId = undefined;
    this.addOfficeLocationModal.close();
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  closeModal() {
    this.createOfficeModal.close();
    this.updatePaginationProps.next(this.getPaginationParams());
  }

  openModalForCreating() {
    this.branchId = undefined;
    this.isEdit = false;
    this.createOfficeModal.open();
  }

  openModalForEditing(id: number) {
    this.branchId = id;
    this.isEdit = true;
    this.createOfficeModal.open();
  }
  loadOfficeBranch(){
    this.officeBranch = this.officeBranchService.getOffices().pipe(
      map((officeBranches: Office[]) => officeBranches.filter((officeBranch) => officeBranch.id !== this.branchId))
    );
  }

  async deleteOfficeBranch(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.officeBranchService.deleteOffice(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
          this.loadOfficeBranch();
          showMessage(PopupType.Success, this.translate.instant("popup.office_branch_deleted_successfully"));

        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }
}
