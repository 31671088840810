import * as moment from "moment";
import { Store } from "@ngrx/store";
import { Component, OnInit } from "@angular/core";
import { DailySummary } from "../models/dailysummary";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { distinctUntilChanged, Observable, Subscription } from "rxjs";
import { DailyWorkerSummary } from "../models/dailyworkersummary";
import { setFrom } from "src/app/store/datepicker/datepicker.actions";
import { DailySummaryService } from "../service/daily-summary.service";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";
@Component({
  selector: "app-daily-worker-summary",
  templateUrl: "./daily-worker-summary.component.html",
  animations: [toggleAnimation],
})
export class DailyWorkerSummaryComponent implements OnInit {
  from: Date = new Date();
  pageSize = 10;
  totalRows = 100;
  cols: DatatableColumn[] = [];
  items: DailyWorkerSummary[] = [];
  tabs: Array<Tab> = [];
  activeTab!: Tab;
  filteredItems: DailySummary[] = [];
  private langChangeSubscription!: Subscription;

  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;

  constructor(private dailySummaryService: DailySummaryService, private translate: TranslateService, public store: Store<AppState>) {}

  ngOnInit() {
    this.getColumns();

    this.store.dispatch(setFrom({ from: this.from }));
    this.datepick$ = this.store.select(selectFromTo);
    this.datepick$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          const isFromSame = moment(prev.from).isSame(moment(curr.from), "day");

          const isToSame = prev.to && curr.to ? moment(prev.to).isSame(moment(curr.to), "day") : prev.to === curr.to;

          return isFromSame && isToSame;
        })
      )
      .subscribe((datepick) => {
        if (datepick) {
          this.from = datepick.from;

          this.updateAndLoadData();
        }
      });
    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.getColumns();
    });
  }

  getColumns(): void {
    this.cols = [
      { field: "id", title: this.translate.instant("daily_summary.id"), isUnique: true, hide: true } as DatatableColumn,
      { field: "agent", title: this.translate.instant("daily_summary.agent"), hide: false } as DatatableColumn,
      { field: "customer", title: this.translate.instant("daily_summary.customer"), hide: false } as DatatableColumn,
      { field: "timestamp", title: this.translate.instant("daily_summary.timestamp"), hide: false } as DatatableColumn,
      { field: "totalSum", title: this.translate.instant("daily_summary.totalSum"), hide: false } as DatatableColumn,
    ];
  }

  updateAndLoadData() {
    const formattedDate = this.from.toISOString();
    this.dailySummaryService.getDailyWorkerSummaryForDate(formattedDate).subscribe((data) => {
      this.items = data;
      this.tabs = this.items.map((item) => ({
        id: item.id,
        label: item.agent,
      }));
      this.changeFilteredDate(this.tabs[0]);
    });
  }

  changeFilteredDate(tab: Tab) {
    this.activeTab = tab;
    const selectedAgent = this.items.find((item) => item.id === tab.id);
    this.filteredItems = selectedAgent ? selectedAgent.dailySummaries : [];
  }

  onTabChange(tab: Tab) {
    this.changeFilteredDate(tab);
  }

  getSumOfSuma(): number {
    return this.filteredItems.reduce((totalSum, summary) => totalSum + summary.totalSum, 0);
  }

  updateColumn(col: DatatableColumn) {
    // Implement column update logic
  }
}

export interface Tab {
  id: number;
  label: string;
}
