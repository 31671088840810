import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Component, Input, OnInit } from "@angular/core";
import { TableService } from "../../services/table.service";
import { ExportService } from "../../services/export.service";
import { DateTimeFormatter } from "../../utils/datetime.formatter";
import { DatatableColumn } from "../base-table/base-table.component";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { toggleAnimation } from "../../animations";

@Component({
  selector: "app-export-table",
  templateUrl: "./export-table.component.html",
  animations: [toggleAnimation],
})
export class ExportTableComponent implements OnInit {
  @Input() search!: string;
  @Input() filename!: string;
  @Input() exportApi!: string;
  @Input() filterType!: string;
  @Input() sortColumn!: string;
  @Input() sortDirection!: string;
  @Input() rawData: { [key: string]: any }[] | undefined;
  @Input() columns!: DatatableColumn[];
  public jsonData: { [key: string]: any }[] | undefined;
  @Input() additionalInfo: any;

  public exportObservable: Observable<unknown[]> | undefined;
  public fullFilename = "";

  public from!: Date;
  public to!: Date | undefined;

  public items: any[] = [];
  public cols: { field: string }[] = [];

  datepick$: Observable<{ from: Date; to: Date | undefined }>;

  constructor(protected store: Store<AppState>, protected translate: TranslateService, protected tableService: TableService, protected exportService: ExportService) {
    this.datepick$ = this.store.select(selectFromTo);
  }

  private getFromToDates(): string {
    const fromDate = DateTimeFormatter.formatDateToLocalString(this.from);
    const toDate = this.to ? DateTimeFormatter.formatDateToLocalString(this.to) : "Danas";

    return `${fromDate} - ${toDate}`;
  }

  ngOnInit(): void {
    this.datepick$.subscribe((el) => {
      this.from = el.from;
      this.to = el.to;
      this.fullFilename = this.filename + " " + this.getFromToDates();
    });
  }

  exportTable(type: string, columns: DatatableColumn[], records: any[]): void {
    if (type === "print") {
      let rowhtml = "<p>" + this.fullFilename + "</p>";
      if (this.additionalInfo) {
        rowhtml += "<p>";
        rowhtml += this.additionalInfo;
        rowhtml += "</p>";
      }
      rowhtml += '<table style="width: 100%;" cellpadding="0" cellspacing="0"><thead><tr style="color: #515365; background: #eff5ff; -webkit-print-color-adjust: exact; print-color-adjust: exact;">';
      columns.forEach((d) => {
        rowhtml += "<th>" + this.translate.instant(d.title) + "</th>";
      });
      rowhtml += "</tr></thead><tbody>";
      records.forEach((item) => {
        rowhtml += "<tr>";
        columns.forEach((d) => {
          const val = item[d.field] ? item[d.field] : "";
          const isDateField = d.field === "startDate" || d.field === "endDate" || d.field === "dateOfSale";
          const isBrutoField = d.field === "bruto";
          const isTotalSumField = d.field === "totalSum";
          const formattedValue = isTotalSumField ? `${val} €` : val;
          rowhtml += `<td${isDateField ? " class='date-cell'" : ""}${isBrutoField ? " class='bruto-cell'" : ""}>` + formattedValue + "</td>";
        });
        rowhtml += "</tr>";
      });
      rowhtml +=
        "<style>body {font-family: Arial; color: #495057;} p {text-align: left; font-size: 14px; margin: 15px 0;} table {border-collapse: collapse; border-spacing: 0;} th, td {font-size: 12px; text-align: left; padding: 4px;} th {padding: 8px 4px;} tr:nth-child(2n-1) {background: #f7f7f7;}</style>";
      rowhtml += "<style>td.date-cell, td.bruto-cell {white-space: nowrap;}</style>";
      rowhtml += "</tbody></table>";

      const winPrint = window.open("", "", "left=0,top=0,width=1000,height=600,toolbar=0,scrollbars=0,status=0");
      winPrint?.document.write("<title>Print</title>" + rowhtml);
      winPrint?.document.close();
      winPrint?.focus();
      winPrint?.print();
    } else if (type === "excel") {
      this.jsonData = this.prepareObjectsForExcel(records, columns);
    }
  }

  prepareObjectsForExcel(records: any[], columns: DatatableColumn[]) {
    return records.map((r) => {
      const row: { [key: string]: any } = {};
      columns.forEach((c) => {
        const fieldName = this.translate.instant(c.title);
        row[fieldName.toString()] = r[c.field];
      });
      return row;
    });
  }

  exportTableNew(type: string): void {
    if (!this.rawData) {
      const exportSubscriber = this.exportService.getDataToExport(this.exportApi, this.filterType, this.from, this.to, this.sortColumn, this.sortDirection, this.search).subscribe((res) => {
        this.exportTable(type, this.columns, res.body || []);
        exportSubscriber.unsubscribe();
      });
    } else {
      this.exportTable(type, this.columns, this.rawData);
    }
  }

  updateJson() {
    this.jsonData = this.items.map((obj: any) => {
      const newObj: any = {};
      this.cols.forEach((col) => {
        newObj[col.field] = obj[col.field];
      });
      return newObj;
    });
  }

  destroyJson(event: any) {
    this.jsonData = undefined;
  }
}
