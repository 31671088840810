import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { AppState } from "src/app/store/index.selector";
import { toggleAnimation, toggleLayout, toggleMenu, toggleNavbar, toggleRTL, toggleSemidark, toggleTheme } from "src/app/store/layout/layout.actions";
import { selectAnimation, selectLayout, selectMenu, selectNavbar, selectRtlClass, selectSemidark, selectTheme } from "src/app/store/layout/layout.selector";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
})
export class LayoutComponent {
  showCustomizer = false;
  theme$!: Observable<string>;
  menu$!: Observable<string>;
  semidark$!: Observable<boolean>;
  layout$!: Observable<string>;
  rtlClass$!: Observable<string>;
  animation$!: Observable<string>;
  navbar$!: Observable<string>;
  constructor(public storeData: Store<AppState>, public router: Router) {
    this.theme$ = this.storeData.select(selectTheme);
    this.menu$ = this.storeData.select(selectMenu);
    this.semidark$ = this.storeData.select(selectSemidark);
    this.layout$ = this.storeData.select(selectLayout);
    this.rtlClass$ = this.storeData.select(selectRtlClass);
    this.animation$ = this.storeData.select(selectAnimation);
    this.navbar$ = this.storeData.select(selectNavbar);
  }

  reloadRoute() {
    window.location.reload();
    this.showCustomizer = true;
  }

  toggleRtl(direction: string) {
    this.storeData.dispatch(toggleRTL({ rtlClass: direction }));
  }

  toggleTheme(theme: string) {
    this.storeData.dispatch(toggleTheme({ theme: theme }));
  }

  toggleMenu(menu: string) {
    this.storeData.dispatch(toggleMenu({ menu: menu }));
  }

  toggleSemidark(semidark: boolean) {
    this.storeData.dispatch(toggleSemidark({ semidark: semidark }));
  }

  toggleLayout(layout: string) {
    this.storeData.dispatch(toggleLayout({ layout: layout }));
  }

  toggleNavbar(navbar: string) {
    this.storeData.dispatch(toggleNavbar({ navbar: navbar }));
  }

  toggleAnimation(animation: string) {
    this.storeData.dispatch(toggleAnimation({ animation: animation }));
  }
}
