import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { OtherServices, OtherServicesCreate } from "../models/otherservices";
import { BaseHttpService } from "src/app/service/base.http.service";
import { OtherServiceQuickView } from "../models/other-services-quick-view";
import { OtherServiceDetail } from "../models/other-services-detail";

@Injectable({
  providedIn: "root",
})
export class OtherServicesService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }
  createOtherService(body: OtherServicesCreate | undefined) {
    return this.http.post(`${this.apiUrl}/api/planservice`, body);
  }
  getOtherServiceData(id: number): Observable<OtherServices> {
    return this.http.get<OtherServices>(`${this.apiUrl}/api/planservice/${id}`);
  }
  getOtherServiceDetails(id: number): Observable<OtherServiceDetail> {
    return this.http.get<OtherServiceDetail>(`${this.apiUrl}/api/planservice/details/${id}`);
  }
  updateOtherService(id: number, data: OtherServices | undefined) {
    return this.http.put<OtherServices>(`${this.apiUrl}/api/planservice/${id}`, data);
  }
  deleteOtherService(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/planService/${id}`, { responseType: "text" });
  }
  getPlanServiceQuickView(id: number | undefined): Observable<OtherServiceQuickView> {
    return this.http.get<OtherServiceQuickView>(`${this.apiUrl}/api/planService/quick-view/${id}`);
  }
}
