import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TransferQuickView } from "../../transfers/models/transfer-quick-view";
import { TransfersService } from "../../transfers/services/transfers.service.service";

@Component({
  selector: "app-transfer-quick-view",
  templateUrl: "./transfer-quick-view.component.html",
})
export class TransferQuickViewComponent implements OnInit,OnDestroy {
  public data!: TransferQuickView;
  @Input() id!: number;
  private getSubscription!: Subscription;

  constructor(public translate: TranslateService, public transferService: TransfersService) {}
  ngOnInit(): void {
    if (this.id) {
      this.fetchTransferQuickView(this.id);
    }
  }
  fetchTransferQuickView(id: number) {
    this.getSubscription = this.transferService.getTransferQuickView(id).subscribe((transfer) => {
      this.data = transfer;
    });
  }
  ngOnDestroy(): void {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
  }
}
