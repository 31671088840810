import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TransfersService } from "../../transfers/services/transfers.service.service";
import { TransferPlanQuickView } from "../../transfers/models/transfer-plan-quick-view";

@Component({
  selector: "app-transfer-plan-quickview",
  templateUrl: "./transfer-plan-quickview.component.html",
})
export class TransferPlanQuickviewComponent implements OnInit, OnDestroy {
  public data!: TransferPlanQuickView;
  @Input() id!: number;
  private getSubscription!: Subscription;

  constructor(public translate: TranslateService, private transferSevice: TransfersService) {}
  ngOnInit(): void {
    if (this.id) {
      this.fetchTransferPlnaQuickView(this.id);
    }
  }
  fetchTransferPlnaQuickView(id: number) {
    this.getSubscription = this.transferSevice.getTransferPlanQuickView(id).subscribe((transferPlan) => {
      this.data = transferPlan;
    });
  }
  ngOnDestroy(): void {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
  }
}
