import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Transfer } from "../../transfers/models/transfer";

@Component({
  selector: "app-transfer-quick-view",
  templateUrl: "./transfer-quick-view.component.html",
})
export class TransferQuickViewComponent {
  @Input() data!: Transfer;

  constructor(public translate: TranslateService) {}
}
