import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {  GroupTravel } from "../../group-travels/models/group-travel";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";

@Component({
  selector: "app-group-travel-quick-view",
  templateUrl: "./group-travel-quick-view.component.html",
})
export class GroupTravelQuickViewComponent implements OnInit {
  @Input() data!: GroupTravel;
  @Input() id!: number;

  constructor(public translate: TranslateService, private groupTravelService: GroupTravelsService) {}

  numberOfPassangers() {
    if (this.data && this.data.groupTravelPassangers) {

      return this.data.groupTravelPassangers.length;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.id) {
      const getSubscription = this.groupTravelService.getGroupTravelData(this.id).subscribe((travelPlan) => {
        this.data = travelPlan;
        getSubscription.unsubscribe();
      });
    }
  }
}
