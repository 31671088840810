import { tap } from "rxjs";
import { Router } from "@angular/router";
import { Travel } from "../models/travel";
import { ActivatedRoute } from "@angular/router";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { slideDownUp } from "src/app/shared/animations";
import { FormBuilder, Validators } from "@angular/forms";
import { Customer } from "../../customers/models/customer";
import { PopupType } from "src/app/shared/enums/popup-types";
import { TravelsService } from "../services/travels.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { TravelDestination } from "../models/travel-destination";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-travel-details",
  templateUrl: "./travel.details.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
    slideDownUp,
  ],
})
export class TravelDetailsComponent implements OnInit {
  isShowingSidebar = false;
  pickerType = false;
  notFoundText = "";
  public id!: number;
  protected travel!: Travel;
  protected passangers!: Customer[];
  public planId!: number;
  public entityname = "travels";
  protected mainDestination!: TravelDestination | undefined;
  additionalDestinations: TravelDestination[] = [];
  private constractId!: number;
  @ViewChild("modal21") modal!: ModalComponent;

  constructor(private travelService: TravelsService, private route: ActivatedRoute, private router: Router, public translate: TranslateService, public fb: FormBuilder) {}
  form = this.fb.group({
    confirmationNumber: ["", Validators.required],
    insuranceNumber: ["", Validators.required],
    sumInRSD: ["", Validators.required],
  });

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params["id"]);
      if (this.id > 0) {
        this.fetchTravelData();
        this.fetchPassangers();
      }
    });
    this.translate.stream("travel_details.additional_destNotText").subscribe((translation: string) => {
      this.notFoundText = translation;
    });
  }
  fetchTravelData() {
    this.travelService.getTravelData(this.id).subscribe((response) => {
      this.travel = response;
      this.mainDestination = this.travel.travelDestinations.find((dest) => dest.isMainDestination);
      this.additionalDestinations = this.travel.travelDestinations.filter((dest) => !dest.isMainDestination);
      this.constractId = this.travel?.contractId;
      this.planId = this.travel.planId;
    });
  }

  fetchPassangers() {
    this.travelService.getPassangersData(this.id).subscribe((response) => {
      this.passangers = response;
    });
  }

  navigateToCreateVoucherPage(): void {
    this.travelService
      .getTravelMemorandumDriveId(this.id)
      .pipe(
        tap((response) => {
          if (response.driveId) {
            window.open(`https://docs.google.com/document/d/${response.driveId}`, "_blank");
          } else {
            this.router.navigate([`/dashboard/travels/${this.id}/voucher-list`]);
          }
        })
      )
      .subscribe();
  }
  navigateToContractPage(): void {
    this.travelService
      .getTravelContactDriveId(this.id)
      .pipe(
        tap((response) => {
          if (response.driveId) {
            window.open(`https://docs.google.com/document/d/${response.driveId}`, "_blank");
          } else {
            this.router.navigate([`/dashboard/travels/${this.id}/contract`]);
          }
        })
      )
      .subscribe();
  }

  getAccountData() {
    const { confirmationNumber, insuranceNumber, sumInRSD } = this.form.value;
    return {
      confirmationNumber,
      insuranceNumber,
      sumInRSD,
    };
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    this.createAccountData();
  }
  createAccountData() {
    this.travelService.createAccountData(this.id, this.getAccountData()).subscribe(
      (response) => {
        showMessage(PopupType.Success, this.translate.instant("popup.accounting_data_created_successfully"));
        this.modal.close();
      },
      (error) => {
        showMessage(PopupType.Danger, this.translate.instant("popup.error_while_creating_accounting_data"));
      }
    );
  }
}
