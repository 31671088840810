import { map, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { UserWorker } from "../models/worker";
import { Sale } from "../../sales/models/sale";
import { WorkerData } from "../models/workerdata";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GenericResponse } from "src/app/shared/models/generics";
import { Office } from "../../office-branch/models/office-branch";
import { BaseHttpService } from "src/app/service/base.http.service";

@Injectable({
  providedIn: "root",
})
export class WorkersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  createWorker(formData: UserWorker) {
    return this.http.post<Worker>(`${this.apiUrl}/api/users/registeragent`, formData);
  }

  getWorkerById(id: number) {
    const apiUrl = `${this.apiUrl}/api/users/${id}`;
    return this.http.get<any>(apiUrl);
  }

  updateWorker(id: number, updatedWorker: UserWorker) {
    const apiUrl = `${this.apiUrl}/api/users/${id}`;
    return this.http.put<Worker>(apiUrl, updatedWorker);
  }

  getWorkerSalesWithHeaders(
    userId: number,
    pageNumber: number,
    pageSize: number
    // filterType: string,
    // from: Date | undefined,
    // to: Date | undefined
  ): Observable<GenericResponse<Sale>> {
    const apiUrl = `${this.apiUrl}/api/Statistics/money/plans/paginated`;
    const params = new HttpParams().set("pageNumber", pageNumber.toString()).set("pageSize", pageSize.toString()).set("userId", userId.toString()).set("filterType", "None").set("from", "2020-12-31");

    return this.http.get<Sale[]>(apiUrl, { params, observe: "response" }).pipe(
      map((response) => {
        const customResponse: GenericResponse<Sale> = {
          body: response.body ?? ([] as Sale[]),
          headers: response.headers,
        };
        return customResponse;
      })
    );
  }
  getWorkerStats(id: number, from: string, to: string): Observable<WorkerData> {
    const apiUrl = `${this.apiUrl}/api/Statistics/worker/stats/${id}`;
    const params = new HttpParams().set("from", from).set("to", to);
    return this.http.get<WorkerData>(apiUrl, { params });
  }

  fetchOfficeBranches(): Observable<Office[]> {
    return this.http.get<Office[]>(`${this.apiUrl}/api/OfficeBranches`);
  }

  getWorkers(): Observable<UserWorker[]> {
    return this.http.get<UserWorker[]>(`${this.apiUrl}/api/users`);
  }
  deleteWorker(id: number) {
    return this.http.delete(`${this.apiUrl}/api/users/${id}`);
  }
}
