import * as moment from "moment";
import { Store } from "@ngrx/store";
import { OnDestroy } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { AppState } from "src/app/store/index.selector";
import { TranslateService } from "@ngx-translate/core";
import { StatisticsYearlyDebt } from "./models/yearlydebt";
import { StatisticsYear } from "./models/statisticsyear";
import { MonthlyDebtStatistics } from "./models/monthlydebt";
import { DailyDebtStatistics } from "./models/dailydebt";
import { StatisticsDaily } from "./models/statisticmoneydaily";
import { DailyEarningsStatistics } from "./models/dailyearnings";
import { OverallStatistics } from "./models/overallstatistics";
import { StatisticsYearlyEarnings } from "./models/yearlyearning";
import { MonthlyEarningsStatistics } from "./models/monthlyearnings";
import { StatisticsMonthly } from "./models/statisticmoneymonthly";
import { selectFromTo } from "src/app/store/datepicker/datetime.selector";
import { distinctUntilChanged, Observable, Subscription, tap } from "rxjs";
import { StatisticsMoneyService } from "./services/statisticsmoney.service";
import { YearlyPopularDestinations } from "./models/yearlypopulardestinations";
import { MostPopularCountriesByMonth } from "./models/mostpopularcountriesbymonth";
import { StatisticOrganizer, StatisticOrganizerProfit } from "./models/statisticorganizer";

@Component({
  selector: "app-statisticsmoney",
  templateUrl: "./statisticsmoney.component.html",
  providers: [DatePipe],
})
export class StatisticsMoneyComponent implements OnInit, OnDestroy {
  constructor(private statisticsService: StatisticsMoneyService, public translate: TranslateService, public store: Store<AppState>) {}

  protected statisticsData!: StatisticsYear;
  public datepick$!: Observable<{ from: Date; to: Date | undefined }>;
  public from!: Date;
  public to!: Date | undefined;
  numberOfOrganizers?: number = 10;
  numberOfDestinations?: number = 10;
  availableYears: number[] = [];
  availableMonths: { label: string; value: number }[] = [];
  selectedYear?: number = new Date().getFullYear();
  selectedMonth: number = new Date().getMonth() + 1;
  yearlypopulardestination!: YearlyPopularDestinations | undefined;
  public year?: number;
  statisticsOrganizer: StatisticOrganizer[] = [];
  statisticsOrganizerProfit: StatisticOrganizerProfit[] = [];
  activeTab?: string = "Overall";
  activeTabs?: string = "yearly";
  overallStatistics: OverallStatistics | undefined;
  protected yearlyearning!: StatisticsYearlyEarnings;
  protected montlyearning!: MonthlyEarningsStatistics;
  protected monthlydebt!: MonthlyDebtStatistics;
  protected yearlydebt!: StatisticsYearlyDebt;
  protected dailyearnings!: DailyEarningsStatistics;
  protected dailyeardebt!: DailyDebtStatistics;
  protected monthlystatistic!: StatisticsMonthly;
  protected dailystatistic!: StatisticsDaily;
  mostPopularCountriesByMonth: MostPopularCountriesByMonth | undefined;
  tabs: Array<{ id: string; label: string }> = [];
  tabsCountryDestination: Array<{ id: string; label: string }> = [];
  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.initializeTabs();
    this.initializeSelectors();
    this.initializeAllData();
  }

  initializeTabs(): void {
    this.tabs = [
      { id: "Overall", label: "tabs_statistics.overall" },
      { id: "Monthlystatistics", label: "tabs_statistics.monthlystatistics" },
      { id: "Dailystatistics", label: "tabs_statistics.dailystatistics" },
    ];
    this.tabsCountryDestination = [
      { id: "yearly", label: "tabsCountryDestination.yearly" },
      { id: "monthly", label: "tabsCountryDestination.monthly" },
    ];
  }

  protected initializeSelectors() {
    this.datepick$ = this.store.select(selectFromTo);
    this.datepick$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          const isFromSame = moment(prev.from).isSame(moment(curr.from), "day");
          const isToSame = prev.to && curr.to ? moment(prev.to).isSame(moment(curr.to), "day") : prev.to === curr.to;
          return isFromSame && isToSame;
        })
      )
      .subscribe((datepick) => {
        if (datepick) {
          this.from = datepick.from;
          this.to = datepick.to;
          this.getOverallStatisticsData();
        }
      });
  }
  initializeAllData() {
    this.getOverallStatisticsData(),
      this.initializeMonths(),
      this.getStatistics(),
      this.getYearlyPopularDestinations(),
      this.getStatisticsOrganizer(),
      this.getStatisticsOrganizerProfit(),
      this.getyearlyearning(),
      this.getyearlydebt(),
      this.getmoneymonthly(),
      this.getmonthlyearning(),
      this.getmonthlydebt(),
      this.getdailyearning(),
      this.getdailydept(),
      this.getdailymoney(),
      this.getMostPopularCountriesByMonth();
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getOverallStatisticsData() {
    const today = new Date();
    if (this.from) {
      const fromDate = this.from.toISOString();
      const toDate = this.to ? this.to.toISOString() : today.toISOString();
      const subscription = this.statisticsService
        .getOverallStatistics(fromDate, toDate)
        .pipe(
          tap((response) => {
            this.overallStatistics = response;
          })
        )
        .subscribe();
      this.subscriptions.add(subscription);
    }
  }
  getStatistics() {
    const statsSubscription = this.statisticsService
      .getStatistics()
      .pipe(
        tap((response) => {
          this.statisticsData = response;
          this.initializeYears();
        })
      )
      .subscribe();

    this.subscriptions.add(statsSubscription);
  }

  getyearlyearning() {
    const getEarningStatSub = this.statisticsService
      .getYearlyEarningStatistics()
      .pipe(
        tap((response) => {
          this.yearlyearning = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getEarningStatSub);
  }

  getmonthlyearning(): void {
    const getEarningsStatSub = this.statisticsService
      .getEarningsMonthStatistics(this.selectedYear)
      .pipe(
        tap((response) => {
          this.montlyearning = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getEarningsStatSub);
  }

  getmoneymonthly() {
    const moneyMonthStatSub = this.statisticsService
      .getMoneyMonthStatistics(this.selectedYear)
      .pipe(
        tap((response) => {
          this.monthlystatistic = response;
        })
      )
      .subscribe();

    this.subscriptions.add(moneyMonthStatSub);
  }

  getdailyearning() {
    const getEarningStatSub = this.statisticsService
      .getEarningsDailyStatistics(this.selectedYear, this.selectedMonth)
      .pipe(
        tap((response) => {
          this.dailyearnings = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getEarningStatSub);
  }

  getdailymoney() {
    const getMoneyDailyStatSub = this.statisticsService
      .getMoneyDailyStatistics(this.selectedYear, this.selectedMonth)
      .pipe(
        tap((response) => {
          this.dailystatistic = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getMoneyDailyStatSub);
  }

  getmonthlydebt() {
    const getDebtmonthStatSub = this.statisticsService
      .getDebtMonthStatistics(this.selectedYear)
      .pipe(
        tap((response) => {
          this.monthlydebt = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getDebtmonthStatSub);
  }

  getdailydept() {
    const getDebtDailyStatSub = this.statisticsService
      .getDebtDailyStatistics(this.selectedYear, this.selectedMonth)
      .pipe(
        tap((response) => {
          this.dailyeardebt = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getDebtDailyStatSub);
  }

  getyearlydebt() {
    const getYearlyDebtStatSub = this.statisticsService
      .getYearlyDebtStatistics()
      .pipe(
        tap((response) => {
          this.yearlydebt = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getYearlyDebtStatSub);
  }

  getStatisticsOrganizer(): void {
    const getOrganizerStatSub = this.statisticsService
      .getOrganizerStatistics(this.numberOfOrganizers)
      .pipe(
        tap((response) => {
          this.statisticsOrganizer = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getOrganizerStatSub);
  }

  getStatisticsOrganizerProfit(): void {
    const getOrganizerProfitStatSub = this.statisticsService
      .getStatisticsOrganizerProfit(this.numberOfOrganizers)
      .pipe(
        tap((response) => {
          this.statisticsOrganizerProfit = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getOrganizerProfitStatSub);
  }

  onTabChange(tab: string) {
    this.activeTab = tab;
  }
  onTabChanges(tab: string) {
    this.activeTabs = tab;
  }

  getYearlyPopularDestinations() {
    const getYearlyPopularDestSub = this.statisticsService
      .getYearlyPopularDestinations(this.selectedYear, this.numberOfDestinations)
      .pipe(
        tap((response) => {
          this.yearlypopulardestination = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getYearlyPopularDestSub);
  }

  getMostPopularCountriesByMonth() {
    const getMostPopularConutriesSub = this.statisticsService
      .getMostPopularCountriesByMonth(this.selectedYear, this.numberOfDestinations)
      .pipe(
        tap((response) => {
          this.mostPopularCountriesByMonth = response;
        })
      )
      .subscribe();

    this.subscriptions.add(getMostPopularConutriesSub);
  }

  onNumberOfOrganizersChange(number: number): void {
    this.numberOfOrganizers = number;
    this.getStatisticsOrganizer();
    this.getStatisticsOrganizerProfit();
  }

  selectYear(year: number) {
    this.selectedYear = year ? year : this.availableYears[this.availableYears.length - 1];
    this.year = this.selectedYear;
    this.from = new Date(`${this.selectedYear}-01-01`);
    this.to = new Date(`${this.selectedYear}-12-31`);
    this.getYearlyPopularDestinations();
    this.getmoneymonthly();
    this.getmonthlydebt();
    this.getmonthlyearning();
    this.getdailydept();
    this.getdailymoney();
    this.getdailyearning();
    this.getMostPopularCountriesByMonth();
  }

  selectMonth(month: number) {
    this.selectedMonth = month ? month : this.availableMonths[this.availableMonths.length - 1].value;
    this.getdailydept(), this.getdailymoney(), this.getdailyearning();
  }

  selectNumberOfDestinations(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
  }

  onYearChange(year: number) {
    this.selectedYear = year;
    this.getStatistics();
    this.getYearlyPopularDestinations();
    this.getMostPopularCountriesByMonth();
    this.getmoneymonthly();
    this.getmonthlydebt();
    this.getmonthlyearning();
    this.getdailydept();
    this.getdailymoney();
    this.getdailyearning();
  }

  onMonthChange(month: number) {
    this.selectedMonth = month;
    this.getdailydept(), this.getdailymoney(), this.getdailyearning();
  }

  onNumberOfDestinationsChange(number: number) {
    this.numberOfDestinations = number;
    this.getStatistics();
    this.getYearlyPopularDestinations();
  }

  initializeYears() {
    if (this.statisticsData && this.statisticsData.yearlyServiceStats) {
      const years = this.statisticsData.yearlyServiceStats.map((item) => item.year);
      this.availableYears = Array.from(new Set(years));
      this.selectedYear = this.availableYears[this.availableYears.length - 1];
    }
  }

  initializeMonths() {
    this.translate.get("months").subscribe((translations: any) => {
      this.availableMonths = Object.keys(translations).map((key) => ({
        label: translations[key],
        value: +key,
      }));
      const currentMonth = new Date().getMonth() + 1;
      this.selectedMonth = this.availableMonths.find((month) => month.value === currentMonth)?.value || 1;
    });
  }
}
