import { Observable } from "rxjs";
import { toggleAnimation } from "../../animations";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { PicklistType } from "../../enums/picklist-types";
import { PicklistOption } from "../../models/picklist-option";
import { PicklistService } from "../../services/picklist.service";
import { Component, forwardRef, Input, OnInit, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-select-remote",
  templateUrl: "./select-remote.component.html",
  animations: [toggleAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectRemoteComponent),
      multi: true,
    },
  ],
})
export class SelectRemoteComponent implements ControlValueAccessor, OnInit {
  public options$!: Observable<PicklistOption[]>;
  public input = null;

  @Input() minTermLength = 2;
  @Input() type!: PicklistType;
  @Input() searchable = false;
  @Input() labelForId!: string;
  @Input() formControlName!: string;
  @Input() selectControl!: FormGroup;
  @Input() setDefaultFirstOption = false;

  @ViewChild("remoteSelect") remoteSelect!: NgSelectComponent;
  constructor(public picklistService: PicklistService, public translate: TranslateService) {}

  ngOnInit(): void {
    this.options$ = this.picklistService.getPicklistOptions(this.type);
    this.options$.subscribe((options) => {
      if (this.setDefaultFirstOption && options.length > 0) {
        this.setDefaultValue(options[0]);
      }
    });
  }

  public focusField() {
    this.remoteSelect.focus();
  }

  setDefaultValue(option: PicklistOption) {
    this.writeValue(option);
    if (this.selectControl && this.selectControl.get(this.formControlName)) {
      this.selectControl.get(this.formControlName)?.setValue(option);
    }
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched() {}
}
