import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LanguageState } from "./language.reducer";

export const selectLanguageState = createFeatureSelector<LanguageState>("lang");

export const selectLocale = createSelector(
  selectLanguageState,
  (state: LanguageState) => state.locale
);

export const selectLanguageList = createSelector(
  selectLanguageState,
  (state: LanguageState) => state.languageList
);
