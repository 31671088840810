import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { AppState } from "../store/index.selector";
import { slideDownUp } from "../shared/animations";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit, Type } from "@angular/core";
import { toggleSidebar } from "../store/layout/layout.actions";
import { selectSemidark } from "../store/layout/layout.selector";
import { LocalStorageService } from "../service/local-storage.service";
import { IconMenuBusComponent } from "../shared/icon/menu/icon-menu-bus";
import { animate, style, transition, trigger } from "@angular/animations";
import { IconMenuHotelComponent } from "../shared/icon/menu/icon-menu-hotel";
import { IconMenuPlaneComponent } from "../shared/icon/menu/icon-menu-plane";
import { IconMenuTravelComponent } from "../shared/icon/menu/icon-menu-travel";
import { IconMenuCompanyComponent } from "../shared/icon/menu/icon-menu-company";
import { IconMenuWorkersComponent } from "../shared/icon/menu/icon-menu-workers";
import { IconMenuTransferComponent } from "../shared/icon/menu/icon-menu-transfer";
import { IconMenuCustomerComponent } from "../shared/icon/menu/icon-menu-customer";
import { IconMenuOrganizerComponent } from "../shared/icon/menu/icon-menu-organizer";
import { IconMenuStatisticsComponent } from "../shared/icon/menu/icon-menu-statistics";
import { IconMenuDailySalesComponent } from "../shared/icon/menu/icon-menu-daily-sales";
import { IconMenuDestinationComponent } from "../shared/icon/menu/icon-menu-destination";
import { IconMenuGroupTravelComponent } from "../shared/icon/menu/icon-menu-group-travel";
import { IconMenuDashboardDinoComponent } from "../shared/icon/menu/icon-menu-dashboard-dino";
import { IconMenuOtherServicesComponent } from "../shared/icon/menu/icon-menu-other-services";
import { IconMenuDailyWorkerSalesComponent } from "../shared/icon/menu/icon-menu-dailyworker-sales";

@Component({
  moduleId: module.id,
  selector: "app-sidebar",
  templateUrl: "./sidebar.html",
  animations: [
    slideDownUp,
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  active = false;
  activeDropdown: string[] = [];
  parentDropdown = "";
  semidark$!: Observable<boolean>;
  isTicketDataInProgress!: boolean;
  isGroupTravelInProgress!: boolean;
  isOtherServiceInProgress!: boolean;
  isTransferInProgress!: boolean;
  isTravelDataInProgress!: boolean;
  private subscriptions: Subscription[] = [];
  inProgressCount = 0;

  constructor(public translate: TranslateService, public storeData: Store<AppState>, public router: Router, private localStorageService: LocalStorageService) {
    this.semidark$ = this.storeData.select(selectSemidark);
  }

  public sidebarSections: SidebarSection[] = [
    {
      titleTranslationKey: "",
      items: [
        { path: "/dashboard/sales", translationKey: "dashboard", iconComponent: IconMenuDashboardDinoComponent },
        { path: "/dashboard/statistics", translationKey: "stats", iconComponent: IconMenuStatisticsComponent, packageRequirment: "statisticsAccounting" },
      ],
    },
    {
      titleTranslationKey: "entities",
      items: [
        { path: "/dashboard/tickets/flight", translationKey: "plane_tickets", iconComponent: IconMenuPlaneComponent },
        { path: "/dashboard/travels", translationKey: "travel", iconComponent: IconMenuTravelComponent },
        { path: "/dashboard/tickets/bus", translationKey: "bus_tickets", iconComponent: IconMenuBusComponent },
        { path: "/dashboard/group-travels", translationKey: "group_travel", iconComponent: IconMenuGroupTravelComponent },
        { path: "/dashboard/other-services", translationKey: "sidebar.other_services", iconComponent: IconMenuOtherServicesComponent },
        { path: "/dashboard/transfers", translationKey: "sidebar.transfer", iconComponent: IconMenuTransferComponent },
      ],
    },
    {
      titleTranslationKey: "clients_and_colaborators",
      items: [
        { path: "/dashboard/customers", translationKey: "clients", iconComponent: IconMenuCustomerComponent },
        { path: "/dashboard/hotels", translationKey: "hotels", iconComponent: IconMenuHotelComponent },
        { path: "/dashboard/companies", translationKey: "companies", iconComponent: IconMenuCompanyComponent },
        { path: "/dashboard/organizers", translationKey: "organizers", iconComponent: IconMenuOrganizerComponent },
        { path: "/dashboard/destinations", translationKey: "destinations", iconComponent: IconMenuDestinationComponent },
        { path: "/dashboard/workers", translationKey: "workers", iconComponent: IconMenuWorkersComponent, roles: ["Admin", "OfficeBranchManager"] },
      ],
    },
    {
      titleTranslationKey: "book_keeping",
      items: [
        { path: "/dashboard/daily-sale", translationKey: "daily_sales", iconComponent: IconMenuDailySalesComponent, packageRequirment: "creationOfDailySalesOrders" },
        { path: "/dashboard/daily_actions", translationKey: "daily_actions", iconComponent: IconMenuDailySalesComponent, packageRequirment: "creationOfDailySummaries", roles: ["Agent"] },
        { path: "/dashboard/daily_actions_worker", translationKey: "daily_action_worker", iconComponent: IconMenuDailyWorkerSalesComponent, roles: ["Admin", "OfficeBranchManager"] },
      ],
    },
  ];

  ngOnInit() {
    this.setActiveDropdown();
    this.checkInProgress();
    this.subscriptions.push(
      this.localStorageService.getKeyStatus("ticketData").subscribe((status) => {
        this.isTicketDataInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("groupTravelPlan").subscribe((status) => {
        this.isGroupTravelInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("otherService").subscribe((status) => {
        this.isOtherServiceInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("transferPlan").subscribe((status) => {
        this.isTransferInProgress = status;
        this.checkInProgress();
      })
    );

    this.subscriptions.push(
      this.localStorageService.getKeyStatus("travelData").subscribe((status) => {
        this.isTravelDataInProgress = status;
        this.checkInProgress();
      })
    );
  }

  setActiveDropdown() {
    const selector = document.querySelector('.sidebar ul a[routerLink="' + window.location.pathname + '"]');
    if (selector) {
      selector.classList.add("active");
      const ul: any = selector.closest("ul.sub-menu");
      if (ul) {
        let ele: any = ul.closest("li.menu").querySelectorAll(".nav-link") || [];
        if (ele.length) {
          ele = ele[0];
          setTimeout(() => {
            ele.click();
          });
        }
      }
    }
  }

  toggleMobileMenu() {
    if (window.innerWidth < 1024) {
      this.storeData.dispatch(toggleSidebar());
    }
  }
  toggleSidebar() {
    this.storeData.dispatch(toggleSidebar());
  }

  checkInProgress() {
    this.inProgressCount = 0;
    const keys = ["ticketData", "groupTravelPlan", "otherService", "transferPlan", "travelData"];
    keys.forEach((key) => {
      if (this.localStorageService.getKeyStatus(key).getValue()) {
        this.inProgressCount++;
      }
    });
  }
}

interface SidebarItem {
  path: string;
  translationKey: string;
  iconComponent: Type<any>;
  roles?: string[];
  packageRequirment?: string | undefined;
}

interface SidebarSection {
  titleTranslationKey: string;
  items: SidebarItem[];
}
