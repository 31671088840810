import { Component, OnInit } from "@angular/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { ActivatedRoute } from "@angular/router";
import { GroupTravelPlan } from "../models/group-travel";

@Component({
  selector: "app-group-travel-plan-details",
  templateUrl: "./group-travel-plan-details.component.html",
})
export class GroupTravelPlanDetailsComponent implements OnInit {
  groupTravelData!: GroupTravelPlan;
  id!: number;

  constructor(private groupTravelPassengersService: GroupTravelsService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.id = +this.route.snapshot.params["id"];
    this.loadGroupTravelDetails();
  }

  loadGroupTravelDetails() {
    this.groupTravelPassengersService.getGroupTravelPlans(this.id).subscribe((response) => {
      this.groupTravelData = response;
    });
  }
}
