<div class="panel" id="voucher" *ngIf="voucher">
  <div class="d-flex justify-content-between align-items-center">
    <div class=" flex flex-wrap items-center justify-between">
    <div class="flex-2" *ngIf="voucher.officeBranch && voucher.officeBranch.logoImage">
      <img [src]="voucher.officeBranch.logoImage.url" alt="" class="w-15 h-20" />
    </div>
    <div class="space-y-1 text-white-dark">
      <div class="w-full" *ngIf="voucher.officeBranch">
        <div class="space-y-1 text-white-dark">
          <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.fullAddress">
            <span class="mr-2">{{ voucher.officeBranch.fullAddress }} </span>
            <icon-home />
          </div>
          <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.phoneNumber">
            <span class="mr-2">{{ voucher.officeBranch.phoneNumber }} </span>
            <icon-phone />
          </div>
          <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.email">
            <span class="mr-2">{{ voucher.officeBranch.email }} </span>
            <icon-mail />
          </div>
          <div class="flex flex-wrap justify-end" *ngIf="voucher.officeBranch.siteURL">
            <span class="mr-2">https://www.{{ voucher.officeBranch.siteURL }} </span>
            <icon-chrome />
          </div>
          <div class="flex flex-wrap justify-end">
            <span class="mr-2">{{ "giro-account" | translate }}: {{ voucher.officeBranch.bankAccount }},PIB: {{ voucher.officeBranch.pib }} </span>
            <icon-zip-file />
          </div>
        </div>
      </div>
    </div>

    </div>
  </div>

  <div class="mt-4">
    <p class="text-center text-lg text-gray-800 tracking-wide">
      {{ "vaucher." + label | translate }} {{ voucher.uniqueId }}
    </p>
  </div>
  
  <div class="col-span-2 flex flex-col sm:col-span-1 mt-10">
    <div class="">{{ "contract.traveller_information" | translate }}</div>
  </div>
  <div class="mt-2 flex">
    <table class="w-full border border-gray-300 table-auto">
      <tbody>
        <tr class="bg-gray-100 font-bold">
          <td colspan="5" class="p-2 border-b border-gray-300">{{ "vaucher.organizer" | translate }}: {{ voucher.agent }}</td>
        </tr>
        <tr>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.destination" | translate }}: {{ voucher.accommodation }}</td>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.hotel" | translate }}: {{ voucher.hotelLabel }}</td>
        </tr>
        <tr>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.transport" | translate }}: {{ voucher.transport }}</td>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.service" | translate }}: {{ voucher.service }}</td>
        </tr>
        <tr>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.start" | translate }}: {{ voucher.start | date : "dd/MM/yyyy"}}</td>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.end" | translate }}: {{ voucher.end | date : "dd/MM/yyyy" }}</td>
        </tr>
        <tr>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.check_in" | translate }}: {{ voucher.checkin | date : "dd/MM/yyyy" }}</td>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.check_out" | translate }}: {{ voucher.checkout | date : "dd/MM/yyyy" }}</td>
        </tr>
        <tr>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.days" | translate }}: {{ voucher.days }},  {{ "vaucher.nights" | translate }}: {{ voucher.nights }}</td>
          <td class="p-2 border-b border-gray-300">{{ "vaucher.rooms" | translate }}: {{ voucher.roomService }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-span-2 flex flex-col sm:col-span-1 mt-2">{{ "contract.passangers_information" | translate }}</div>
    <div class="table-responsive mt-2">
    <table class="table-striped">
      <thead>
        <tr>
          <th rowspan="4">{{ "vaucher.guests" | translate }}</th>
          <th>{{ "vaucher.name_and_surname" | translate }}</th>
          <!-- <th>{{ "vaucher.date_of_birth" | translate }}</th> -->
          <th>{{ "vaucher.email" | translate }}</th>
          <th>{{ "vaucher.passport_number" | translate }}</th>
          <th>{{ "vaucher.phone_number" | translate }}</th>
        </tr>
      </thead>

      <tbody>
        <ng-container *ngFor="let passanger of voucher.passengers; index as i">
          <tr>
            <td>{{ i + 1 }}</td>
            <td>{{ passanger?.name }} {{ passanger?.surname }}</td>
            <!-- <td>{{ passanger?.dateOfBirth | date : "dd.MM.yyyy" }}</td> -->
            <td>{{ passanger?.email }}</td>
            <td>{{ passanger?.passport }}</td>
            <td>{{ passanger?.phoneNumber }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="mt-2">
    <div class="col-span-2 flex flex-col sm:col-span-1 mt-2">{{ "ticket_checkout.remarks" | translate }}</div>
      <textarea id="notes" rows="3" class="w-100 form-textarea" [placeholder]="translate.instant('ticket_checkout.remarks')" [(ngModel)]="voucher.notes" readonly></textarea>
  </div>
  <div class="mt-10">
    <div class="mx-auto rounded-lg py-10">
      <div class="flex justify-between">
        <div>
          <p>
            <strong>{{ "vaucher.date" | translate }}:</strong> {{ today }}
          </p>
          <p>{{ "document_validity" | translate   }}</p>
        </div>
        <div>
          <p><strong>Agent:</strong> {{ voucher.createdByFullName }}</p>
          <p><strong>PTT:</strong> {{ voucher.officeBranch ? voucher.officeBranch.name : "" }}</p>
          <p>__________________</p>
        </div>
      </div>
    </div>
  </div>
</div>
