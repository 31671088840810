import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseHttpService } from "src/app/service/base.http.service";
import { Transfer, TransferPlan, TransferPlanEdit, Vehicle } from "./../models/transfer";
import { TransferQuickView } from "../models/transfer-quick-view";
import { TransferPlanQuickView } from "../models/transfer-plan-quick-view";

@Injectable({
  providedIn: "root",
})
export class TransfersService extends BaseHttpService {
  constructor(private http: HttpClient) {
    super();
  }

  getTransfers(): Observable<Transfer[]> {
    return this.http.get<Transfer[]>(`${this.apiUrl}/api/transfers`);
  }
  getTransferData(id: number): Observable<Transfer> {
    return this.http.get<Transfer>(`${this.apiUrl}/api/transfers/${id}`);
  }

  getTransferPlan(id: number): Observable<TransferPlan> {
    return this.http.get<TransferPlan>(`${this.apiUrl}/api/transfers/plan/${id}`);
  }

  getTransfersPicklist() {
    return this.http.get<Vehicle[]>(`${this.apiUrl}/api/transfers/picklist`);
  }

  createTransfer(data: any) {
    return this.http.post<any>(`${this.apiUrl}/api/transfers`, data);
  }

  updateTransfer(id: any, data: any) {
    return this.http.put<any>(`${this.apiUrl}/api/transfers/${id}`, data);
  }

  createTransferClone(id: number) {
    return this.http.post<Transfer>(`${this.apiUrl}/api/transfers/clone/${id}`, {});
  }

  // addPassangerToTransfer(data: any, departureId?: number, destinationId?: number) {
  //   let url = `${this.apiUrl}/api/transfers/plan`;
  //   if (departureId !== undefined && departureId !== null) {
  //     url += `/${departureId}`;
  //   }
  //   if (destinationId !== undefined && destinationId !== null) {
  //     url += `/${destinationId}`;
  //   }

  //   return this.http.post<any>(url, data);
  // }
  addPassangerToTransfer(data: any, transferIds: number[]) {
    const url = `${this.apiUrl}/api/transfers/plan/${transferIds.join(",")}`;
    return this.http.post<any>(url, data);
  }

  getTransferById(transferId: number) {
    return this.http.get<Transfer>(`${this.apiUrl}/api/Transfers/${transferId}`);
  }

  addTransferToTicket(id: number, data: any, type: string) {
    return this.http.post(`${this.apiUrl}/api/Transfers/${type}/${id}`, data);
  }

  deleteTransfer(id: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/transfers/${id}`, { responseType: "text" });
  }

  updateTransferPassanger(planId: number, data: TransferPlanEdit) {
    return this.http.put<any>(`${this.apiUrl}/api/Transfers/plan/${planId}`, data);
  }
  getTransferQuickView(id: number | undefined): Observable<TransferQuickView> {
    return this.http.get<TransferQuickView>(`${this.apiUrl}/api/Transfers/quick-view/${id}`);
  }
  getTransferPlanQuickView(id: number | undefined): Observable<TransferPlanQuickView> {
    return this.http.get<TransferPlanQuickView>(`${this.apiUrl}/api/Transfers/quick-view-plan/${id}`);
  }
  getTransfersPassangersByPlan(transferPlanId?: number): Observable<TransferPlan[]> {
    return this.http.get<TransferPlan[]>(`${this.apiUrl}/api/TransfersPlan/passangers-by-plan/${transferPlanId}`);
  }
}
