import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { selectLocale } from "../store/language/language.selector";
import { AppState } from "../store/index.selector";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  language!: string;

  constructor(private store: Store<AppState>) {
    this.store.select(selectLocale).subscribe((lang) => {
      this.language = lang;
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const modifiedReq = req.clone({
      setHeaders: {
        'Accept-Language': this.language || 'en-US'
      }
    });
    return next.handle(modifiedReq); 
  }
}
