<div class="main-section relative font-nunito text-sm font-normal antialiased" [ngClass]="[(sidebar$ | async) ? 'toggle-sidebar' : '', (menu$ | async), (layout$ | async ), (rtlClass$ | async)]">
  <!-- screen loader -->
  <div *ngIf="(isShowMainLoader$ | async)" class="screen_loader animate__animated fixed inset-0 z-[60] grid place-content-center bg-[#fafafa] dark:bg-[#060818]">
    <img class="ml-[5px] h-auto w-20 flex-none" src="/assets/images/loader.svg" alt="" />
  </div>

  <div class="text-black dark:text-white-dark">
    <div *ngIf="showTopButton" class="fixed bottom-6 z-50 ltr:right-6 rtl:left-6">
      <button type="button" class="btn btn-outline-primary animate-pulse rounded-full bg-[#fafafa] p-2 dark:bg-[#060818] dark:hover:bg-primary" (click)="goToTop()">
        <icon-arrow-top></icon-arrow-top>
      </button>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
