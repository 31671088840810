<div class="mb-3 flex flex-wrap items-center justify-center gap-4 lg:justify-end print:hidden">
  <button type="button" (click)="navigateToCreateVoucherPage()" class="btn btn-primary gap-2">
    <icon-voucher></icon-voucher>
    {{ "travel_details.open_voucher" | translate }}
  </button>

  <button type="button" (click)="navigateToContractPage()" class="btn btn-primary gap-2">
    <icon-contract></icon-contract>
    {{ "travel_details.open_contract" | translate }}
  </button>

  <button *appHasPackageRight="['eTouristAccounting']" type="button" (click)="modal21.open()" class="btn btn-primary gap-2" ngxTippy="Create Accounting Data">
    <icon-accounting-data></icon-accounting-data>
    {{ "account_data.accounting_data" | translate }}
  </button>
  <div [ngxTippy]="'higher_tier' | translate">
    <button *appHasPackageRight="['eTouristAccounting', true]" type="button" disabled="true" class="btn btn-primary gap-2">
      <icon-accounting-data></icon-accounting-data>
      {{ "account_data.accounting_data" | translate }}
    </button>
  </div>

  <app-file-upload [id]="planId"></app-file-upload>
  <app-file-history [isShowingSidebar]="isShowingSidebar" [entityname]="entityname" [id]="id" (toggleSidebar)="isShowingSidebar = !isShowingSidebar"></app-file-history>
</div>

<div *ngIf="travel">
  <div class="panel relative rounded-md bg-white shadow-md dark:bg-gray-900">
    <div class="grid grid-cols-2 gap-5 gap-x-2 sm:grid-cols-4">
      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="text-xl font-bold">
            {{ "travel_details.lead_passanger" | translate }}
          </div>
          <div class="text-xl">{{ travel.customer.name }} {{ travel.customer.surname }}</div>
          <div class="text-[15px] italic text-white-dark" style="font-style: italic">
            {{ travel.customer.phoneNumber }}
          </div>
        </div>

        <div class="col-span-1 flex flex-col sm:col-span-2 print:hidden">
          <div class="flex flex-col">
            <div>{{ "ticket_details.status" | translate }}:</div>
            <ng-container *ngIf="travel.plan.status; else notPaid">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.paid" | translate }}
              </div>
            </ng-container>
            <ng-template #notPaid>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "ticket_details.not_paid" | translate }}
              </div>
            </ng-template>
          </div>
          <div class="mt-2 flex flex-col">
            <div class="ltr:mr-2 rtl:ml-2">{{ "ticket_details.active" | translate }}:</div>
            <ng-container *ngIf="travel.plan.active; else notActive">
              <div class="badge mt-1 inline-flex w-auto self-start bg-success">
                {{ "ticket_details.active" | translate }}
              </div>
            </ng-container>
            <ng-template #notActive>
              <div class="badge mt-1 inline-flex w-auto self-start bg-slate-200 text-white">
                {{ "ticket_details.deleted" | translate }}
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg p-4 sm:col-span-4 sm:grid-cols-4 print:hidden">
        <div class="col-span-2 grid grid-cols-2 sm:col-span-4">
          <div class="col-span-2 flex flex-col pb-4 sm:col-span-4">
            <div class="text-xl font-bold">
              {{ "travel_details_label" | translate }}
            </div>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.departure_from" | translate }} :</div>
          <div *ngIf="travel.departure">{{ travel.departure.description }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.destination" | translate }} :</div>
          <div *ngIf="mainDestination">{{ mainDestination.destination?.description }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "notes" | translate }}:</div>
          <div>{{ travel.plan.notes }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1 sm:row-span-3">
          <div>{{ "travel_details.additional_destinations" | translate }} :</div>
          <div *ngFor="let dest of additionalDestinations">
            <app-travel-accordion [content]="dest"></app-travel-accordion>
          </div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.departure_date" | translate }}:</div>
          <div>{{ travel.start | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.return_date" | translate }}:</div>
          <div>{{ travel.end | date : "dd.MM.yyyy" }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.days_nights" | translate }}:</div>
          <div>{{ travel.days }}/{{ travel.nights }}</div>
        </div>

        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.hotel" | translate }}:</div>
          <div *ngIf="mainDestination">{{ mainDestination.hotel?.name }}</div>
        </div>
        <div class="col-span-1 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.room_description" | translate }}:</div>
          <div>{{ travel.type.title }}</div>
        </div>
        <div class="col-span-2 flex flex-col py-2 sm:col-span-1">
          <div>{{ "travel_details.service_type" | translate }}:</div>
          <div *ngIf="mainDestination">{{ mainDestination.service?.title }}</div>
        </div>
      </div>

      <div class="col-span-2 flex grid grid-cols-2 rounded-lg border border-white-dark/20 bg-slate-100 p-4 sm:col-span-4 sm:grid-cols-4">
        <div class="col-span-2 sm:col-span-4">
          <div class="text-xl font-bold">
            {{ "paymentssendemail.payments" | translate }}
          </div>
        </div>
        <app-sale-details class="col-span-2 sm:col-span-4" [id]="planId" [hideStatus]="true"></app-sale-details>
      </div>
    </div>
    <div class="my-5 block space-y-4 overflow-x-auto rounded-lg border border-white-dark/20 p-4 print:hidden" *ngIf="passangers && passangers.length > 0">
      <div class="text-[20px] text-dark">{{ "travel_details.all_passengers" | translate }}:</div>
      <div class="grid grid-cols-1 justify-center sm:grid-cols-1">
        <ng-container *ngFor="let item of passangers; index as i">
          <div
            class="flex grid grid-cols-2 items-center justify-between gap-5 gap-x-2 rounded-xl p-3 font-semibold text-gray-500 shadow-[0_0_4px_2px_rgb(31_45_61_/_10%)] transition-all duration-300 hover:scale-[1.01] hover:text-primary dark:bg-[#1b2e4b] sm:min-w-[625px] sm:grid-cols-4"
          >
            <div class="user-profile grid-cols-1 justify-self-center sm:grid-cols-1 sm:justify-self-start">
              <img [src]="item.imageUrl ? item.imageUrl : '/assets/images/auth/user-icon.png'" alt="" class="h-8 w-8 rounded-md object-cover" />
            </div>

            <div class="grid-cols-2 px-2 sm:grid-cols-1">{{ item.name }} {{ item.surname }}</div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.phone_number" | translate }}:
              {{ item.phoneNumber ? item.phoneNumber : "N/A" }}
            </div>
            <div class="grid-cols-1 px-2 sm:grid-cols-1">
              {{ "travel_details.email" | translate }}:
              {{ item.email ? item.email : "N/A" }}
            </div>

            <ng-template #backUp> </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<modal #modal21 class="modal-top auth-modal">
  <ng-template #modalHeader> <div class="!py-5">Register</div> </ng-template>
  <ng-template #modalBody>
    <form [formGroup]="form" (ngSubmit)="save()">
      <div class="relative mb-4" [ngClass]="form.controls['confirmationNumber'].touched ? (form.controls['confirmationNumber'].errors ? 'has-error' : 'has-success') : ''">
        <label for="confirmationNumber">{{ "account_data.confirmation_number" | translate }}:</label>
        <input type="text" placeholder="{{ 'account_data.confirmation_number' | translate }}" class="form-input rtl:pr-10" id="confirmationNumber" formControlName="confirmationNumber" />
        <ng-container *ngIf="form.controls['confirmationNumber'].touched && form.controls['confirmationNumber'].errors">
          <small class="mt-1 text-danger">{{ "account_data.please_provide_confirmation_number" | translate }}</small>
        </ng-container>
      </div>
      <div class="relative mb-4" [ngClass]="form.controls['insuranceNumber'].touched ? (form.controls['insuranceNumber'].errors ? 'has-error' : 'has-success') : ''">
        <label for="insuranceNumber">{{ "account_data.insurance_number" | translate }}:</label>
        <input type="text" placeholder="{{ 'account_data.insurance_number' | translate }}" class="form-input rtl:pr-10" id="insuranceNumber" formControlName="insuranceNumber" />
        <ng-container *ngIf="form.controls['insuranceNumber'].touched && form.controls['insuranceNumber'].errors">
          <small class="mt-1 text-danger">{{ "account_data.please_provide_insurance_number" | translate }}</small>
        </ng-container>
      </div>
      <div class="relative mb-4" [ngClass]="form.controls['sumInRSD'].touched ? (form.controls['sumInRSD'].errors ? 'has-error' : 'has-success') : ''">
        <label for="sumInRSD">{{ "account_data.sum_in_rsd" | translate }}:</label>
        <input type="text" placeholder="{{ 'account_data.sum_in_rsd' | translate }}" class="form-input rtl:pr-10" id="sumInRSD" formControlName="sumInRSD" />
        <ng-container *ngIf="form.controls['sumInRSD'].touched && form.controls['sumInRSD'].errors">
          <small class="mt-1 text-danger">{{ "account_data.please_provide_sum_in_rsd" | translate }}</small>
        </ng-container>
      </div>
      <button type="submit" class="btn btn-primary w-full">{{ "account_data.save" | translate }}</button>
    </form>
  </ng-template>
</modal>
