import { catchError, switchMap, tap } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { firstValueFrom, from, Observable, Subscription } from "rxjs";
import { InvoiceComponent } from "../components/invoice/invoice.component";
import { Destination } from "src/app/domain/destinations/models/destination";
import { HotelsService } from "src/app/domain/hotels/services/hotels.service";
import { WorkersService } from "src/app/domain/workers/services/workers.service";
import { CustomersService } from "src/app/domain/customers/services/customers.service";
import { CompaniesService } from "src/app/domain/companies/services/companies.service";
import { OrganizersService } from "src/app/domain/organizers/services/organizers.service";
import { HotelFormComponent } from "src/app/domain/hotels/hotel-form/hotel-form.component";
import { DestinationsService } from "src/app/domain/destinations/services/destinations.service";
import { CompanyFormComponent } from "src/app/domain/companies/company-form/company-form.component";
import { WorkerFormComponent } from "src/app/domain/workers/worker.form.component/worker.form.component";
import { OrganizerFormComponent } from "src/app/domain/organizers/organizer-form/organizer-form.component";
import { ConfirmationDialogComponent } from "../components/confirmation-dialog/confirmation-dialog.component";
import { EditDestinationComponent } from "src/app/domain/destinations/edit-destination/edit-destination.component";
import { Injectable, Injector, ComponentFactoryResolver, ApplicationRef, ComponentRef, Type } from "@angular/core";
import { CustomerCreateEditComponent } from "src/app/domain/customers/customer-create-edit/customer-create-edit.component";
import { ConfirmationDialogOptions, InvoiceDialogOptions, ModalComponentInputs } from "../models/modal-dialog-options";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  constructor(
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private translateService: TranslateService,
    private customersService: CustomersService,
    private hotelsService: HotelsService,
    private companiesService: CompaniesService,
    private organizersService: OrganizersService,
    private destinationsService: DestinationsService,
    private workersService: WorkersService
  ) {}

  /**
   * Opens a confirmation dialog.
   * @param options Customization options for the confirmation dialog.
   * @returns Promise<boolean> indicating user confirmation.
   */

  confirm(options: ConfirmationDialogOptions = {}): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(ConfirmationDialogComponent);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.title = options.title || this.translateService.instant("modal.confirmation");
      componentRef.instance.message = options.message || this.translateService.instant("modal.are_you_sure_you_want_to_proceed");
      componentRef.instance.confirmButtonText = options.confirmButtonText || this.translateService.instant("modal.confirm");
      componentRef.instance.cancelButtonText = options.cancelButtonText || this.translateService.instant("modal.cancel");

      componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
        resolve(confirmed);
        this.closeModal(componentRef, null, null, onEscape, onBackdropClick);
      });

      const backdrop = this.attachBackdrop();
      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, null, onEscape, onBackdropClick);
        resolve(false);
      };

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          this.closeModal(componentRef, backdrop, null, onEscape, onBackdropClick);
          resolve(false);
        }
      };

      backdrop.addEventListener("click", onBackdropClick);
      document.addEventListener("keydown", onEscape);

      this.attachComponentToDOM(componentRef);
    });
  }

  openDestinationModal(destination?: Destination): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      try {
        const componentFactory = this.resolver.resolveComponentFactory(EditDestinationComponent);
        const componentRef: ComponentRef<EditDestinationComponent> = componentFactory.create(this.injector);

        componentRef.instance.editMode = !!destination;
        componentRef.instance.destination = destination || ({} as Destination);
        componentRef.instance.isLoading = false;

        this.attachComponentToDOM(componentRef);
        const backdrop = this.attachBackdrop();

        let isSubmitted = false;

        const onEscape = (event: KeyboardEvent) => {
          if (event.key === "Escape") {
            this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
            observer.next(false);
            observer.complete();
          }
        };

        const onBackdropClick = () => {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          observer.next(false);
          observer.complete();
        };

        backdrop.addEventListener("click", onBackdropClick);
        document.addEventListener("keydown", onEscape);

        const subscription: Subscription = componentRef.instance.formSubmit
          .pipe(
            switchMap((data: any) => {
              if (data && !isSubmitted) {
                isSubmitted = true;
                if (componentRef.instance.editMode) {
                  return from(this.destinationsService.updateDestination(destination?.id || 0, data)).pipe(
                    tap(() => {
                      this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                      observer.next(true);
                    })
                  );
                } else {
                  return from(this.destinationsService.addDestination(data)).pipe(
                    tap(() => {
                      this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                      observer.next(true);
                    })
                  );
                }
              } else {
                this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                observer.next(false);
                return new Observable<boolean>();
              }
            }),
            catchError((error) => {
              console.error(error);
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              observer.next(false);
              observer.complete();
              return new Observable<boolean>();
            })
          )
          .subscribe();
      } catch (error) {
        observer.error(error);
      }
    });
  }
  openModal<T>(component: Type<ModalComponentInputs<T>>, service: any, entityId?: number, updateMethod?: string, addMethod?: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      const componentFactory = this.resolver.resolveComponentFactory(component);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.editMode = entityId !== undefined;
      if (entityId !== undefined) {
        componentRef.instance.entityId = entityId;
      }

      const backdrop = document.createElement("div");
      backdrop.className = "fixed inset-0 bg-black bg-opacity-50 z-40";
      document.body.appendChild(backdrop);

      let isSubmitted = false;
      const subscription = componentRef.instance.formSubmit
        .pipe(
          switchMap(async (entityData) => {
            if (entityData !== null && !isSubmitted) {
              isSubmitted = true;
              try {
                if (componentRef.instance.editMode) {
                  if (updateMethod && typeof service[updateMethod] === "function") {
                    await firstValueFrom(service[updateMethod](entityId!, entityData));
                  }
                } else {
                  if (addMethod && typeof service[addMethod] === "function") {
                    await firstValueFrom(service[addMethod](entityData));
                  }
                }
                this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
                observer.next(true);
                observer.complete();
              } catch (error) {
                isSubmitted = false;
                observer.next(false);
                observer.complete();
              }
            } else {
              this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
              observer.next(false);
              observer.complete();
            }
          })
        )
        .subscribe();

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);

      const onBackdropClick = () => {
        this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
        observer.next(false);
        observer.complete();
      };
      backdrop.addEventListener("click", onBackdropClick);

      const onEscape = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
          this.closeModal(componentRef, backdrop, subscription, onEscape, onBackdropClick);
          observer.next(false);
          observer.complete();
        }
      };
      document.addEventListener("keydown", onEscape);
    });
  }
  openWorkerModal(workerId?: number): Observable<boolean> {
    return this.openModal(WorkerFormComponent, this.workersService, workerId, "updateWorker", "createWorker");
  }
  openOrganizerModal(organizerId?: number): Observable<boolean> {
    return this.openModal(OrganizerFormComponent, this.organizersService, organizerId, "updateOrganizer", "addOrganizer");
  }

  openCompanyModal(companyId?: number): Observable<boolean> {
    return this.openModal(CompanyFormComponent, this.companiesService, companyId, "updateCompany", "addCompany");
  }

  openHotelModal(hotelId?: number): Observable<boolean> {
    return this.openModal(HotelFormComponent, this.hotelsService, hotelId, "updateHotel", "addHotel");
  }

  openCustomerModal(customerId?: number): Observable<boolean> {
    return this.openModal(CustomerCreateEditComponent, this.customersService, customerId, "updateCustomerData", "createCustomerData");
  }

  private attachComponentToDOM(componentRef: ComponentRef<any>): void {
    this.appRef.attachView(componentRef.hostView);
    const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  private attachBackdrop(): HTMLElement {
    const backdrop = document.createElement("div");
    backdrop.className = "fixed inset-0 bg-black bg-opacity-50 z-40";
    document.body.appendChild(backdrop);
    return backdrop;
  }

  public closeModal(componentRef: ComponentRef<any>, backdrop: HTMLElement | null, subscription: any, onEscape: (event: KeyboardEvent) => void, onBackdropClick: () => void): void {
    if (subscription) {
      subscription.unsubscribe();
    }

    if (backdrop) {
      backdrop.removeEventListener("click", onBackdropClick);
      document.body.removeChild(backdrop);
    }

    if (onEscape) {
      document.removeEventListener("keydown", onEscape);
    }

    this.appRef.detachView(componentRef.hostView);
    componentRef.destroy();
  }

  openModalForSendingInovice(options: InvoiceDialogOptions): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const componentFactory = this.resolver.resolveComponentFactory(InvoiceComponent);
      const componentRef = componentFactory.create(this.injector);

      componentRef.instance.title = options.title || this.translateService.instant("modal.confirmation");
      componentRef.instance.message = options.message || this.translateService.instant("modal.are_you_sure_you_want_to_proceed");
      componentRef.instance.confirmButtonText = options.confirmButtonText || this.translateService.instant("modal.confirm");
      componentRef.instance.cancelButtonText = options.cancelButtonText || this.translateService.instant("modal.cancel");
      componentRef.instance.plan = options.plan;
      componentRef.instance.navigateToRoute = options.navigateToRoute;
      componentRef.instance.isOnlyAvans = options.isOnlyAvans;
      componentRef.instance.activeTab = options.activeTab;
      componentRef.instance.paymentId = options.paymentId;

      componentRef.instance.confirmed.subscribe((confirmed: boolean) => {
        resolve(confirmed);
        this.appRef.detachView(componentRef.hostView);
        componentRef.destroy();
      });

      this.appRef.attachView(componentRef.hostView);
      const domElem = (componentRef.hostView as any).rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    });
  }
}
