import * as moment from "moment";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { VoucherService } from "../services/voucher.service";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { Component, Input, SimpleChanges } from "@angular/core";
import { Voucher } from "src/app/domain/travels/models/voucher";
import { Customer } from "src/app/domain/customers/models/customer";

@Component({
  selector: "app-voucher-edit",
  templateUrl: "./voucher.edit.component.html",
})
export class VoucherEditComponent {
  @Input() voucher!: Voucher;
  @Input() passangers!: Customer[];
  @Input() cancelEditUrl!: string;

  constructor(private route: ActivatedRoute, public fb: FormBuilder, public router: Router, public voucherService: VoucherService, public translate: TranslateService) {}

  mask8 = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
  today: string = new Date().toLocaleDateString("sr-RS");
  form = this.fb.group({
    agent: ["", Validators.required],
    transport: ["", Validators.required],
    hotelLabel: ["", Validators.required],
    checkin: ["", Validators.required],
    checkout: ["", Validators.required],
    roomService: ["", Validators.required],
    service: ["", Validators.required],
    accommodation: ["", Validators.required],
    start: ["", Validators.required],
    end: ["", Validators.required],
    days: ["", Validators.required],
    nights: ["", Validators.required],
    notes:["", Validators.required]

  });

  ngOnChanges(changes: SimpleChanges) {
    if (changes["voucher"] && this.voucher) {
      this.updateFormWithVoucher();
    }
  }

  private updateFormWithVoucher() {
    this.form.patchValue({
      agent: this.voucher.agent,
      transport: this.voucher.transport,
      hotelLabel: this.voucher.hotelLabel,
      checkin: this.convertToLocalDate(this.voucher.checkin),
      checkout: this.convertToLocalDate(this.voucher.checkout),
      roomService: this.voucher.roomService,
      service: this.voucher.service,
      accommodation: this.voucher?.accommodation,
      start: this.convertToLocalDate(this.voucher?.start),
      end: this.convertToLocalDate(this.voucher?.end),
      days: this.voucher?.days?.toString(),
      nights: this.voucher?.nights?.toString(),
      notes: this.voucher?.notes
    });
  }

  save() {
    if (this.form.invalid) {
      showMessage(PopupType.Warning, this.translate.instant("popup.please_fill_all_required_fields"));
      this.form.markAllAsTouched();
      return;
    }
    const updateSub = this.voucherService.updateTravelVoucher(this.voucher.id, this.extractVoucher()).subscribe({
      next: () => {
        updateSub.unsubscribe();
        showMessage(PopupType.Success, this.translate.instant("popup.voucher_updated_successfully"));
        this.router.navigate([this.cancelEditUrl]);
      },
      error: (error) => {
        updateSub.unsubscribe();
        showMessage(PopupType.Danger, error.toString());
      },
    });
  }

  convertToLocalDate(dateString: string): string {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("sr-RS", { day: "2-digit", month: "2-digit", year: "numeric" });
  }

  extractVoucher() {
    this.passangers = this.passangers.map((passanger: any) => {
      passanger.name = passanger.fullName.split(" ")[0];
      passanger.surname = passanger.fullName.split(" ")[1];
      passanger.dateOfBirth = passanger.dateOfBirth ? moment(passanger.dateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD") : undefined;
      return passanger;
    });

    const { agent, transport, hotelLabel, checkin, checkout, roomService, service ,notes} = this.form.value;
    const voucher = {
      agent,
      transport,
      hotelLabel,
      checkin: moment(checkin, "DD/MM/YYYY").format("YYYY-MM-DD"),
      checkout: moment(checkout, "DD/MM/YYYY").format("YYYY-MM-DD"),
      roomService,
      service,
      passengers: this.passangers,
      accommodation: this.form.value.accommodation,
      start: moment(this.form.value.start, "DD/MM/YYYY").format("YYYY-MM-DD"),
      end: moment(this.form.value.end, "DD/MM/YYYY").format("YYYY-MM-DD"),
      days: this.form.value.days,
      nights: this.form.value.nights,
      notes
    };

    return voucher;
  }

  cancelEdit(e: Event) {
    e.preventDefault();
    this.router.navigate([this.cancelEditUrl]);
  }
}
