import { toggleAnimation } from "../../animations";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectComponent } from "@ng-select/ng-select";
import { Hotel } from "src/app/domain/hotels/models/hotel";
import { EntityPicklistType } from "../../enums/picklist-types";
import { PicklistService } from "../../services/picklist.service";
import { FormControl, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { catchError, concat, distinctUntilChanged, Observable, of, Subject, switchMap, tap } from "rxjs";
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-autocomplete-remote",
  templateUrl: "./autocomplete-remote.component.html",
  animations: [toggleAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteRemoteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteRemoteComponent implements OnInit {
  public input = null;
  public options$!: Observable<any[]>;

  @ViewChild("autocomplete") autocomplete!: NgSelectComponent;

  constructor(public picklistService: PicklistService, public translate: TranslateService) {
    this.mockFormName = new FormGroup({
      [this.formControlName]: new FormControl(""),
    });
  }
  @Input() labelName!: string;
  @Input() placeholder!: string;
  @Input() type!: EntityPicklistType;

  @Input() minTermLength = 2;
  @Input() labelForId!: string;
  @Input() formControlName!: string;
  @Input() selectControl!: FormGroup | undefined;
  @Output() hotelSelected = new EventEmitter<Hotel>();

  @Input() changedCallback!: (val: any) => void;
  @Input() addCallback!: (val: any) => void;

  optionsLoading = false;
  mockFormName: FormGroup;
  optionsInput$ = new Subject<string>();
  selectedPersons: any[] = [];

  ngOnInit() {
    this.fetchOptions();
  }

  trackByFn(item: any) {
    return item.id;
  }

  focusField() {
    this.autocomplete.focus();
  }

  private fetchOptions() {
    this.options$ = concat(
      of([]),
      this.optionsInput$.pipe(
        distinctUntilChanged(),
        tap(() => (this.optionsLoading = true)),
        switchMap((term) =>
          this.picklistService.getPicklistOptionsForEntity(this.type, term).pipe(
            catchError(() => of([])),
            tap(() => (this.optionsLoading = false))
          )
        )
      )
    );
  }

  saveNewOption(value: string) {
    if (this.addCallback) {
      this.addCallback(value);
      this.autocomplete.blur();
      this.autocomplete.close();
    }
  }

  changed(value: any) {
    if (this.changedCallback) {
      this.changedCallback(value);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAdd(value: any) {}

  writeValue(value: any): void {
    this.input = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange() {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched() {}
}
