import { Store } from "@ngrx/store";
import { ModalComponent } from "angular-custom-modal";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TableService } from "src/app/shared/services/table.service";
import { ETouristDestination, ETouristTravel } from "../models/e-tourist.travel";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";

import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";
@Component({
  templateUrl: "./e-tourist.table.component.html",
  animations: [toggleAnimation],
})
export class ETouristTableComponent extends BaseTableComponent<ETouristTravel> implements OnInit {
  @ViewChild("modal") modal!: ModalComponent;

  constructor(public override translate: TranslateService, public override store: Store<AppState>, public override tableService: TableService, public override userService: UserService) {
    super(store, tableService, "etourist/travels/plans", userService, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override getColumns(): void {
    this.cols = [
      { field: "uniqueIdentifier", title: this.translate.instant("travel_package.uniqueIdentifier"), isUnique: true, hide: true } as DatatableColumn,
      { field: "travelAgencyName", title: this.translate.instant("travel_package.travelAgencyName"), hide: false } as DatatableColumn,
      { field: "travelAgencyIdentificationNumber", title: this.translate.instant("travel_package.travelAgencyIdentificationNumber"), hide: false } as DatatableColumn,
      { field: "name", title: this.translate.instant("travel_package.name"), hide: false } as DatatableColumn,
      { field: "destinationsCustom", title: this.translate.instant("travel_package.destinations"), hide: false } as DatatableColumn,
    ];
  }

  getDestinations(destinations: ETouristDestination[]) {
    return destinations.map((d) => `${d.countryName}, ${d.cityName}`).join(" | ");
  }
}
