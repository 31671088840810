import { Observable } from "rxjs";
import { UserWorker } from "../models/worker";
import { TranslateService } from "@ngx-translate/core";
import { WorkersService } from "../services/workers.service";
import { Office } from "../../office-branch/models/office-branch";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";

@Component({
  selector: "app-worker-form",
  templateUrl: "./worker.form.component.html",
})
export class WorkerFormComponent implements OnInit {
  @Output() formSubmit = new EventEmitter<UserWorker | null>();
  @Input() id!: number | undefined;
  @Input() entityId!: number;
	@ViewChild("nameInput") nameInput!: ElementRef;
  @Input() editMode = false;



  public form!: FormGroup;
  public isLoading = false;

  options = [
    { value: "admin", label: this.translate.instant("admin") },
    { value: "agent", label: this.translate.instant("agent") },
  ];
  options$!: Observable<Office[]>;

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private workerService: WorkersService
  ) { }

  ngOnInit(): void {
    this.initForm();
    if (this.entityId !== undefined) {
      this.workerService.getWorkerById(this.entityId).subscribe((worker) => {
        this.initForm(worker);
      });
    } else {
      this.initForm({} as UserWorker);
    }
    this.options$ = this.workerService.fetchOfficeBranches();

		setTimeout(() => {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }, 0);
  }
	
  initForm(worker: UserWorker = {} as UserWorker): void {
    const {
      name = "",
      surname = "",
      email = "",
      username = "",
      password = "",
      role = "admin",
      officeBranch = null,
      uniqueMasterCitizenNumber = "",
      isOfficeBranchManager = false,
    } = worker;
    const passwordValidators = this.entityId ? [] : [Validators.required];
    this.form = this.fb.group({
      name: [name, Validators.required],
      surname: [surname, Validators.required],
      email: [email, [Validators.required, Validators.email]],
      username: [username, Validators.required],
      password: [password, passwordValidators],
      role: [this.options.find((o) => o.value === role), Validators.required],
      officeBranch: [officeBranch, Validators.required],
      uniqueMasterCitizenNumber: [
        uniqueMasterCitizenNumber,
        Validators.required,
      ],
      isOfficeBranchManager: [isOfficeBranchManager, Validators.required],
    });
  }

  fetchWorker(): UserWorker {
    const {
      name,
      surname,
      email,
      username,
      password,
      role,
      officeBranch,
      uniqueMasterCitizenNumber,
      isOfficeBranchManager,
    } = this.form.value;

    return {
      name,
      surname,
      email,
      username,
      password,
      role: role.value,
      officeBranchId: officeBranch.id,
      uniqueMasterCitizenNumber,
      isOfficeBranchManager: isOfficeBranchManager ?? false,
    } as UserWorker;
  }

  submitForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    const workerData: UserWorker = this.fetchWorker();
    this.formSubmit.emit(workerData);


    setTimeout(() => {
      this.isLoading = false;
    }, 500);
  }

  closeModal() {
    this.formSubmit.emit(null);
  }
}