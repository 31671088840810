import { Component, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { slideDownUp } from "src/app/shared/animations";
import { TravelDestination } from "src/app/domain/travels/models/travel-destination";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-travel-accordion",
  templateUrl: "./accordion-item.component.html",
  animations: [slideDownUp],
})
export class AccordionComponent {
  @Input() content!: TravelDestination;
  isOpen = false;

  constructor(public translate: TranslateService) {}

  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
