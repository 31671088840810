import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { GroupTravelsService } from "../../group-travels/services/group-travels.service";
import { GroupTravelQuickView } from "../../group-travels/models/group-travel-quick";

@Component({
  selector: "app-group-travel-quick-view",
  templateUrl: "./group-travel-quick-view.component.html",
})
export class GroupTravelQuickViewComponent implements OnInit, OnDestroy{
  public  data!: GroupTravelQuickView;
  @Input() id!: number;
  private getSubscription!: Subscription;


  constructor(public translate: TranslateService, private groupTravelService: GroupTravelsService) {}

  ngOnInit(): void {
    if (this.id) {
      this.fetchGroupTravelsQuickView(this.id);
    }
  }
  fetchGroupTravelsQuickView(id:number){
   this.getSubscription = this.groupTravelService.getGroupTravelsQuickView(id).subscribe((grouptravels) => {
        this.data = grouptravels;
      });
  }
  ngOnDestroy(): void {
    if (this.getSubscription) {
      this.getSubscription.unsubscribe();
    }
  }
}
