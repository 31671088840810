<div class="pt-5">
  <div class="mb-5 flex overflow-y-auto whitespace-nowrap border-b border-[#ebedf2] font-semibold dark:border-[#191e3a]">
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'profile' }"
      (click)="setActiveTab('profile')"
    >
      <icon-user />
      {{ "settings_profile.profile" | translate }}
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'picklists' }"
      (click)="setActiveTab('picklists')"
    >
      <icon-dollar-sign-circle />
      {{ "settings_profile.picklists" | translate }}
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'office-branches' }"
      (click)="setActiveTab('office-branches')"
      *ngIf="($userRole | async) === 'OfficeBranchManager'"
    >
      <icon-company class="h-5 w-5" />
      {{ "settings_profile.offices" | translate }}
    </a>
    <a
      href="javascript:;"
      class="flex gap-2 border-b border-transparent p-4 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'danger-zone' }"
      (click)="setActiveTab('danger-zone')"
    >
      <icon-phone />
      {{ "settings_profile.dangerZone" | translate }}
    </a>

    <a
      href="javascript:;"
      class="flex gap-3 border-b border-transparent p-5 !outline-none hover:border-primary hover:text-primary"
      [ngClass]="{ '!border-primary text-primary': activeTab.toLowerCase() === 'layout' }"
      (click)="setActiveTab('layout')"
    >
      <icon-settings />
      {{ "settings_profile.settings" | translate }}
    </a>
  </div>
  <div>
    <div *ngIf="activeTab.toLowerCase() === 'profile'">
      <app-user-profile />
    </div>
    <div *ngIf="activeTab.toLowerCase() === 'picklists'">
      <div class="panel space-y-8">
        <div class="flex flex-col">
          <ng-container *ngFor="let type of typeList; index as i">
            <app-settings-create [type]="type" [title]="titleList[i]" [accordion]="accordion" [orderNumber]="i" (updateActiveAccordion)="updateAccordion($event)"></app-settings-create>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="activeTab.toLowerCase() === 'layout'">
      <div class="switch">
        <div class="panel">
          <app-layout></app-layout>
        </div>
      </div>
    </div>
    <ng-container *ngIf="activeTab.toLowerCase() === 'office-branches'">
      <div class="switch" *appHasRole="['Admin']">
        <div class="panel">
          <app-office-branch-list></app-office-branch-list>
        </div>
      </div>
    </ng-container>

    <div *ngIf="activeTab.toLowerCase() === 'danger-zone'">
      <div class="switch">
        <div class="panel">
          <app-danger-zone></app-danger-zone>
        </div>
      </div>
    </div>
  </div>
</div>
