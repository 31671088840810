import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppState } from "src/app/store/index.selector";
import { OtherServices } from "./../models/otherservices";
import { PopupType } from "src/app/shared/enums/popup-types";
import { showMessage } from "src/app/shared/utils/toast.popup";
import { MessagePortalComponent } from "src/app/portal/portal";
import { TableService } from "src/app/shared/services/table.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { OtherServicesService } from "../services/other-services.service";
import { PositioningService } from "src/app/shared/services/positioning.service";
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseTableComponent, DatatableColumn } from "src/app/shared/components/base-table/base-table.component";
import { toggleAnimation } from "src/app/shared/animations";
import { UserService } from "../../user-profile/service/user.service";

@Component({
  selector: "app-other-services-table",
  templateUrl: "./other-services-table.component.html",
  animations: [toggleAnimation],
})
export class OtherServicesTableComponent extends BaseTableComponent<OtherServices> implements OnInit, OnDestroy {
  @ViewChild("dropdownPlaceholder") dropdownPlaceholder!: ElementRef;
  @ViewChild(MessagePortalComponent) messagePortalComponent!: MessagePortalComponent;
  public sales: OtherServices[] = [];
  private routeSubscription!: Subscription;
  public currentId!: number;

  constructor(
    private otherServices: OtherServicesService,
    private route: ActivatedRoute,
    public override translate: TranslateService,
    public override store: Store<AppState>,
    public override tableService: TableService,
    private positioningService: PositioningService,
    private modalService: ModalService,
    public override userService: UserService
  ) {
    super(store, tableService, `planService/paginated`, userService, translate);
  }
  override ngOnInit() {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.apiUrl = `planService/paginated?type=${params["type"]}`;
      super.ngOnInit();
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.routeSubscription.unsubscribe();
  }

  onPortalAttached() {
    setTimeout(() => {
      this.positioningService.setPosition(this.dropdownPlaceholder, this.messagePortalComponent);
    }, 0);
  }

  override getColumns(): void {
    this.cols = [
      { field: "actions", title: this.translate.instant("otherservices.actions"), hide: false, sort: false } as DatatableColumn,
      { field: "id", title: this.translate.instant("otherservices.id"), hide: true } as DatatableColumn,
      { field: "status", title: this.translate.instant("otherservices.status"), hide: true } as DatatableColumn,
      { field: "customer", title: this.translate.instant("otherservices.customer"), hide: false } as DatatableColumn,
      { field: "bruto", title: this.translate.instant("otherservices.bruto"), hide: false } as DatatableColumn,
      { field: "neto", title: this.translate.instant("otherservices.neto"), hide: false } as DatatableColumn,
      { field: "avans", title: this.translate.instant("otherservices.avans"), hide: false } as DatatableColumn,
      { field: "reservation", title: this.translate.instant("otherservices.reservation"), hide: false } as DatatableColumn,
      { field: "details", title: this.translate.instant("otherservices.details"), hide: false } as DatatableColumn,
    ];
  }
  public exportCols = [
    { field: "serviceType", title: this.translate.instant("otherservices_export.serviceType"), isUnique: true, hide: true } as DatatableColumn,
    { field: "customerNameAndSurname", title: this.translate.instant("otherservices_export.customerNameAndSurname"), hide: false, sort: false } as DatatableColumn,
    { field: "customerPhoneNumber", title: this.translate.instant("otherservices_export.customerPhoneNumber"), hide: false } as DatatableColumn,
    { field: "creationDate", title: this.translate.instant("otherservices_export.creationDate"), hide: false } as DatatableColumn,
    { field: "bruto", title: this.translate.instant("otherservices_export.bruto"), hide: false } as DatatableColumn,
    { field: "neto", title: this.translate.instant("otherservices_export.neto"), hide: false } as DatatableColumn,
    { field: "profit", title: this.translate.instant("otherservices_export.profit"), hide: false } as DatatableColumn,
    { field: "saleTime", title: this.translate.instant("otherservices_export.saleTime"), hide: true, sort: false } as DatatableColumn,
    { field: "user", title: this.translate.instant("otherservices_export.user"), hide: true, sort: false } as DatatableColumn,
  ];

  async deletePlanService(id: number) {
    const confirmed = await this.modalService.confirm();
    if (confirmed) {
      const deleteSubscription = this.otherServices.deleteOtherService(id).subscribe({
        next: () => {
          deleteSubscription.unsubscribe();
          this.updatePaginationProps.next(this.getPaginationParams());
        },
        error: (err) => {
          deleteSubscription.unsubscribe();
          showMessage(PopupType.Danger, err.toString());
        },
      });
    }
  }
  onMouseEnter(id: number): void {
    this.currentId = id; 
  }
}
