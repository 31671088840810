<div class="fixed inset-0 z-50 flex items-center justify-center">
  <div class="w-full max-w-md rounded-lg bg-white p-6 shadow-lg">
    <div class="relative w-full max-w-md rounded-lg bg-white">
      <button (click)="closeModal()" class="absolute right-2 top-2 text-gray-500 hover:text-gray-700">
        <icon-x class="h-5 w-5"></icon-x>
      </button>
      <form [formGroup]="form" (ngSubmit)="submitForm()" *ngIf="form">
        <div class="grid grid-cols-1 gap-5 md:grid-cols-2">
          <div [ngClass]="form.controls['name'].touched ? (form.controls['name'].errors ? 'has-error' : 'has-success') : ''">
            <label for="name">{{ "worker.name" | translate }}</label>
            <input type="text" placeholder="{{ 'worker.enter_name' | translate }}" class="form-input" id="name" formControlName="name" [appLimitCharacterInput]="60" #nameInput />
            <ng-container *ngIf="form.controls['name'].touched && form.controls['name'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_name" | translate }}</small>
              </p>
            </ng-container>
          </div>
          <div [ngClass]="form.controls['surname'].touched ? (form.controls['surname'].errors ? 'has-error' : 'has-success') : ''">
            <label for="surname">{{ "worker.surname" | translate }}</label>
            <input type="text" placeholder="{{ 'worker.enter_surname' | translate }}" class="form-input" id="surname" formControlName="surname" [appLimitCharacterInput]="60" />
            <ng-container *ngIf="form.controls['surname'].touched && form.controls['surname'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_surname" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2">
          <div [ngClass]="form.controls['email'].touched ? (form.controls['email'].errors ? 'has-error' : 'has-success') : ''">
            <label for="email">{{ "worker.email" | translate }}</label>
            <input type="text" placeholder="{{ 'worker.enter_email' | translate }}" class="form-input" id="email" formControlName="email" [appLimitCharacterInput]="60" />
            <ng-container *ngIf="form.controls['email'].touched && form.controls['email'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_email" | translate }}</small>
              </p>
            </ng-container>
          </div>
          <div [ngClass]="form.controls['username'].touched ? (form.controls['username'].errors ? 'has-error' : 'has-success') : ''">
            <label for="username">{{ "worker.username" | translate }}</label>
            <input type="text" placeholder="{{ 'worker.enter_username' | translate }}" class="form-input" id="username" formControlName="username" [appLimitCharacterInput]="60" />
            <ng-container *ngIf="form.controls['username'].touched && form.controls['username'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_username" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1" *ngIf="entityId === undefined">
          <div [ngClass]="form.controls['password'].touched ? (form.controls['password'].errors ? 'has-error' : 'has-success') : ''">
            <label for="password">{{ "worker.password" | translate }}</label>
            <input type="password" placeholder="{{ 'worker.enter_password' | translate }}" class="form-input" id="password" formControlName="password" [appLimitCharacterInput]="60" />
            <ng-container *ngIf="form.controls['password'].touched && form.controls['password'].errors">
              <p class="mt-1 text-danger">
                <small *ngIf="form.controls['password'].hasError('required')">
                  {{ "worker.please_provide_password" | translate }}
                </small>
                <small *ngIf="form.controls['password'].hasError('pattern')">
                  {{ "worker.password_complexity" | translate }}
                </small>
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div [ngClass]="form.controls['uniqueMasterCitizenNumber'].touched ? (form.controls['uniqueMasterCitizenNumber'].errors ? 'has-error' : 'has-success') : ''">
            <label for="uniqueMasterCitizenNumber">{{ "user_profile.uniqueMasterCitizenNumber" | translate }}</label>
            <input
              type="text"
              placeholder="{{ 'worker.enter_uniqueMasterCitizenNumber' | translate }}"
              class="form-input"
              entityId="uniqueMasterCitizenNumber"
              formControlName="uniqueMasterCitizenNumber"
              [appLimitCharacterInput]="60"
            />
            <ng-container *ngIf="form.controls['uniqueMasterCitizenNumber'].touched && form.controls['uniqueMasterCitizenNumber'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_uniqueMasterCitizenNumber" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div [ngClass]="form.controls['role'].touched ? (form.controls['role'].errors ? 'has-error' : 'has-success') : ''">
            <label for="role">{{ "worker.role" | translate }}</label>
            <ng-select labelForId="role" [items]="options" formControlName="role" [searchable]="false" [clearable]="false" class="custom-multiselect"> </ng-select>
            <ng-container *ngIf="form.controls['role'].touched && form.controls['role'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "worker.please_provide_role" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div [ngClass]="form.controls['officeBranch'].touched ? (form.controls['officeBranch'].errors ? 'has-error' : 'has-success') : ''">
            <label for="officeBranch">{{ "user_profile.officeBranch" | translate }}</label>
            <ng-select labelForId="officeBranch" [items]="options$ | async" bindLabel="name" formControlName="officeBranch" [searchable]="false" [clearable]="false" class="custom-multiselect"> </ng-select>
            <ng-container *ngIf="form.controls['officeBranch'].touched && form.controls['officeBranch'].errors">
              <p class="mt-1 text-danger">
                <small>{{ "user_profile.please_provide_officeBranch" | translate }}</small>
              </p>
            </ng-container>
          </div>
        </div>
        <div *ngIf="form.controls['role'].value?.value === 'admin'" class="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1">
          <div [ngClass]="form.controls['isOfficeBranchManager'].touched ? (form.controls['officeBranch'].errors ? 'has-error' : 'has-success') : ''">
            <label for="isOfficeBranchManager">
              <input type="checkbox" class="form-checkbox" entityId="isOfficeBranchManager" formControlName="isOfficeBranchManager" />
              {{ "worker.is_office_branch_manager" | translate }}
            </label>
          </div>
        </div>
        <button type="submit" class="btn btn-primary mt-3 w-full" [disabled]="isLoading || form.invalid">
          <ng-container *ngIf="!isLoading; else loadingTemplate">
            <ng-container *ngIf="entityId !== undefined; else noUserId"> {{ "worker.update" | translate }}</ng-container>
            <ng-template #noUserId>
              {{ "worker.save" | translate }}
            </ng-template>
          </ng-container>
          <ng-template #loadingTemplate>
            <span class="border-blue inline-block h-5 w-5 animate-spin rounded-full border-2 border-l-transparent align-middle ltr:mr-4 rtl:ml-4"></span>{{ "loading" | translate }}
          </ng-template>
        </button>
      </form>
    </div>
  </div>
</div>
