import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { GroupTravel, GroupTravelDestination } from "../models/group-travel";
import { TranslateService } from "@ngx-translate/core";
import { GroupTravelsService } from "../services/group-travels.service";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-group-travel-details",
  templateUrl: "./group-travel.details.component.html",
  animations: [
    trigger("toggleAnimation", [
      transition(":enter", [style({ opacity: 0, transform: "scale(0.95)" }), animate("100ms ease-out", style({ opacity: 1, transform: "scale(1)" }))]),
      transition(":leave", [animate("75ms", style({ opacity: 0, transform: "scale(0.95)" }))]),
    ]),
  ],
})
export class GroupTravelDetailsComponent implements OnInit {
  protected id!: number;
  protected group_travel!: GroupTravel;
  public entityname = "GroupTravels";
  isShowingSidebar = false;
  protected mainDestination!: GroupTravelDestination;
  additionalDestinations: GroupTravelDestination[] = [];

  constructor(private travelService: GroupTravelsService, private route: ActivatedRoute, public translate: TranslateService) {}
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"];
      this.fetchTravelData();
    });
  }

  fetchTravelData() {
    this.travelService.getGroupTravelData(this.id).subscribe((response) => {
      this.group_travel = response;
      this.mainDestination = this.group_travel.groupTravelDestinations.find((dest) => dest.isMainDestination) || {} as GroupTravelDestination;
      this.additionalDestinations = this.group_travel.groupTravelDestinations.filter((dest) => !dest.isMainDestination);
    });
  }
}
