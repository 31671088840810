import { catchError, tap } from "rxjs";
import { Hotel } from "../models/hotel";
import { HotelsService } from "../services/hotels.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, OnInit } from "@angular/core";

@Component({
  selector: "app-hotel-form",
  templateUrl: "./hotel-form.component.html",
})
export class HotelFormComponent implements OnInit, AfterViewInit{
  @Input() entityId!: number;  // Ensure that entityId is defined as part of the interface
  @Input() editMode = false;
  @Input() isLoading = false;
  @Output() formSubmit = new EventEmitter<Hotel | null>();
  form!: FormGroup;

  @ViewChild("nameInput") nameInput!: ElementRef;
  constructor(private fb: FormBuilder,public hotelService:HotelsService) {}

  ngOnInit(): void {
    this.initForm();
    console.log(this.entityId);
    if (this.entityId) {
      this.loadHotelDetails();
    }
  }
  ngAfterViewInit(): void {
    if (this.nameInput) {
      this.nameInput.nativeElement.focus();
      this.nameInput.nativeElement.select();
    }
  }

  private initForm(hotel: Hotel = {} as Hotel): void {
    this.form = this.fb.group({
      name: [hotel.name || "", Validators.required],
      address: [hotel.address || ""],
      phoneNumber: [hotel.phoneNumber || ""],
      email: [hotel.email || "", [Validators.email]],
      representative: [hotel.representative || ""],
    });
  }
  loadHotelDetails(): void {
    this.hotelService
      .getHotelDetails(this.entityId)
      .pipe(
        tap((hotel) => {
          this.initForm(hotel); 
        }),
        catchError((err) => {
          console.error("Error loading customer data:", err);
          return [];
        })
      )
      .subscribe();
  }

  submitForm(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const formData = this.form.value;
    this.formSubmit.emit(formData);
  }

  closeModal(): void {
    this.formSubmit.emit(null);
  }
}
