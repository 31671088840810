import { Store } from "@ngrx/store";
import { Injectable } from "@angular/core";
import { AppState } from "src/app/store/index.selector";
import { setFrom, setFromTo } from "./../../store/datepicker/datepicker.actions";

@Injectable({
  providedIn: "root",
})
export class DateService {
  private currentFrom: Date | null = null;
  private currentTo: Date | null = null;

  constructor(private store: Store<AppState>) {}

  setFromDate(from: Date) {
    if (!this.datesAreEqual(from, this.currentFrom)) {
      this.currentFrom = from;
      this.store.dispatch(setFrom({ from }));
    }
  }

  setFromToDate(from: Date, to: Date) {
    if (!this.datesAreEqual(from, this.currentFrom) || !this.datesAreEqual(to, this.currentTo)) {
      this.currentFrom = from;
      this.currentTo = to;
      this.store.dispatch(setFromTo({ from, to }));
    }
  }

  private datesAreEqual(date1: Date | null, date2: Date | null): boolean {
    if (!date1 || !date2) {
      return date1 === date2;
    }
    return date1.getTime() === date2.getTime();
  }
}
